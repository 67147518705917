import { Icon } from '@iconify/react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, IconButton, List, ListItem, Stack,
    Tooltip, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { changeInvoiceField, getInvoiceBreakdown, invoiceForm } from '../../../../redux/slices/invoicing';
import { useDispatch, useSelector } from '../../../../redux/store';
import FieldMapping from '../../../../reusable-components/formFieldPairs/FieldMapping';
import Iconify from '../../../../reusable-components/iconify';
import usePrompt from '../../../../reusable-components/prompt/usePrompt';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { fCurrency } from '../../../../utils/formatNumber';
import { fMonthDayYearShort } from '../../../../utils/formatTime';
import { useScopeCheck } from '../../../../reusable-components/scopes';

InvoiceForm.propTypes = {
  setOpenGlCodeSummary: PropTypes.func,
  setHighlightedGls: PropTypes.func,
  glRows: PropTypes.array,
};
export default function InvoiceForm({ setHighlightedGls, glRows, setOpenGlCodeSummary }) {
  const { id } = useParams();

  const dispatch = useDispatch();

  const {
    data: { invoiceData, invoiceItemData, invoiceGLItems },
  } = useSelector((state) => state.invoicing);
  const { invoice } = invoiceItemData || {};
  const { vendors: autoVendors, manualVendors } = invoiceData;
  const { enqueueSnackbar } = useSnackbar();
  const hasEditAccess = useScopeCheck(
    invoice?.sourceMethod === 'Manual' ? ['ManualInvoice-Edit'] : ['Invoice-Edit'],
    true
  );

  const [editInvoice, setEditInvoice] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [expand, setExpand] = useState(false);
  const [unsavedData, setUnsavedData] = useState(null);
  const facilities = invoice?.sourceMethod === 'Manual' ? invoiceData.manualFacilities : invoiceData.facilities;
  usePrompt(editInvoice);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: invoice,
  });

  const facId = watch('facilityId');
  const facilityPermissionRequired =
    invoice.facilityId !== 0 &&
    invoice.facilityId !== undefined &&
    !facilities?.find((option) => option.value === invoice.facilityId);

  const vendorOptions = useMemo(
    () =>
      invoiceItemData.invoice.sourceMethod === null
        ? autoVendors
        : /*sourceMethod === "Manual"*/ manualVendors.filter((v) => v.extraValue === facId),
    [facId, autoVendors, invoiceItemData.invoice.sourceMethod, manualVendors]
  );

  const totalSum = invoiceItemData?.invoiceItems?.reduce((accumulator, item) => {
    const itemTotal = item?.price * item?.quantity + item?.taxAmount;
    return accumulator + itemTotal;
  }, 0);
  const calculatedTotal =
    invoiceGLItems.length > 0
      ? invoiceGLItems
        .map((i) => Number(i.amount) + Number(i.shipping))
        ?.reduce((accumulator, current) => accumulator + current, 0) ?? 0
      : Math.round((invoiceItemData?.invoice?.shipping + totalSum + Number.EPSILON) * 100) / 100;
  const initializeData = async () => {
    if (invoiceItemData?.invoice !== null && invoiceItemData?.invoice !== undefined) {
      Object.entries(invoiceItemData?.invoice).forEach(([key, value]) => {
        if ((key === 'facilityId' || key === 'vendorId') && value === 0) setValue(key, '');
        else if (key === 'invoiceType' && value !== 'Invoice' && value !== 'CreditMemo') {
          setValue('invoiceType', invoiceItemData?.invoice?.total >= 0 ? 'Invoice' : 'CreditMemo');
        } else setValue(key, value ?? '');
      });
      setDataLoaded(true);
    }
  };
  useEffect(() => {
    initializeData();
  }, [invoiceItemData, dataLoaded]);

  //update disable/enable invoice save button
  useEffect(() => {
    if (dataLoaded) {
      if (unsavedData != null) {
        const onEdit = !Object.keys(getValues()).every((key) => {
          const value1 = getValues()?.[key];
          const value2 = invoiceItemData?.invoice[key];
          if (value1 instanceof Date) return fMonthDayYearShort(value1) === fMonthDayYearShort(value2);
          if (!Number.isNaN(+value2)) return Number(value1) === Number(value2);
          return (value1 === null && value2 === '') || (value1 === '' && value2 === null) || value1 === value2;
        });
        setEditInvoice(onEdit);
      }
    }
    // if (invoice === undefined && getValues() !== null)
    //else setEditInvoice(true);
  }, [dataLoaded, invoice, unsavedData]);

  const onChangeField = async (name, newValue) => {
    setValue(name, newValue);
    setUnsavedData((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };
  const handleVendorTotalChange = async (newValue) => {
    if (!Number.isNaN(+newValue)) {
      if (Number(newValue) < 0) {
        setValue('invoiceType', 'CreditMemo');
      } else if (Number(newValue) >= 0) {
        setValue('invoiceType', 'Invoice');
      }
      setValue('total', newValue);
    }
    setUnsavedData((prev) => ({
      ...prev,
      total: newValue,
    }));
  };
  const handleInvoiceTypeChange = async (e) => {
    if (e.target.value === 'Invoice') {
      setValue('total', `${Math.abs(getValues().total)}`);
    } else {
      setValue('total', `-${Math.abs(getValues().total)}`);
    }
    setValue('invoiceType', e.target.value);
    setUnsavedData((prev) => ({
      ...prev,
      invoiceType: e.target.value,
    }));
  };
  const handleInvoiceFormSave = async (data) => {
    setUnsavedData(null);
    if (editInvoice) {
      setEditInvoice(false);
      const response = await dispatch(invoiceForm({ invoice: data }));
      if (response === 'success') {
        await dispatch(getInvoiceBreakdown(id));
        enqueueSnackbar('Invoice updated successfully', { variant: 'success' });
      } else enqueueSnackbar('Error updating Invoice', { variant: 'error' });
    }
  };

  const updateVendorValue = (newValue) => {
    setValue('vendorId', newValue?.value);
    setValue('vendor', newValue?.label);
    setUnsavedData((prev) => ({
      ...prev,
      vendor: newValue?.label,
    }));
  };

  useEffect(() => {
    if (invoiceItemData?.invoice?.date && !invoiceItemData?.invoice?.glPostingDate) {
      dispatch(changeInvoiceField(id, { key: 'GlPostingDate', value: invoiceItemData?.invoice?.date }));
    }
  }, [invoice]);

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <form onSubmit={handleSubmit(handleInvoiceFormSave)}>
          <Box sx={mainBoxStyle}>
            <Box>
              <>
                <Grid container justifyContent={'end'} alignItems={'center'}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      type="submit"
                      sx={{
                        display: editInvoice || (invoiceItemData === null && unsavedData != null) ? 'block' : 'none',
                        mr: 1,
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Tooltip title={`${expand ? 'more' : 'less'}`}>
                      <IconButton onClick={() => setExpand(!expand)} sx={{ color: 'info.main' }}>
                        <Iconify icon={`ic:twotone-expand-${expand ? 'more' : 'less'}`} width={26} height={26} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            </Box>
            <Scrollbar sx={expand ? { maxHeight: '150px' } : {}}>
              <Box>
                <Stack spacing={2}>
                  {!getValues()?.multiFacility ? (
                    <>
                      <Controller
                        name={facilityPermissionRequired ? 'facility' : 'facilityId'}
                        control={control}
                        options={facilities || []}
                        render={({ field }) => (
                          <FieldMapping
                            fieldName="Facility * "
                            name={facilityPermissionRequired ? 'facility' : 'facilityId'}
                            fieldType={facilityPermissionRequired ? 'text' : 'autocomplete'}
                            onChange={(event, newValue) => {
                              setValue('facilityId', newValue.value);
                              setValue('facility', newValue.label);
                              setUnsavedData((prev) => ({
                                ...prev,
                                facility: newValue.label,
                              }));
                              updateVendorValue({ label: null, value: null });
                            }}
                            disabled={!hasEditAccess || facilityPermissionRequired}
                            options={facilities || []}
                            control={control}
                            tooltipText={facilityPermissionRequired ? 'Facility permission required' : ''}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </>
                  ) : (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={
                          <Icon
                            icon="material-symbols-light:expand-more-rounded"
                            color="#8dc63f"
                            height="20px"
                            width="20px"
                          />
                        }
                        sx={{ p: 0 }}
                      >
                        <Typography sx={fieldStyle}>Multiple facilities</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {invoiceGLItems?.length === 0 ? (
                          <>
                            <Tooltip>{facilities?.find((x) => x.value === glRows?.[0]?.facilityId)?.label}</Tooltip>
                          </>
                        ) : (
                          <Scrollbar sx={{ maxHeight: '250px' }}>
                            <List sx={{ listStyle: 'decimal', pl: 4 }}>
                              {getValues().invoiceFacilities.map((facility, index) => {
                                const glFacilities = glRows?.filter((x) => x.facilityId === facility.facId);
                                return (
                                  <>
                                    <ListItem sx={{ display: 'list-item' }} key={index}>
                                      {facility.facilityName} &nbsp;
                                      <Tooltip
                                        onClick={() => {
                                          setOpenGlCodeSummary(true);
                                          const glIds = [];
                                          glFacilities?.forEach((item) => {
                                            glIds.push(item.id);
                                          });
                                          setHighlightedGls(glIds);
                                        }}
                                        title={glFacilities?.map((item, index) =>
                                          index > 0 ? `, ${item.glCode}` : item.glCode
                                        )}
                                      >
                                        <Icon icon="material-symbols:info-outline" color="#0075db" />
                                      </Tooltip>
                                    </ListItem>
                                  </>
                                );
                              })}
                            </List>
                          </Scrollbar>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <Controller
                    name={'vendorId'}
                    control={control}
                    options={vendorOptions || []}
                    render={({ field }) => (
                      <FieldMapping
                        fieldName="Vendor * "
                        name="vendorId"
                        fieldType={'autocomplete'}
                        onChange={(event, newValue) => updateVendorValue(newValue)}
                        options={vendorOptions || []}
                        disabled={!hasEditAccess}
                        control={control}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </Stack>
                <Divider variant="fullWidth" sx={dividerStyle} />
                <Stack spacing={2}>
                  <Controller
                    name={'vendorInvoiceId'}
                    control={control}
                    render={({ field }) => (
                      <FieldMapping
                        fieldName="Invoice * "
                        name="vendorInvoiceId"
                        fieldType={'text'}
                        onChange={(e) => {
                          const name = e?.target.name;
                          const newValue = e.target.value;
                          onChangeField(name, newValue);
                        }}
                        disabled={!hasEditAccess}
                        control={control}
                      />
                    )}
                    rules={{ required: true }}
                  />

                  {invoice?.sourceMethod === 'Manual' && (
                    <Controller
                      name={'invoiceId'}
                      control={control}
                      render={({ field }) => (
                        <FieldMapping
                          fieldName="PO"
                          name="invoiceId"
                          fieldType={'text'}
                          onChange={(e) => {
                            const name = e?.target.name;
                            const newValue = e.target.value;
                            onChangeField(name, newValue);
                          }}
                          disabled={!hasEditAccess}
                          control={control}
                        />
                      )}
                    />
                  )}
                  <Controller
                    name={'invoiceType'}
                    control={control}
                    render={({ field }) => (
                      <FieldMapping
                        fieldName="Invoice type * "
                        name="invoiceType"
                        fieldType={'select'}
                        onChange={handleInvoiceTypeChange}
                        options={[
                          { label: 'Invoice', value: 'Invoice' },
                          { label: 'Credit Memo', value: 'CreditMemo' },
                        ]}
                        disabled={!hasEditAccess}
                        control={control}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <Controller
                    name={'referenceNumber'}
                    control={control}
                    render={({ field }) => (
                      <FieldMapping
                        fieldName="Reference #"
                        value={getValues()?.referenceNumber}
                        name="referenceNumber"
                        fieldType={'text'}
                        control={control}
                        onChange={(e) => {
                          const name = e?.target.name;
                          const newValue = e.target.value;
                          onChangeField(name, newValue);
                        }}
                        disabled={!hasEditAccess}
                      />
                    )}
                  />
                </Stack>

                <Divider variant="fullWidth" sx={dividerStyle} />
                <Stack spacing={2}>
                  <Controller
                    name={'date'}
                    control={control}
                    render={({ field }) => (
                      <FieldMapping
                        fieldName="Invoice date * "
                        //value={invoiceValues?.date}
                        name="date"
                        fieldType={'date'}
                        onChange={(newDate) => {
                          onChangeField('date', newDate);
                        }}
                        control={control}
                        disabled={!hasEditAccess}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <Controller
                    name={'dueDate'}
                    control={control}
                    render={({ field }) => (
                      <FieldMapping
                        fieldName="Due date"
                        //value={invoiceValues?.dueDate}
                        name="dueDate"
                        fieldType={'date'}
                        onChange={(newDate) => {
                          onChangeField('dueDate', newDate);
                        }}
                        control={control}
                        disabled={!hasEditAccess}
                      />
                    )}
                  />
                  <Controller
                    name={'glPostingDate'}
                    control={control}
                    render={({ field }) => (
                      <FieldMapping
                        fieldName="Gl Posting Date"
                        name="glPostingDate"
                        fieldType={'date'}
                        onChange={(newDate) => {
                          onChangeField('glPostingDate', newDate);
                        }}
                        control={control}
                      />
                    )}
                  />
                </Stack>
                <Divider variant="fullWidth" sx={dividerStyle} />
                <Stack spacing={2}>
                  <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: 'info.main' }}>Totals:</Typography>

                  {invoice?.sourceMethod === 'Manual' ? (
                    <Controller
                      name={'total'}
                      control={control}
                      render={({ field }) => (
                        <FieldMapping
                          fieldName="Vendor total"
                          name="total"
                          fieldType={'currency'}
                          onChange={handleVendorTotalChange}
                          control={control}
                          disabled={!hasEditAccess}
                        />
                      )}
                    />
                  ) : (
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                      <Controller
                        name={'total'}
                        control={control}
                        render={({ field }) => (
                          <FieldMapping
                            fieldName="Vendor total"
                            name="total"
                            fieldType={'currency'}
                            onChange={handleVendorTotalChange}
                            control={control}
                            disabled={!hasEditAccess}
                          />
                        )}
                      />

                      <Tooltip title={`Our calculations indicate a total of ${fCurrency(calculatedTotal)} `} arrow>
                        {fCurrency(getValues()?.total) === fCurrency(calculatedTotal) ? (
                          <Typography fontSize={'1.5rem'} color="secondary">
                            <CheckCircleOutlineIcon />
                          </Typography>
                        ) : (
                          <Typography fontSize={'1.5rem'} color="error.main">
                            <ErrorOutlineIcon />
                          </Typography>
                        )}
                      </Tooltip>
                    </Stack>
                  )}
                  <Controller
                    name={'shipping'}
                    control={control}
                    render={({ field }) => (
                      <FieldMapping
                        fieldName="Shipping"
                        name="shipping"
                        fieldType={'currency'}
                        onChange={(newValue) => {
                          onChangeField('shipping', newValue);
                        }}
                        control={control}
                        disabled={invoiceGLItems?.length > 0 || !hasEditAccess}
                      />
                    )}
                  />
                </Stack>
              </Box>
            </Scrollbar>
          </Box>
        </form>
      </Box>
    </>
  );
}

const dividerStyle = {
  backgroundColor: 'info.main',
  height: '2px',
  my: '20px',
};
const mainBoxStyle = {
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  padding: '25px',
  overflow: 'auto',
  border: 'solid 1px #c1c9d0',
  //maxHeight: '450px',
};
const fieldStyle = { color: '#060606', fontSize: '16px', fontWeight: '700' };

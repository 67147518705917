import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/OrderStatuses

// GET
// /Api/ConnectedItems

// GET
// /Api/OrdersUnread
export async function getOrdersUnreadData() {
  const response = await axios.get('/OrdersUnread');
  return response;
}

// GET
// /Api/ReplacementData orderId
export async function getReplacementData(orderId) {
  const response = await axios.get(`/ReplacementData?orderId=${orderId}`);
  return response;
}

// POST
// /Api/UpdateOrderGuidePrices facilityId items {  "itemId": 0,  "price": 0}
export async function postUpdateOrderGuidePrices({ facilityId, items }) {
  items = items?.map((item) => ({
    itemId: item.itemId,
    price: item.updatedPrice,
  }));

  const formData = objectToFormData({ facilityId, items });
  const response = await axios.post('/UpdateOrderGuidePrices', formData);
  return response;
}

// POST
// /Api/ApproveOrder

// POST
// /Api/CombineOrders
export async function postCombineOrder(orderIdArray) {
  const originalOrderId = orderIdArray[0];
  const combineOrders = orderIdArray?.slice(1);
  const formData = objectToFormData({ originalOrderId, combineOrders });
  const response = await axios.post('/CombineOrders', formData);
  return response;
}

// POST
// /Api/SplitOrder orderId items
export async function postSplitOrder({ orderId, selectedRows, data }) {
  const itemsToPost = data?.map((item) => ({
    id: item.id,
    vendorId: item.vendorId,
    quantity: item.quantity,
    uomAmount: item.uomAmount,
    itemId: item.itemId,
    agoraCategoryId: item.agoraCategory.id,
    agoraSubcategoryId: item.agoraSubcategory.id,
    vendorItemId: item.vendorItemId,
    description: item.description,
    uomType: item.uomType,
    price: item.price,
    approved: item.approved,
    splitAmount: selectedRows.find((splitItem) => splitItem.id === item.id)?.quantity || 0,
  }));
  const formData = objectToFormData({ items: itemsToPost, orderId });
  const response = await axios.post('/SplitOrder', formData);
  return response;
}

// POST
// /Api/SaveOrder
export async function postSaveOrder({ orderId, items, vendorDates }) {
  const itemsToPost = items?.map((item) => ({
    id: item?.unsavedId === 0 ? 0 : item?.unsavedId ? item?.unsavedId : item.id,
    itemId: item.itemId,
    vendorId: item.vendorId,
    quantity: item.quantity,
    uomAmount: item.uomAmount,
    agoraCategoryId: item.agoraCategory.id,
    agoraSubcategoryId: item.agoraSubcategory.id,
    vendorItemId: item.vendorItemId,
    description: item.description,
    uomType: item.uomType,
    price: item.price,
    approved: item.approved,
  }));
  const formData = objectToFormData({ items: itemsToPost, orderId, vendorDates });
  const response = await axios.post('/SaveOrder', formData);
  return response;
}
// POST
// /Api/SaveOrder
export async function postApproveOrder({ rerunOrder, orderId, items, address, vendorDates, delayOrderInSeconds, globalNote, removeGlobalNoteFromVendors }) {
  const itemsToPost = items?.map((item) => ({
    id: item.id,
    vendorId: item.vendorId,
    quantity: item.quantity,
    uomAmount: item.uomAmount,
    itemId: item.itemId,
    agoraCategoryId: item.agoraCategory.id,
    agoraSubcategoryId: item.agoraSubcategory.id,
    vendorItemId: item.vendorItemId,
    description: item.description,
    uomType: item.uomType,
    price: item.price,
    approved: item.approved,
  }));

  const vendorDatesUpdated = vendorDates.map((vendorDate) => ({
    vendorId: vendorDate.vendorId,
    requestedDate: vendorDate.date,
  }));

  const formData = objectToFormData({
    items: itemsToPost,
    rerunOrder,
    orderId,
    address,
    vendorDates: vendorDatesUpdated,
    delayOrderInSeconds,
    globalNote,
    removeGlobalNoteFromVendors
  });
  const response = await axios.post('/ApproveOrder', formData);
  return response;
}

// POST
// /Api/OrderAsPDF
export async function getOrderAsPDF({ orderId, items, address, vendorId }) {
  const itemsToPost = items?.map((item) => ({
    vendorItemId: item.vendorItemId,
    vendor: item.vendor,
    description: item.description,
    uomType: item.uomType,
    quantity: item.quantity,
    updatedQuantity: item.quantity,
    uomAmount: item.uomAmount,
    price: item.price,
  }));
  const formData = objectToFormData({ items: itemsToPost, orderId, address, vendorId });
  const response = await axios.post('/OrderAsPDF', formData, { responseType: 'blob' });
  return response;
}
// POST
// /Api/OrderAsExcel
export async function getOrderAsExcel({ orderId, items, address }) {
  const itemsToPost = items?.map((item) => ({
    vendorItemId: item.vendorItemId,
    vendor: item.vendor,
    description: item.description,
    uomType: item.uomType,
    quantity: item.quantity,
    uomAmount: item.uomAmount,
    price: item.price,
  }));
  const formData = objectToFormData({ items: itemsToPost, orderId, address });
  const response = await axios.post('/OrderAsExcel', formData, { responseType: 'blob' });
  return response;
}

export async function getOrderOccupantsData(orderId) {
  const response = await axios.get(`/OrderOccupants?orderId=${orderId}`);
  return response;
}

export async function postLeaveOrder(orderId) {
  const response = await axios.post(`/LeaveOrder?orderId=${orderId}`);
  return response;
}
export async function getPendingOrdersData() {
  const response = await axios.get('/PendingOrders');
  return response;
}

export async function getPreviousOrdersData({ startDate, endDate }) {
  const response = await axios.get(`/PreviousOrders?Start=${startDate}&End=${endDate}`);
  return response;
}

//OrdersList
export async function getOrdersListData(facilityId) {
  const response = await axios.get(`/OrdersList?facilityId=${facilityId}`);
  return response;
}

// /Api/AddToOrderList
export async function getAddToOrderListData(facilityId, categoryId) {
  const response = await axios.get(`/AddToOrderList?facilityId=${facilityId}&categoryId=${categoryId}`);
  return response;
}

export async function getPreviousOrderInfoData(orderId) {
  const response = await axios.get(`/PreviousOrder?orderId=${orderId}`);
  return response;
}

export async function getPreviousOrderInfoDataByNumber(orderId) {
  const response = await axios.get(`/PreviousOrder?orderNumber=${orderId}`);
  return response;
}
export async function getPendingOrderInfoData(orderId) {
  const response = await axios.get(`/ApproveOrderData?orderId=${orderId}`);
  return response;
}
export async function removedFromBudget(id, remove) {
  const response = await axios.post(`/RemoveFromBudget?id=${id}&remove=${remove}`);
  return response;
}

///Api/CheckVendorOrderSent orderId vendorId
export async function checkVendorOrderSent(orderId, vendorIds) {
  const formData = objectToFormData({
    orderId,
    vendorIds,
  });

  const response = await axios.post(`/CheckVendorsOrderSent`, formData);
  return response;
}

export async function getBudgetsData({ facilityId, includeUnapproved, useCurrentCensus, orderNumber = 0, categories }) {
  const response = await axios.get(
    `/Budgets?facilityId=${facilityId}&includeUnapproved=${includeUnapproved}&useCurrentCensus=${useCurrentCensus}&orderNumber=${orderNumber}`
  );
  return response;
}

export async function getBudgetItemDetailsData({ facilityId, categoryId, includeUnapproved, budgetDate, subcategoryId = 0 }) {
  const response = await axios.get(
    `/BudgetItemDetails?facilityId=${facilityId}&categoryId=${categoryId}&includeUnapproved=${includeUnapproved}&budgetDate=${budgetDate.toJSON()}&subcategoryId=${subcategoryId}`
  );
  return response;
}

// POST
// /Api/OrderCommentForm OrderNumber Comment vendorIds[]
export async function addOrderCommentForm({ orderNumber, comment, vendorIds }) {
  const formData = objectToFormData({ orderNumber, comment, vendorIds });
  const response = await axios.post('/OrderCommentForm', formData);
  return response;
}
export async function editOrderCommentForm({ id, orderNumber, comment, vendorIds }) {
  const formData = objectToFormData({ id, orderNumber, comment, vendorIds });
  const response = await axios.post('/OrderCommentForm', formData);
  return response;
}

// POST
// /Api/DeleteOrderComment id
export async function deleteOrderComment(id) {
  const response = await axios.post(`deleteOrderComment?id=${id}`);
  return response;
}
// /Api/DeleteOrder id
export async function deleteOrder(id) {
  const response = await axios.post(`deleteOrder?id=${id}`);
  return response;
}

export async function changeDate(id, orderDate) {
  const response = await axios.post(`/ChangeOrderDelayDate?orderId=${id}&orderDate=${orderDate}`);
  return response;
}

export async function getVendorItemsForReplacement({ vendorId, facilityId, uoms }) {
  const formData = objectToFormData({ vendorId, facilityId, uoms });
  const response = await axios.post('/VendorItemsForReplacement', formData);
  return response;
}

export async function setItemsPPD(items) {
  const updatedForm = items.map(i => ({
    FacilityId: i.facilityId, ItemId: i.itemId, IsPPD: i.isPPD
  }));

  const formData = objectToFormData({ input: updatedForm });

  const response = await axios.post('/SetItemsPPD', formData);
  return response;
}

export async function setItemPPD({ facilityId, itemId, isPPD }) {
  const formData = objectToFormData({ facilityId, itemId, isPPD });
  const response = await axios.post('/SetItemPPD', formData);
  return response;
}

export async function postOrderProductForm({ parentData, productData, categories, vendorId, vendorValue }) {
  const { description, notes } = parentData;

  const { agoraCategoryId, agoraSubcategoryId } = categories[0];

  const productCategories =
    categories.length > 1
      ? categories?.slice(1).map((category) => ({
        agoraCategoryId: category.agoraCategoryId,
        agoraSubcategoryId: category.agoraSubcategoryId,
      }))
      : [];
  const formattedProductData = {
    VendorItemId: vendorId,
    Price: productData.price,
    UOMAmount: productData.uomAmount,
    UOMType: productData.uom,
    VendorId: vendorValue,
    Vendor: productData.vendor,
    BaseUOMAmount: productData.baseUOMAmount,
    BaseMinUOMAmount: productData.baseMinUOMAmount,
    BaseUOMType: productData.baseUOMType,
    Manufacturer: productData.manufacturer,
    ManufacturerId: productData.manufacturerId,
    IsPPD: productData.isPPD,
    Pictures: productData.pictureUrls,
    Splittable: !!productData.uomAmount2,
    VendorItemLink: productData.vendorItemLink,
    productDetailUOM: productData.uomAmount2
      ? {
        Price: productData.price2,
        UOMType: productData.uomType2,
        UOMAmount: productData.uomAmount2,
      }
      : {},
  };

  const submitData = {
    Id: 0,
    Description: description,
    Notes: notes,
    AgoraCategoryId: agoraCategoryId,
    AgoraSubcategoryId: agoraSubcategoryId,
    ProductCategories: productCategories,
    ProductDetail: formattedProductData,
  };

  const response = await axios.post(`/OrderProductForm`, objectToFormData(submitData));
  return response;
}

export async function getConnectedItems(productId, orderId) {
  const response = await axios.get(`/ConnectedItems?productId=${productId}&orderId=${orderId}`);
  return response;
}

// GET / Api / OrdersSent; orderId
export async function getOrdersSent(orderId) {
  const response = await axios.get(`/OrdersSent?orderId=${orderId}`);
  return response;
}

///Api/OrderWasApprovedid
export async function getOrderWasApproved(id) {
  const response = await axios.get(`/OrderWasApproved?id=${id}`);
  return response;
}

export async function changeBudgetDate(id, budgetDate) {
  const response = await axios.post(`/ChangeBudgetDate?id=${id}&budgetDate=${budgetDate}`);
  return response;
}

export async function saveManualBudgetItemData(itemQuantityId, unitPrice,shipping,tax, qty) {
  const response = await axios.post(`/AddManualBudgetItem?itemQuantityId=${itemQuantityId}&unitPrice=${unitPrice}&shipping=${shipping}&tax=${tax}&qty=${qty}`);
  return response;
}

// GET / Api / ReviewOrder; orderId
export async function setReviewOrder(orderId) {
  const response = await axios.post(`/ReviewOrder?orderId=${orderId}`);
  return response;
}

export async function getStateAlerts(zip) {
  const severityLevels = ['Severe', 'Emergency', 'Extreme'];
  try {
    const responseZip = await axios.get(`https://api.zippopotam.us/us/${zip}`);
    const placeData = responseZip.data.places[0];
    const latitudeLongitude = `${placeData.latitude},${placeData.longitude}`;
    const EVENT_TYPES =
      "Tornado," +
       "Severe%20Thunderstorm," +
       "Flash%20Flood," +
       "Flood," +
       "Hurricane," +
       "Typhoon," +
       "Storm%20Surge," +
       "Extreme%20Wind," +
       "Dust%20Storm," +
       "Snow%20Squall," +
       "Earthquake," +
       "Fire%20Weather," +
       "Extreme%20Heat," +
       "Hazardous%20Materials," +
       "Civil%20Emergency," +
       "Avalanche%20Warning," +
       "Coastal%20Flood," +
       "Winter%20Storm%20Warning," +
       "Severe%20Weather%20Statement," +
      "Tsunami%20Warning";
    const NWSAlertsApiUrl = `https://api.weather.gov/alerts/active?point=${latitudeLongitude}&event=${EVENT_TYPES}`;
    const response = await axios.get(NWSAlertsApiUrl);
    const alertResult = response.data;
    if (alertResult.features && alertResult.features.length > 0) {
      const headlines = alertResult.features
        .filter(feature => severityLevels.includes(feature.properties.severity))
        .map(feature => feature.properties.headline);
      return {
        alertCount: alertResult.features.length,
        headlines,
        message: 'Active weather alerts retrieved successfully.',
      };
    }
    return {
      alertCount: 0,
      headlines: [],
      message: 'No active alerts found.',
    };
  } catch (error) {
    console.error('Error fetching alerts:', error);
    return {
      alertCount: 0,
      headlines: [],
      message: 'Error retrieving alerts.',
    };
  }
};

import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  CssBaseline,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HOST_API_URL } from '../../../../../config-global';
import { useSelector } from '../../../../../redux/store';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';
import { CHECKOUT } from '../../../../../reusable-components/datagrid/cartColumns';
import Iconify from '../../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../../reusable-components/scopes';
import { fCurrency } from '../../../../../utils/formatNumber';
import SubmitOrder from './SubmitOrder';

export default function CheckoutAllView() {
  const { facilityId = '' } = useParams();

  const [cartItems, setCartItems] = useState([]);
  const [itemErrorCount, setItemErrorCount] = useState(0);
  const [personsArray, setPersonsArray] = useState([]);
  const [thePersonFor, setThePersonFor] = useState('');
  const [requestedDate, setRequestedDate] = useState(null);

  const {
    data: { carts, facility, personsFor, personFor },
  } = useSelector((state) => state.cart);

  useEffect(() => {
    setPersonsArray(personsFor ? personsFor.map((person, index) => ({ name: person, id: index })) : null);
  }, [personsFor]);

  useEffect(() => {
    setThePersonFor(personFor);
  }, [personFor]);

  const isLoading = useSelector((state) => state.cart.isLoading);
  useEffect(() => {
    let tempItemErrorCount = 0;
    const confirmItems = carts.filter(cart => cart.orderable).flatMap((cart) => {
      const validItems = cart.items.filter((item) => item.quantity > 0);
      validItems.forEach((item) => {
        if (item.errors?.length > 0) {
          tempItemErrorCount++;
        }
      });
      return validItems;
    });

    setCartItems(confirmItems);
    setItemErrorCount(tempItemErrorCount);
  }, [carts]);

  return (
    <>
      <CssBaseline />
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item sx={{ mx: 1, mb: 1 }}>
          <Typography variant="subtitle1" color="primary">
            {facility?.label}
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 1, mb: 1 }}>
          <Button
            variant="outlined"
            sx={{ mr: 1 }}
            size="small"
            onClick={() => {
              window.open(`${HOST_API_URL}Api/DownloadCart?facilityId=${facilityId}`, '_blank');
            }}
            startIcon={<Iconify icon="mdi:tray-arrow-down" />}
            color="secondary"
          >
            Download Cart
          </Button>
        </Grid>
        <Grid item sx={{ ml: 1, mb: 1 }}>
          <Autocomplete
            sx={{
              width: '100%',
              minWidth: 250,
            }}
            freeSolo
            size="small"
            type="new-password"
            options={personsArray}
            getOptionLabel={(option) => option?.name || ''}
            forcePopupIcon
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setThePersonFor(newValue);
              } else {
                setThePersonFor(newValue?.name || '');
              }
            }}
            onInputChange={(event, newInputValue) => {
              setThePersonFor(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={thePersonFor || 'The person for'}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // Ensuring autocomplete is off
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Tooltip title="This is ship date request. Not delivery date request" placement="top" arrow>
          <Grid item sx={{ mx: 1, mb: 1, height: '40px' }}>
            <DatePicker
              label="Requested ship date"
              clearable
              value={requestedDate}
              onChange={setRequestedDate}
              slotProps={{ field: { clearable: true }, textField: { size: 'small' } }}
            />
          </Grid>
        </Tooltip>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={9.5}>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', height: 'calc(100vh - 205px)' }}>
              <CustomDataGrid
                dontGetRowHeight
                data={cartItems}
                gridColumns={CHECKOUT}
                disableSelectionOnClick
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableColumnReorder
                pinnedColumns={{
                  left: ['picture'],
                  right: ['vendor', 'total', 'quantity', 'actions'],
                }}
                sx={{
                  '& .MuiDataGrid-cell': {
                    cursor: 'default !important',
                  },
                }}
                loading={isLoading}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={2.5}>
          <CardContent>
            {carts.filter(cart => cart.orderable && cart.items?.length > 0).map((cart, index) => (
              <Typography variant="body2" key={index}>
                {cart.agoraCategory} {cart.items.length} items (
                {fCurrency(cart.items.reduce((acc, item) => acc + item.price * item.quantity, 0))})
              </Typography>
            ))}
            <Typography variant="body1" sx={{ mt: 1 }}>
              Subtotal ({cartItems.reduce((count, item) => (item.quantity > 0 ? count + 1 : count), 0)} item
              {cartItems.length !== 1 ? 's' : ''}):{' '}
              <Typography variant="body1" component="span" fontWeight="bold">
                {fCurrency(
                  cartItems.reduce((acc, item) => {
                    if (item.quantity > 0) {
                      return acc + item.price * item.quantity;
                    }
                    return acc;
                  }, 0)
                )}
              </Typography>
            </Typography>

            <SubmitOrder
              cartItems={cartItems}
              itemErrorCount={itemErrorCount}
              thePersonFor={thePersonFor}
              requestedDate={requestedDate}
            />
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
}

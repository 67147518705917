import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getBudgetsByFacility } from '../../../../api/budgets';
import { BudgetBox } from '../../orders/new-order';
import { useSelector } from '../../../../redux/store';

Budgets.propTypes = {
  selectedRow: PropTypes.object,
};

export default function Budgets({ selectedRow }) {
  const {
    data: { facilities },
  } = useSelector((state) => state.budget);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [facility, setFacility] = useState(
    selectedRow
      ? {
          id: selectedRow.id,
          label: selectedRow.facilityName,
          facilityBookingTypeId: selectedRow.facilityBookingTypeId,
        }
      : ''
  );

  const fetchData = async () => {
    if (facility?.id) {
      try {
        const response = await getBudgetsByFacility(facility.id, date);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching facility budget:', error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [facility, date]);

  return (
    <>
      <Grid item xs={12}>
        {!selectedRow && (
          <Box sx={{ mt: 1 }}>
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Facility
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle1" sx={{ mb: 1, ml: 3 }}>
                  Month
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  fullWidth
                  value={facility}
                  onChange={(event, newValue) => {
                    setFacility(newValue);
                  }}
                  options={facilities?.map((type) => ({
                    id: type.id,
                    label: type.facilityName,
                    facilityBookingTypeId: type.facilityBookingTypeId,
                  }))}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Grid>
                <Grid item xs={2} sx={{ ml: 3 }}>
                  <DatePicker
                    openTo="month"
                    views={['month', 'year']}
                    value={date}
                    onChange={(dateValue) => {
                      setDate(new Date(dateValue.getFullYear(), dateValue.getMonth(), 1));
                    }}
                  />
                </Grid>
              
            </Grid>
          </Box>
        )}
        {data?.length > 0 && (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {data?.map((budget) => (
              <Grid item xs={3} key={budget.agoraCategoryId}>
                <BudgetBox catId={budget.agoraCategoryId} facId={facility?.id} budgetDate={date} setBudgetDate={setDate} budgetData={data} />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
}

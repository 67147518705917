import { TextField } from '@mui/material';

export default function SimpleAutocompleteProps()
{ 
  return {
    size: "small",
    sx: {
      width: '100%',
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 },
      '& .MuiFormLabelRoot ': 'none',
    },
    isOptionEqualToValue: (option, value) => option?.value === value?.value,
    renderInput: (params) => (
      <TextField
        {...params}
        type="search"
        label=""
        variant="outlined"
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off',
        }}
      />
    )
  };
}

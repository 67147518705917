import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Iconify from '../../iconify';
import { useTabs } from '../../../context/TabContext';

CustomTextPanel.propTypes = {
  activeFilter: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  gridId: PropTypes.string,
};

export default function CustomTextPanel({ activeFilter, filters, setFilters, gridId }) {
  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;
  const { activeTab } = useTabs();
  const sessionStorageKey = `tab-${activeTab.key}-filters-${baseUrl}-${gridId}`;

  const [value, setValue] = useState(filters[activeFilter]?.value || '');
  const updateSessionStorage = (newFilters) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(newFilters));
  };

  useEffect(() => {
    setValue(filters[activeFilter]?.value || '');
  }, [activeFilter, filters]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    const newFilters = newValue
      ? { ...filters, [activeFilter]: { value: newValue, operator: 'contains' } }
      : { ...filters };

    if (!newValue) {
      delete newFilters[activeFilter];
    }
    setFilters(newFilters);
    updateSessionStorage(newFilters);
  };

  const handleClear = () => {
    setValue('');
    const newFilters = { ...filters };
    delete newFilters[activeFilter];
    setFilters(newFilters);
    updateSessionStorage(newFilters);
  };

  return (
    <TextField
      name="autocomplete=off"
      autoComplete="off"
      value={value}
      onChange={handleChange}
      fullWidth
      autoFocus
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <Iconify icon={'ri:close-fill'} width={20} height={20} sx={{ cursor: 'pointer' }} />
            </IconButton>
          </InputAdornment>
        ),
        style: { height: '30px', borderRadius: '4px', border: `solid 1px #404042` },
      }}
    />
  );
}

import { Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteUsers, disableUsers, enableUsers } from '../../../../api/user/index';
import { getUsers } from '../../../../redux/slices/users';
import { useSelector } from '../../../../redux/store';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { USERS_COLUMNS } from '../../../../reusable-components/datagrid/permissionColumns';
import CustomToolbar from './CustomToolbar';
import { useSnackbar } from '../../../../reusable-components/snackbar';

export default function UsersView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSelection, setShowSelection] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectionType, setSelectionType] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { users },
  } = useSelector((state) => state.users);
  const isLoading = useSelector((state) => state.users.isLoading);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const deleteSelection = async (userIds) => {
    if (selectionType === 'Delete') {
      const response = await deleteUsers(userIds);
      if (response.data) {
        if (response.data > 1) enqueueSnackbar(`${response.data} users deleted`, { variant: 'success' });
        if (response.data === 1) enqueueSnackbar(`${response.data} user deleted`, { variant: 'success' });
        if (response.data < 1) enqueueSnackbar(`No users deleted`, { variant: 'error' });
      }
      dispatch(getUsers());
    } else if (selectionType === 'Disable') {
      //disable
      const response = await disableUsers(userIds);
      if (response.data) {
        if (response.data > 1) enqueueSnackbar(`${response.data} users disabled`, { variant: 'success' });
        if (response.data === 1) enqueueSnackbar(`${response.data} user disabled`, { variant: 'success' });
        if (response.data < 1) enqueueSnackbar(`No users disabled`, { variant: 'error' });
      }
    } else if (selectionType === 'Enable') {
      //enable
      const response = await enableUsers(userIds);
      if (response.data) {
        if (response.data > 1) enqueueSnackbar(`${response.data} users enabled`, { variant: 'success' });
        if (response.data === 1) enqueueSnackbar(`${response.data} user enabled`, { variant: 'success' });
        if (response.data < 1) enqueueSnackbar(`No users enabled`, { variant: 'error' });
      }
    }
  };

  const customToolbar = useCallback(
    () => (
      <CustomToolbar
        selected={selected}
        setSelected={setSelected}
        showSelection={!!showSelection}
        setShowSelection={setShowSelection}
        deleteSelection={deleteSelection}
        selectionType={selectionType}
        setSelectionType={setSelectionType}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSelection, selected]
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-users-view"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          data={users}
          gridColumns={USERS_COLUMNS}
          sort={[{ field: 'fullName', sort: 'asc' }]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick={!!showSelection}
          checkboxSelection={!!showSelection}
          keepNonExistentRowsSelected
          onRowSelectionModelChange={(newSelection) => {
            setSelected(newSelection);
          }}
          rowSelectionModel={selected}
          onRowClick={(params) => {
            if (showSelection) return;
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            navigate(`/dashboard/admin/users/${params.id}`);
          }}
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
        />
      </Grid>
    </>
  );
}

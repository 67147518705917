import { createSearchParams, Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import Page404 from './pages/Page404';
import { useScopeCheck } from './reusable-components/scopes';
//auth
import { ForgotPasswordPage, LoginPage, NewPasswordPage, ResetPasswordPage } from './pages/auth';

//dashboard
import {
  AdminBudgets,
  AdvancedSearch,
  Cart,
  Catalog,
  CatalogItem,
  Census,
  Checkout,
  CheckoutAll,
  CompletedRentals,
  CurrentRentals,
  DashboardHome,
  EmailRecipients,
  Emails,
  ExcelUpload,
  Facilities,
  Groups,
  Invoice,
  Statements,
  Invoices,
  InvoicingReports,
  InvoicingVendors,
  Order,
  OrderGuide,
  OrderGuides,
  Orders,
  PendingRentals,
  PurchasingCategories,
  Rental,
  RentalCart,
  RentalCatalog,
  RentalCheckout,
  RentalSettings,
  RentalVendorsView,
  RentalSummary,
  RentalOrder,
  Reports,
  Shop,
  SingleUser,
  SiteRules,
  Subscriptions,
  SystemNotices,
  Tasks,
  Ticket,
  Tickets,
  User,
  Users,
  Vendors,
  VendorCatalog,
  Warehouse,
  WarehouseVendors,
  WarehouseItem,
  InvoicingSettings,
} from './pages/dashboard';
import Page403 from './pages/Page403';
import TicketSettingsView from './components/dashboard/tickets/TicketSettingsView';
import URLChangeTracker from './components/trackers/URLChangeTracker';
import ReceivedEmails from './pages/dashboard/admin/ReceivedEmails';

// ----------------------------------------------------------------------

export default function Router() {
  URLChangeTracker();
  const routes = useRoutes([
    {
      path: '/auth',
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/auth/login" />, index: true },
        { path: 'login', element: <LoginPage /> },
        { path: 'forgot-password', element: <ForgotPasswordPage />, search: createSearchParams({ email: '' }) },
        { path: 'new-password', element: <NewPasswordPage /> },
        { path: 'resetpassword', element: <ResetPasswordPage /> },
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: 'home', element: <DashboardHome /> },
        { path: 'advanced-search', element: <AdvancedSearch /> },
        { path: 'home/user/:id', element: <SingleUser />, search: createSearchParams({ id: '' }) },
        { path: 'orders/new-order/cart/:facilityId', element: <Cart /> },
        { path: 'orders/new-order/checkout/:agoraCategoryId', element: <Checkout /> },
        { path: 'orders/new-order/checkout', element: <CheckoutAll /> },
        { path: 'orders/new-order/:facilityId', element: <Shop /> },
        { path: 'orders/:id', element: <Order />, search: createSearchParams({ id: '' }) },
        { path: 'orders', element: <Orders /> },
        { path: 'reports', element: <Reports /> },
        { path: 'invoicing/manual', element: <Invoices /> },
        { path: 'invoicing/pendingFilter', element: <Invoices /> },
        { path: 'invoicing/awaitingSubmission', element: <Invoices /> },
        { path: 'invoicing/pending', element: <Invoices /> },
        {
          path: `invoicing/previous`,
          element: <Invoices />,
          search: createSearchParams({ start: '', end: '' }),
        },
        { path: 'invoicing/complete', element: <Statements /> },
        { path: 'invoicing/incomplete', element: <Statements /> },
        {
          path: 'invoicing/reports',
          element: <InvoicingReports />,
          search: createSearchParams({ start: '', end: '' }),
        },
        { path: 'invoicing/vendors', element: <InvoicingVendors /> },
        { path: 'invoicing/settings', element: <InvoicingSettings /> },
        {
          path: 'invoicing/manual/:id',
          element: <Invoice />,
          search: createSearchParams({ id: '' }),
        },
        {
          path: 'invoicing/pendingFilter/:id',
          element: <Invoice />,
          search: createSearchParams({ id: '' }),
        },
        {
          path: 'invoicing/awaitingSubmission/:id',
          element: <Invoice />,
          search: createSearchParams({ id: '' }),
        },
        {
          path: 'invoicing/pending/:id',
          element: <Invoice />,
          search: createSearchParams({ id: '' }),
        },
        {
          path: 'invoicing/previous/:id',
          element: <Invoice />,
          search: createSearchParams({ id: '' }),
        },
        { path: 'tickets/settings', element: <TicketSettingsView /> },
        { path: 'tickets/:id', element: <Ticket />, search: createSearchParams({ id: '', subticket: '' }) },
        { path: 'tickets', element: <Tickets /> },
        { path: 'admin/budgets', element: <AdminBudgets /> },
        { path: 'admin/catalog', element: <Catalog /> },
        { path: 'admin/catalog/:id', element: <CatalogItem />, search: createSearchParams({ id: '' }) },

        { path: 'admin/census', element: <Census /> },
        { path: 'admin/order-guides', element: <OrderGuides /> },
        { path: 'admin/order-guides/:id', element: <OrderGuide />, search: createSearchParams({ id: '' }) },
        { path: 'admin/users', element: <Users /> },
        { path: 'admin/facilities', element: <Facilities /> },
        { path: 'admin/users/:id', element: <User />, search: createSearchParams({ id: '' }) },
        { path: 'admin/purchasing-categories', element: <PurchasingCategories /> },
        { path: 'admin/emails', element: <Emails /> },
        { path: 'admin/received-emails', element: <ReceivedEmails /> },
        { path: 'admin/vendors', element: <Vendors /> },
        { path: 'super-admin/item-warehouse', element: <Warehouse /> },
        { path: 'super-admin/item-warehouse/:id', element: <WarehouseItem />, search: createSearchParams({ id: '' }) },
        { path: 'super-admin/warehouse-vendors', element: <WarehouseVendors /> },
        { path: 'super-admin/facilities', element: <Facilities /> },
        { path: 'super-admin/email-recipients', element: <EmailRecipients /> },
        { path: 'super-admin/tasks', element: <Tasks /> },
        { path: 'super-admin/site-rules', element: <SiteRules /> },
        { path: 'super-admin/groups', element: <Groups /> },
        { path: 'super-admin/excel', element: <ExcelUpload /> },
        { path: 'super-admin/vendor-catalog', element: <VendorCatalog /> },
        { path: 'super-admin/system-notices', element: <SystemNotices /> },
        { path: 'rentals/pending-rentals', element: <PendingRentals /> },
        { path: 'rentals/current-rentals', element: <CurrentRentals /> },
        { path: 'rentals/previous-rentals', element: <CompletedRentals /> },
        { path: 'rentals/rental-catalog', element: <RentalCatalog /> },
        { path: 'rentals/rental-vendors', element: <RentalVendorsView /> },
        { path: 'rentals/rental-settings', element: <RentalSettings /> },
        {
          path: 'rentals/rental-order/:orderId/:orderStatus',
          element: <RentalOrder />,
          search: createSearchParams({ orderId: '', orderStatus: '' }),
        },
        {
          path: 'rentals/new-rental',
          element: <Rental />,
          search: createSearchParams({ facilityId: '', category: '', residentId: '', subcategoryId: '' }),
        },
        {
          path: 'rentals/rental-orders/rental-cart/:facilityId',
          element: <RentalCart />,
          search: createSearchParams({ facilityId: '', category: '', residentId: '' }),
        },
        {
          path: 'rentals/new-rental/rental-cart/',
          element: <RentalCart />,
          search: createSearchParams({ facilityId: '', category: '', residentId: '' }),
        },
        {
          path: 'rentals/new-rental/rental-checkout/:residentId',
          element: <RentalCheckout />,
          search: createSearchParams({ facilityId: '', category: '', residentId: '' }),
        },
        {
          path: 'rentals/new-rental/rental-order-summary',
          element: <RentalSummary />,
          search: createSearchParams({ facilityId: '', category: '', residentId: '', orderNumber: '' }),
        },
        { path: 'admin/emails', element: <Emails /> },
        { path: 'admin/vendors', element: <Vendors />, search: createSearchParams({ tab: ''}) },
        { path: 'admin/subscriptions', element: <Subscriptions /> },
        { path: 'tickets/:id', element: <Ticket />, search: createSearchParams({ id: '', subticket: '' }) },
        { path: 'tickets', element: <Tickets /> },
      ],
    },
    {
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

import { Button, Grid } from '@mui/material';
import { HOST_API_URL } from '../../../../config-global';
import Iconify from '../../../../reusable-components/iconify';
import NewVendorModal from './NewVendorModal';
import VendorAPIModal from './VendorAPIModal';


export default function CustomToolbar() {

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" alignItems="flex-end" spacing={1} sx={{ mb: 1 }}>
            {
              <Grid item>
                <NewVendorModal />
                <VendorAPIModal />
                <Button disabled
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    window.open(`${HOST_API_URL}Api/Invoices/DownloadVendors`, '_blank');
                  }}
                  startIcon={<Iconify icon="file-icons:microsoft-excel" />}
                >
                  Download Invoice Vendors
                </Button>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { addItemToCart, clearBadgeFromRedux, removeItemFromCart } from '../../../../redux/slices/rentals';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import Image from '../../../../reusable-components/image';
import Lightbox from '../../../../reusable-components/lightbox';

RentalItem.propTypes = {
  item: PropTypes.object,
};

export default function RentalItem({ item }) {
  const dispatch = useDispatch();
  const {
    data: { cart },
  } = useSelector((state) => state.rentals);
  const { user } = useSelector((state) => state.user);
  const [currentItem, setCurrentItem] = useState(null);
  const [openNotPreferredDialog, setOpenNotPreferredDialog] = useState(false);
  const location = useLocation();
  const residentId = Number(new URLSearchParams(location.search).get('residentId'));

  useEffect(() => {
    const currentRental = cart.find(
      (cartItem) => cartItem.itemId === item.itemId && cartItem.residentId === residentId
    );
    if (currentRental) {
      setCurrentItem(currentRental);
    } else {
      setCurrentItem(null);
    }
  }, [cart, residentId, item]);

  const CustomIconButton = styled(Iconify)({
    cursor: 'pointer',
    color: '#fff',
  });

  const handleClickAddToCart = () => {
    if (!item.isPreferred) {
      setOpenNotPreferredDialog(true);
    } else {
      handleAddCart();
    }
  };
  const handleAddCart = () => {
    dispatch(addItemToCart(item.itemId, residentId, user.id, currentItem?.cartId ?? 0));
    setTimeout(() => {
      dispatch(clearBadgeFromRedux());
    }, 1000);
  };
  const handleRemoveCart = () => {
    dispatch(removeItemFromCart(currentItem.cartItemId));
  };
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  const handleClose = () => {
    setOpenNotPreferredDialog(false);
  };
  const handleConfirm = () => {
    setOpenNotPreferredDialog(false);
    handleAddCart();
  };

  return (
    <Grid item key={item} xs={3} style={{ maxWidth: '300px' }}>
      <Paper
        key={item.itemId}
        elevation={12}
        sx={{
          textAlign: 'left',
          cursor: 'pointer',
          position: 'relative',
          padding: '4px',
          width: '200px',
          height: '320px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {currentItem?.quantity > 0 ? (
          <div style={{ padding: '20px' }}>
            <AddToCartDiv>
              <CustomIconButton icon="lucide:trash" width={12} height={12} onClick={() => handleRemoveCart()} />
              <span>&nbsp;&nbsp;</span>
              <span style={{ color: '#fff' }}>{currentItem.quantity}</span>
              <span>&nbsp;&nbsp;</span>{' '}
              <CustomIconButton
                icon="iconamoon:sign-plus-bold"
                width={12}
                height={12}
                onClick={() => handleClickAddToCart()}
              />
            </AddToCartDiv>
          </div>
        ) : (
          <IconButton
            onClick={() => handleClickAddToCart()}
            sx={{
              right: -20,
              top: -6,
              ml: 15,
              position: 'relative',
              color: '#b128bc',
            }}
          >
            <Tooltip title={`Add to Cart`}>
              <Iconify icon="iconamoon:sign-plus-circle-fill" width={24} height={24} />
            </Tooltip>
          </IconButton>
        )}
        <Image
          alt="attachment"
          src={item.image}
          sx={{
            borderRadius: 1.5,
            width: '100%',
            height: 'auto',
            maxHeight: '180px',
            objectFit: 'contain',
            margin: '0 auto',
          }}
          onClick={() => handleOpenLightbox()}
        />
        <Lightbox slides={[{ src: item.image }]} open={selectedImage >= 0} close={handleCloseLightbox} />

        {item.isPreferred && (
          <Chip
            label="Preferred Vendor"
            color="info"
            variant="outlined"
            size="small"
            icon={<CheckCircleOutlineIcon />}
            sx={{ mt: 1 }}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            maxWidth: '200px',
            marginTop: 'auto',
          }}
        >
          <Typography variant="subtitle2">
            <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Price: </span>${item.price}
            <span>/</span>
            <span>{item.pricingTypeName}</span>
          </Typography>

          <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'baseline', fontWeight: 'bold' }}>
            <Typography variant="subtitle2">
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Vendor:</span> {item.vendorName}{' '}
            </Typography>
            <Typography variant="subtitle2" sx={{ maxWidth: '200', color: '#4ca5e7', textDecoration: 'underline' }}>
              <Tooltip title={`Proceed to Vendor Site`}>
                <Link
                  href={item.vendorURL}
                  color="#4ca5e7"
                  variant="inherit"
                  target="_blank"
                  marginLeft={1}
                  style={{ fontSize: '12px' }}
                >
                  {' '}
                  {item.vendorItemId}
                </Link>
              </Tooltip>
            </Typography>
          </Box>
          <Typography variant="subtitle2">
            <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Description:</span> {item.description}
          </Typography>
        </Box>
      </Paper>

      {openNotPreferredDialog && (
        <div>
          <Dialog
            open={openNotPreferredDialog}
            onClose={handleClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle id="confirm-dialog-title" sx={{ textAlign: 'center' }}>
              Not Preferred Vendor
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="confirm-dialog-description">
                Are you sure you want to continue with this non-preferred vendor?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Grid>
  );
}
const AddToCartDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 7,
  height: 22,
  borderRadius: '10px',
  backgroundColor: '#b128bc',
  color: 'secondary',
  padding: theme.spacing(0.5),
  margin: '2.5px 3px 2.5px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: '2',
}));

import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useGridApiRef, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid-pro';
import { Checkbox, Box } from '@mui/material';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector } from '../../../../../redux/store';

RolesValueTable.propTypes = {
  roleType: PropTypes.object,
  tempRoles: PropTypes.array,
  setTempRoles: PropTypes.func,
};

export default function RolesValueTable({ roleType, tempRoles, setTempRoles }) {
  const apiRef = useGridApiRef();
  const {
    data: { user },
  } = useSelector((state) => state.users);
  const { roleValues } = user;
  const { optionValues, innerOptionValues, id } = roleType;
  const usesFacility = roleType?.useModel === 'Facility';

  const [allValues, setAllValues] = useState([]);
  const [rows, setRows] = useState(null);

  useEffect(() => {
    setTempRoles(roleValues);
  }, [roleValues]);

  const roleIsActive = tempRoles?.reduce((result, role) => {
    result[`${role?.userPermissionTypeId}-${role?.modelId}-${role?.modelAttachmentId ?? 0}`] = true;
    return result;
  }, {});

  useEffect(() => {
    const newOptionValues = innerOptionValues
      ? optionValues?.flatMap((option) => innerOptionValues?.map((innerOption) => ({ option, innerOption })))
      : optionValues?.map((option) => ({ option }));
    setAllValues(newOptionValues);
    const activeRoles = roleValues?.reduce((result, role) => {
      result[`${role?.userPermissionTypeId}-${role?.modelId}-${role?.modelAttachmentId ?? 0}`] = true;
      return result;
    }, {});
    setRows(
      (
        newOptionValues?.map(({ option, innerOption }, index) => ({
          id: index,
          mainKey: innerOption?.key === -1 ? null : `${id}-${option.key}-${innerOption?.key ?? 0}`,
          mainOption: option.value,
          mainOptionKey: option.key,
          subOption: innerOption ? innerOption.value : '',
          optionSubOptions: option?.subOptions ?? [],
          innerOptionSubOptions: innerOption?.subOptions ?? [],
          subkeys: (innerOption?.subOptions ?? [])
            .filter((subOpt) => subOpt?.userPermissionTypeId)
            .map((subOpt) => `${subOpt?.userPermissionTypeId}-${option.key}-${subOpt.key}`),
          category: innerOption ? innerOption.value : '',
        })) ?? []
      )
        .map((row) => ({
          ...row,
          sortBy: `${usesFacility && row.mainOption === user.facility ? '0' : '1'}${
            row.mainKey && activeRoles[row.mainKey] ? '0' : '1'
          }
      ${row.subkeys.some((key) => activeRoles[key]) ? '0' : '1'}${row.mainOption}`,
        }))
        .sort((row, nextRow) => {
          const sortByLowerCase = row.sortBy.toLowerCase();
          const nextSortByLowerCase = nextRow.sortBy.toLowerCase();
          return (sortByLowerCase > nextSortByLowerCase) - (sortByLowerCase < nextSortByLowerCase);
        })
    );
  }, [optionValues, innerOptionValues, id, usesFacility]);

  const splitRowKey = (rowId) => {
    const parts = rowId.split('-');
    const id = parts[0];
    const option = parts[1];
    const innerOption = parts[2] !== 'undefined' ? parts[2] : undefined;
    return {
      userPermissionTypeId: parseInt(id, 10),
      modelId: parseInt(option, 10),
      modelAttachmentId: parseInt(innerOption, 10) || undefined,
    };
  };

  const handleCheck = (event, rowId) => {
    event.stopPropagation();
    event.preventDefault();
    const roleIndex = tempRoles.findIndex(
      (role) => `${role.userPermissionTypeId}-${role.modelId}-${role.modelAttachmentId ?? 0}` === rowId
    );
    if (roleIndex !== -1)
      setTempRoles(
        tempRoles.filter(
          (role) => `${role.userPermissionTypeId}-${role.modelId}-${role.modelAttachmentId ?? 0}` !== rowId
        )
      );
    else setTempRoles([...tempRoles, splitRowKey(rowId)]);
  };

  const handleCheckAll = (event, col = null) => {
    event.stopPropagation();
    event.preventDefault();
    const visibleRowIds = gridFilteredSortedRowIdsSelector(apiRef?.current?.state);
    const userPermissionTypeId = col
      ? (
          allValues?.flatMap((val) =>
            val.innerOption?.subOptions?.filter((opt) => opt.header === col).map((opt) => opt.userPermissionTypeId)
          ) ?? []
        ).find(() => true)
      : null;
    const visibleRows = rows?.filter((row) => visibleRowIds.includes(row.id));
    const keys = userPermissionTypeId
      ? visibleRows.flatMap((row) => row.subkeys).filter((key) => key.startsWith(`${userPermissionTypeId}-`))
      : visibleRows.map((row) => row.mainKey).filter((key) => key);
    if (keys.every((key) => roleIsActive[key])) {
      setTempRoles(
        tempRoles.filter(
          (role) => !keys.includes(`${role.userPermissionTypeId}-${role.modelId}-${role.modelAttachmentId ?? 0}`)
        )
      );
      return;
    }
    setTempRoles([...tempRoles, ...keys.filter((key) => !roleIsActive[key]).map((key) => splitRowKey(key))]);
  };

  const columns = useMemo(() => {
    const hasSubOptions = allValues?.some((val) =>
      val.innerOption?.subOptions?.some((innerVal) => innerVal.userPermissionTypeId)
    );
    return [
      {
        field: 'mainOption',
        headerName: roleType.modelLabel,
        type: 'customText',
        flex: 1,
        renderCell: (params) => (
          <Box sx={{ color: usesFacility && user.facility === params.row.mainOption ? 'info.main' : 'default' }}>
            {params.row.mainOption}
          </Box>
        ),
      },
      ...Array.from(
        new Set(
          (allValues?.flatMap((val) => val.option?.subOptions?.map((opt) => opt.header)) ?? []).filter((col) => col)
        )
      ).map((col) => ({
        field: `extraMainOpt-${col.replace(' ', '-')}`,
        type: 'customText',
        headerName: col,
        flex: 1,
        valueGetter: (params) => params.row.optionSubOptions.find((subOpt) => subOpt.header === col)?.value,
        renderCell: (params) => params.row.optionSubOptions.find((subOpt) => subOpt.header === col)?.value,
      })),
      ...Array.from(
        new Set(
          (allValues?.flatMap((val) => val.innerOption?.subOptions?.map((opt) => opt.header)) ?? []).filter(
            (col) => col
          )
        )
      ).map((col) => ({
        field: `extraSubOpt-${col.replace(' ', '-')}`,
        flex: 1,
        headerName: col,
        valueGetter: (params) => params.row.innerOptionSubOptions.find((subOpt) => subOpt.header === col)?.value,
        checkBox: () => {
          if (
            !allValues.some((val) =>
              val.innerOption?.subOptions.some((innerVal) => innerVal.header === col && innerVal.userPermissionTypeId)
            )
          )
            return col;
          const userPermissionTypeId = (
            allValues?.flatMap((val) =>
              val.innerOption?.subOptions?.filter((opt) => opt.header === col).map((opt) => opt.userPermissionTypeId)
            ) ?? []
          ).find(() => true);
          const allChecked =
            rows
              ?.flatMap((row) => row.subkeys)
              .filter((key) => key.startsWith(`${userPermissionTypeId}-`))
              .every((key) => roleIsActive[key]) ?? false;
          return (
            <Checkbox
              checked={allChecked}
              sx={{ mr: 1, color: '#fff' }}
              onClick={(event) => handleCheckAll(event, col)}
            />
          );
        },
        type: 'customText',
        renderCell: (params) => {
          const subOpt = params.row.innerOptionSubOptions.find((subOpt) => subOpt.header === col);
          const key = subOpt ? `${subOpt.userPermissionTypeId}-${params.row.mainOptionKey}-${subOpt.key}` : null;
          return (
            <>
              {subOpt?.userPermissionTypeId && (
                <Checkbox checked={roleIsActive[key] || false} onChange={(event) => handleCheck(event, key)} />
              )}
              {subOpt?.value}
            </>
          );
        },
      })),
      ...(allValues?.some(({ innerOption }) => innerOption)
        ? [
            {
              field: 'subOption',
              flex: 1,
              type: 'customText',
              renderCell: (params) => (
                <>
                  {hasSubOptions && params.row.mainKey && (
                    <Checkbox
                      checked={roleIsActive[params.row.mainKey] || false}
                      onChange={(event) => handleCheck(event, params.row.mainKey)}
                    />
                  )}
                  {params.row?.subOption}
                </>
              ),
              checkBox: () =>
                hasSubOptions ? (
                  <Checkbox
                    checked={rows?.filter((row) => row.mainKey).every((row) => roleIsActive[row.mainKey]) ?? false}
                    sx={{ mr: 1, color: '#fff' }}
                    onClick={(event) => handleCheckAll(event)}
                  />
                ) : (
                  ''
                ),
              headerName: roleType.modelAttachmentLabel,
            },
          ]
        : []),
      ...(!hasSubOptions
        ? [
            {
              field: 'active',
              headerName: 'Active',
              flex: 1,
              renderHeader: () => (
                <>
                  <Checkbox
                    checked={rows?.filter((row) => row.mainKey).every((row) => roleIsActive[row.mainKey]) ?? false}
                    sx={{ mr: 1, color: '#fff' }}
                    onClick={(event) => handleCheckAll(event)}
                  />
                  Active
                </>
              ),
              renderCell: (params) => (
                <Checkbox
                  checked={roleIsActive[params.row.mainKey] || false}
                  onChange={(event) => handleCheck(event, params.row.mainKey)}
                />
              ),
              valueGetter: (params) =>
                roleIsActive[params.row.mainKey]
                  ? `${usesFacility && params.row.mainOption === user.facility ? '1' : '0'}1${params.row.label}`
                  : `${usesFacility && params.row.mainOption === user.facility ? '1' : '0'}0${
                      params.row.mainOption || params.row.company || params.row.subOption
                    }`,
            },
          ]
        : []),
    ];
  }, [allValues, roleIsActive, user.facility, rows]);

  return rows && rows.length > 0 ? (
    <CustomDataGrid
      gridId={`${roleType.id}-admin-roles-modal`}
      data={rows ?? []}
      gridColumns={columns}
      disableSelectionOnClick
      apiRef={apiRef}
      forceReRender={tempRoles}
    />
  ) : (
    <div style={{ textAlign: 'center' }}>
      There is no {roleType.label} set up for this {roleType?.useModel}.
    </div>
  );
}

import { Button, Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import NewTicketTest from '../../../../components/dashboard/tickets/ticket/newTicket/NewTicketTest';
import { getFacilitiesAndUsersList } from '../../../../redux/slices/tickets';

export default function NewTicket()
{
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();

  const { facilities, ticketTypes, ticketCategories, facilityCategories, defaultFacilityId } = useSelector((state) => state.tickets.data);
  const { orderById } = useSelector((state) => state.orders || {});
  const { facilityId } = orderById[id] || {};
  const { user } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([{ value: `${user?.firstName} ${user?.lastName}`, id: user?.email }]);

  useEffect(() => {
    dispatch(getFacilitiesAndUsersList());
  }, []);

  const handleClose = () => {
    setOpen(false);
    setUsers(user ? [{ value: `${user.firstName} ${user.lastName}`, id: user.email }] : []);
  };

  return (
    <div>
      <Button color="secondary" size="small" sx={{ minWidth: 120 }} variant="outlined" onClick={() =>  setOpen(true)} startIcon={<Iconify icon="eva:plus-outline" />}>
        New Ticket
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth>      
        <NewTicketTest
          setOpen={setOpen}
          facilities={facilities ?? []}
          users={users ?? []}
          ticketTypes={ticketTypes ?? []}
          ticketCategories={ticketCategories ?? []}
          facilityCategories={facilityCategories ?? []}
          facilityId={facilityId ?? defaultFacilityId ?? 0}
          orderNumber={location.pathname.includes('/orders/') ? id: null}
        />
      </Dialog>
    </div>
  );
}

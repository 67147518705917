import PropTypes from 'prop-types';
import TextFormField from './IntegrationTextFormField';
import CheckboxFormField from './IntegrationCheckboxFormField';
import Row from './IntegrationFormRow';

ExcelOrderImportIntegration.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func
};

export default function ExcelOrderImportIntegration({ value, setValue })
{
  return <>    
    <Row>
      <TextFormField
        header={"Vendor Item Id Column"}
        value={value.vendorItemIdCol}
        onChange={(e) => setValue({ ...value, vendorItemIdCol: e.target.value })}
      />   
      <TextFormField
        header={"Description Column"}
        value={value.descriptionCol}
        onChange={(e) => setValue({ ...value, descriptionCol: e.target.value })}
      />   
      <TextFormField
        header={"UOM Column"}
        value={value.uomCol}
        onChange={(e) => setValue({ ...value, uomCol: e.target.value })}
      />   
    </Row>
    <Row>
      <TextFormField
        header={"Quantity Column"}
        value={value.qtyCol}
        onChange={(e) => setValue({ ...value, qtyCol: e.target.value })}
      />
      <TextFormField
        header={"Status Column"}
        value={value.statusCol}
        onChange={(e) => setValue({ ...value, statusCol: e.target.value })}
      />
      <TextFormField
        header={"Message Column"}
        value={value.messageCol}
        onChange={(e) => setValue({ ...value, messageCol: e.target.value })}
      />
    </Row>
    <Row>
      <TextFormField
        header={`Order Number ${value.getOrderNumberByRegex ? "Regex Search" : "Column"}`}
        value={value.orderNumberSearch}
        onChange={(e) => setValue({ ...value, orderNumberSearch: e.target.value })}
      />
      <TextFormField
        header={`Account Search ${value.getAccountByRegex ? "Regex Search" : "Column"}`}
        value={value.accountSearch}
        onChange={(e) => setValue({ ...value, accountSearch: e.target.value })}
      />     
    </Row>
    <Row>     
      <CheckboxFormField
        label={"Get Order Number By Regex"}
        checked={value.getOrderNumberByRegex ?? false}
        onChange={(e) => setValue({ ...value, getOrderNumberByRegex: e.target.checked })}
      />
      <CheckboxFormField
        label={"Get Account By Regex"}
        checked={value.getAccountByRegex ?? false}
        onChange={(e) => setValue({ ...value, getAccountByRegex: e.target.checked })}
      />    
    </Row>   
  </>;
}

import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { InvoicingView } from '../../../components/dashboard/invoicing';
import { useDispatch, useSelector } from '../../../redux/store';
import CustomToolbar from '../../../components/dashboard/invoicing/CustomToolbar';
import {
  getInvoiceDataFromRedux,
  getManualInvoicing,
  getPendingInvoicing,
  getInvoicingApprovers,
} from '../../../redux/slices/invoicing';
import { useScopeCheck } from '../../../reusable-components/scopes';
import CompletedInvoices from '../../../components/dashboard/invoicing/CompletedInvoices';

export default function NewInvoicing() {
  const location = useLocation();
  const section = location.pathname.split('/').pop();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get('start');
  const endDate = searchParams.get('end');

  const [showSelection, setShowSelection] = useState('');
  const [selectedToProcess, setSelectedToProcess] = useState([]);

  const {
    data: { invoiceData },
  } = useSelector((state) => state.invoicing);
  const getInvoiceFormData = async () => {
    dispatch(getInvoiceDataFromRedux());
  };
  useEffect(() => {
    dispatch(getInvoicingApprovers());
    dispatch(getManualInvoicing());
    dispatch(getPendingInvoicing({ pending: true }));

    if (invoiceData?.length === 0) {
      getInvoiceFormData();
    }
  }, [endDate, section, startDate]);
  const [activeRows, setActiveRows] = useState([]);
  const customToolbar = useCallback(() => {
    const isFiltered = (filter) => filter === section;
    return <CustomToolbar isFiltered={isFiltered} activeRows={activeRows} showSelection = {showSelection} setShowSelection = {setShowSelection} setSelectedToProcess = {setSelectedToProcess}/>;
  }, [activeRows, showSelection]);

  return (
    <>
      <Helmet>
        <title>Invoicing | Platform Solutions</title>
      </Helmet>
      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {section !== "previous" && <InvoicingView CustomToolbar={customToolbar} setActiveRows={setActiveRows} showSelection = {showSelection} setShowSelection = {setShowSelection} setSelectedToProcess = {setSelectedToProcess}/>}
          {section === "previous" && <CompletedInvoices CustomToolbar={customToolbar} setActiveRows={setActiveRows} />}
        </Grid>
      </Grid>
    </>
  );
}

import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Tooltip, Grid, TextField, Button, Stack, IconButton, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { getManualInvoicing, saveApprovers } from '../../../../../redux/slices/invoicing';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import Iconify from '../../../../../reusable-components/iconify';

SubmitApprovers.propTypes = {
  selectedApprovers: PropTypes.any,
};
export default function SubmitApprovers({ selectedApprovers }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveApprovers = async () => {
    setIsSubmitting(true);
    const response = await dispatch(saveApprovers(id, selectedApprovers));
    if (response === 'success') {
      enqueueSnackbar('Approvers modified successfully', { variant: 'success' });
      const res = await dispatch(getManualInvoicing());
      if (res.data?.length > 0) {
        const nextId = res.data?.[0]?.id;
        navigate(`/dashboard/invoicing/manual/${nextId}`);
      } else {
        const pathSegments = location.pathname.split('/');
        const url = pathSegments.slice(0, -1).join('/');
        navigate(url);
      }
    } else {
      enqueueSnackbar('Error modifying approvers', { variant: 'error' });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Tooltip title={''} placement="top" arrow>
        <Box>
          <AwaitButton
            variant="contained"
            color="secondary"
            onClick={handleSaveApprovers}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<Iconify icon="material-symbols:order-approve-outline-sharp" />}
          >
            Send for approval
          </AwaitButton>
        </Box>
      </Tooltip>
    </>
  );
}

import { Grid } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';

export default function RentalSubcategorySelect() {
  const {
    data: { categoryData },
  } = useSelector((state) => state.rentals);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchParams] = useSearchParams();
  const selectedCategory = searchParams.get('category');
  const category = searchParams.get('category');
  const facilityId = searchParams.get('facilityId');
  const residentId = searchParams.get('residentId');
  const handleItemClick = (item) => {
    if (item === selectedItem) {
      setSelectedItem(null);
      navigate(`/dashboard/rentals/new-rental?facilityId=${facilityId}&category=${category}&residentId=${residentId}`);
    } else {
      setSelectedItem(item);
      navigate(
        `/dashboard/rentals/new-rental?facilityId=${facilityId}&category=${category}&residentId=${residentId}&subcategory=${item}`
      );
    }
  };
  const filteredSubcategories = categoryData
    .filter((item) => item.categoryName === selectedCategory)
    .map((item) => item.subcategoryName);

  return (
    <>
      <div style={{ width: '100%' }}>
        <header>
          <Grid container spacing={2}>
            {selectedCategory &&
              filteredSubcategories.map((subcategoryName, index) => (
                <Grid item key={subcategoryName}>
                  <AwaitButton
                    sx={{
                      textAlign: 'center',
                      cursor: 'pointer',
                      color: selectedCategory === subcategoryName ? '#4CA5E7' : '#6D6E71',
                      border: selectedCategory === subcategoryName ? '1px solid #4CA5E7' : '1px solid #6D6E71',
                      borderRadius: '18px',
                      mb: 2,
                    }}
                    onClick={() => handleItemClick(subcategoryName)}
                    style={{
                      color: selectedItem === subcategoryName ? '#4CA5E7' : '#6D6E71',
                      border: selectedItem === subcategoryName ? '1px solid #4CA5E7' : '1px solid #6D6E71',
                    }}
                  >
                    {subcategoryName}
                  </AwaitButton>
                </Grid>
              ))}
          </Grid>
        </header>
      </div>
    </>
  );
}

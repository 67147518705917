import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { postGLForm } from '../../../../api/invoicing';
import { getVendors, getGLs } from '../../../../redux/slices/invoicing';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import FormTextWithValue from '../../../../reusable-components/form/FormTextWithValue';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import AddMoreCategoryAutocomplete from './AddMoreCategoryAutocomplete';

NewGLModal.propTypes = {
  row: PropTypes.object,
  edit: PropTypes.bool,
};

export default function NewGLModal({ row = null, edit = false }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { vendors } = useSelector((state) => state.vendors.data);
  const { vendors: invVendors, facilities } = useSelector((state) => state.invoicing.data.invoicingVendors);

  const facOptions = useMemo(() => facilities, [facilities]);

  const existingVendorNames = useMemo(() =>
    invVendors?.map((v) => ({ facilityId: v.facilityId, name: v.name.toLowerCase().trim() }))
      ?.concat(vendors?.map((v) => ({ facilityId: 0, name: v.name.toLowerCase().trim() })))
    , [vendors, invVendors]);

  const [value, setValue] = useState({
    id: '',
    glCode: '',
    facility: '',
    category: '',
    categoryId: '',
    categoryDesc: '',
    subcategory: '',
  });

  const handleSave = async () => {

    const response = await postGLForm(value);
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      enqueueSnackbar(`Successfully added ${response.data.glCode}`, {
        variant: 'success',
      });
      dispatch(getVendors());
      dispatch(getGLs());
      setOpen(false);
    } else
      enqueueSnackbar(`Error: Could not add vendor`, {
        variant: 'error',
      });
  };

  const handleClickOpen = () => {
    setValue({
      id: edit ? row.subcategoryId : '',
      glCode: edit ? row.glCode : '',
      facility: edit ? facOptions?.find(f => f.value === row.facilityId) ?? '' : '',
      categoryDesc: edit ? row.categoryDesc : '',
      category: edit ? row.category : '',
      categoryId: edit ? row.categoryId : '',
      subcategory: edit ? row.subcategory : '',
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      id: '',
      glCode: '',
      category: '',
      categoryId: '',
      categoryDesc: '',
      subcategory: '',
    });
    setOpen(false);
  };

  const vendorError = value.glCode === '';
  //  || row?.name !== value.name && existingVendorNames?.find(vendName => vendName.name === value.name?.toLowerCase().trim()
  //    && (vendName.facilityId === facOptions?.find(o => o.value === value.facility?.value)?.value || vendName.facilityId === 0));
  const facilityError = value.facility === '' || value.facility === undefined || value.facility === null;

  function isObject(value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }
  useEffect(() => {
    if (open) {
      const onEdit = !Object.keys(value).every((key) => {
        let value1 = value?.[key];
        let value2 = edit ? row[key] : '';
        if (isObject(value1)) value1 = value1.label;
        if (isObject(value2)) value2 = value2.label;

        if (!Number.isNaN(+value2)) return Number(value1) === Number(value2);
        return (value1 === null && value2 === '') || (value1 === '' && value2 === null) || value1 === value2;
      });
      setEdited(onEdit);
    }
  }, [value]);


  return (
    <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
      {edit ? <IconButton
        variant="outlined"
        sx={{ mr: 1 }}
        size="small"
        color="secondary"
        onClick={handleClickOpen}
      >
        <EditIcon />
      </IconButton>
        :
        <Button
          variant="outlined"
          sx={{ mr: 1 }}
          size="small"
          color="secondary"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          New Invoice GL
        </Button>
      }
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {edit ? 'Edit Invoice GL' : 'New Invoice GL'}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
                <FormTextWithValue value={value} title="GL Code*" flex={6} name="glCode" setValue={setValue} />
                <Box flex={6}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Facility *
                  </Typography>
                  <Autocomplete size="small"
                    fullWidth
                    value={value.facility}
                    isOptionEqualToValue={(option, value) => option?.id === value?.facility?.id}
                    options={facOptions||[]}
                    onChange={(e, newValue) => setValue({ ...value, facility: newValue })}
                    renderInput={(params) => <TextField {...params} label="Search or select" />}
                    sx={{
                      width: "100%",
                      mt: 1,
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      '& .MuiFormLabel-root ': { display: 'none' },
                    }}
                  />
                </Box>
              </Stack>
              {edited && (vendorError || facilityError) && (
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
                  <Alert severity="error" sx={{ width: '100%' }}>
                    {value.glCode === '' ? 'Missing GL' : vendorError && 'GL already exists'}
                    {vendorError && facilityError && ' & '}
                    {facilityError && 'Missing facility'}
                  </Alert>
                </Stack>)}
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="GL Description" flex={12} name="subcategory" setValue={setValue} />
              </Stack>
              <Stack>
                <Box flex={6}>
                  <AddMoreCategoryAutocomplete value={value} setValue={setValue} setError={setCategoryError} />
                  {/*<Autocomplete size="small"*/}
                  {/*  fullWidth*/}
                  {/*  value={value.facility}*/}
                  {/*  isOptionEqualToValue={(option, value) => option?.id === value?.facility?.id}*/}
                  {/*  options={facOptions}*/}
                  {/*  onChange={(e, newValue) => setValue({ ...value, facility: newValue })}*/}
                  {/*  renderInput={(params) => <TextField {...params} label="Search or select" />}*/}
                  {/*  sx={{*/}
                  {/*    width: "100%",*/}
                  {/*    mt: 1,*/}
                  {/*    '& legend': { display: 'none' },*/}
                  {/*    '& fieldset': { top: 0 },*/}
                  {/*    '& .MuiFormLabel-root ': { display: 'none' },*/}
                  {/*  }}*/}
                  {/*/>*/}
                </Box>
              </Stack>
              {/*<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>*/}
              {/*  <FormTextWithValue value={value} title="GL Category" flex={6} name="category" setValue={setValue} />*/}
              {/*  <FormTextWithValue value={value} title="GL Category Desc" flex={6} name="categoryDesc" setValue={setValue} />*/}
              {/*</Stack>*/}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={vendorError || facilityError || categoryError}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </ScopeGuard>
  );
}

import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { InvoicingVendors } from '../../../components/dashboard/invoicing';


export default function Vendors() {
  return (
    <>
      <Helmet>
        <title>Invoicing | Platform Solutions</title>
      </Helmet>
      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <InvoicingVendors/>
        </Grid>
      </Grid>
    </>
  );
}

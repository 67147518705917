import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license-pro'; // Import LicenseInfo
import { pdfjs } from 'react-pdf';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import SnackbarProvider from './reusable-components/snackbar';
import { TabProvider } from './context/TabContext';
import { TabStoreProvider } from './context/TabStoreContext';

// lightbox
/* eslint-disable import/no-unresolved */
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.css';
import './base_css.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// Set MUI License Key
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// ----------------------------------------------------------------------

export default function App() {
  const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter baseUrl={baseUrl}>
          <TabProvider>
            <TabStoreProvider>
              <ThemeProvider>
                <ErrorBoundary fallback={'An error has occurred'}>
                  <SnackbarProvider>
                    <Router />
                  </SnackbarProvider>
                </ErrorBoundary>
              </ThemeProvider>
            </TabStoreProvider>
          </TabProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </HelmetProvider>
  );
}

import { Button, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { HOST_API_URL } from '../../../../config-global';
import { getVendors as getPurchasingVendors } from '../../../../redux/slices/vendors';
import { useDispatch, useSelector } from '../../../../redux/store';
import { createColumns } from '../../../../reusable-components/datagrid/custom-filters';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { VENDORS } from '../../../../reusable-components/datagrid/vendorColumns';
import Iconify from '../../../../reusable-components/iconify';
import ElectronicVendorModal from './ElectronicVendorModal';


export default function ElectronicVendorsTab() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { data: { vendors } } = useSelector((state) => state.vendors);
  const isLoading = useSelector((state) => state.vendors.isLoading);

  const getData = async () => {
    await dispatch(getPurchasingVendors());
  }

  useEffect(() => {
    getData();
  }, [dispatch]);

  useEffect(() => {
    setData(vendors?.filter(v => v?.hasInvoices === true));
  }, [vendors]);

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => (<ElectronicVendorModal row={params.row} edit />),
    id: 'actions',
  };

  const columns = createColumns([...VENDORS, actionButtons]);

  const customToolbar = useCallback(() => (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
      <Grid item>
        <Grid container direction="row" alignItems="flex-end" spacing={1} sx={{ mb: 1 }}>
          {
            <Grid item>
              <ElectronicVendorModal />
              <Button disabled
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => {
                  window.open(`${HOST_API_URL}Api/Invoices/DownloadVendors`, '_blank');
                }}
                startIcon={<Iconify icon="file-icons:microsoft-excel" />}
              >
                Download
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  ), [])


  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="invoicing-vendors-purchasing-vendors"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          sort={[{ field: 'invoiceVendorId', sort: 'asc' }]}
          gridColumns={[...columns]}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
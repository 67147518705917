import { Button, Grid, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Iconify from '../iconify';

DateRangePickerSelect.propTypes = {
  datePickerSx: PropTypes.object,
  buttonSx: PropTypes.object,
  noStickyFilter: PropTypes.bool,
  setValue: PropTypes.func,
  value: PropTypes.array
}

export default function DateRangePickerSelect({ datePickerSx, buttonSx, noStickyFilter, value, setValue }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const applyParams = (startDate, endDate) => {  
    if (noStickyFilter) {
      if (setValue) setValue(startDate, endDate);
      return;
    }
    const formattedStartDate = startDate?.toISOString();
    const formattedEndDate = endDate?.toISOString();
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('start', formattedStartDate);
    searchParams.set('end', formattedEndDate);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLast7Days = () => {
    applyParams(new Date(new Date().setDate(new Date().getDate() - 6)), new Date());
  };

  const handleLast30Days = () => {
    applyParams(new Date(new Date().setDate(new Date().getDate() - 29)), new Date());
  };

  const handleThisMonth = () => {
    applyParams(new Date(new Date().setDate(1)), new Date());
  };

  const handleLast6Months = () => {
    const today = new Date();
    const sixMonthsAgo = new Date(new Date().setMonth(today.getMonth() - 6));
    applyParams(sixMonthsAgo, today);
  };

  const handle1Year = () => {
    applyParams(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date());
  };

  const handleThisYear = () => {
    applyParams(new Date(new Date().setMonth(0, 1)), new Date());
  };

  const handleAllTime = () => {
    applyParams(new Date(new Date().setFullYear(2020, 0, 1)), new Date());
  };

  const handleClose = (e) => {
    switch (e.target.textContent) {
      case 'Last 7 days':
        handleLast7Days();
        break;
      case 'Last 30 days':
        handleLast30Days();
        break;
      case 'This month':
        handleThisMonth();
        break;
      case 'Last 6 months': // Add this case
        handleLast6Months();
        break;
      case '1 year':
        handle1Year();
        break;
      case 'This year':
        handleThisYear();
        break;
      case 'All time':
        handleAllTime();
        break;
      default:
        break;
    }

    setAnchorEl(null);
  };
  const dateToLabel = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const now = new Date();

    const diffInDays = Math.floor((end - start) / (24 * 60 * 60 * 1000));
    const diffInYears = now.getFullYear() - start.getFullYear();
    const diffInMonths = diffInYears * 12 + now.getMonth() - start.getMonth();

    if (diffInYears === 0 && diffInMonths === 0 && start.getDate() === 1) {
      return 'This month';
    }
    if (diffInMonths >= 6 && diffInMonths < 12) {
      return 'Last 6 months';
    }
    if (diffInYears === 1 && start.getMonth() === now.getMonth() && start.getDate() === now.getDate()) {
      return '1 year';
    }

    if (diffInYears === 0 && start.getMonth() === 0 && start.getDate() === 1) {
      return 'This year';
    }

    if (startDate === '1900-01-01' && endDate === now.toISOString().substring(0, 10)) {
      return 'All time';
    }
    if (diffInDays <= 7) {
      return 'Last 7 days';
    }
    if (diffInDays <= 30) {
      return 'Last 30 days';
    }
    return 'Custom';
  };

  const handleDateChange = (newValue) => {
    if (newValue[0] && newValue[1]) {
      applyParams(newValue[0], newValue[1]);
    }
  };

  const dateValues = noStickyFilter ? value || [] : [new Date(searchParams.get('start')), new Date(searchParams.get('end'))];

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item>
          <DateRangePicker
            startText="Start date"
            endText="End date"
            value={dateValues}
            onChange={handleDateChange}           
            sx={{
              height: '20px ',
              width: '300px',
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabel-root ': { display: 'none' },
              '& .MuiInputBase-input.MuiOutlinedInput-input': {
                padding: '3px',
                ...datePickerSx,
              },
            }}
          />
        </Grid>
        <Grid item sx={{ ml: 2 }}>
          <>
            <Button
              variant="outlined"
              onClick={handleClick}
              size="small"
              sx={{
                mt: 1.5,
                borderRadius: 1,
                color: 'text.primary',
                borderColor: 'text.primary',
                '&:active': { borderColor: 'primary.main' },
                height: '30px ',
                minHeight: '30px ',
               ...buttonSx

              }}
              endIcon={<Iconify icon="mdi:chevron-down" width={24} height={24} />}
            >
              {dateToLabel(noStickyFilter ? dateValues.length > 0 ? dateValues[0] : new Date() : searchParams.get('start'),
                noStickyFilter ? dateValues.length > 1 ? dateValues[1] : new Date() : searchParams.get('end'))}
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={handleClose} disableRipple>
                Last 7 days
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                Last 30 days
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                This month
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                Last 6 months
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                1 year
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                This year
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                All time
              </MenuItem>
            </StyledMenu>
          </>
        </Grid>
      </Grid>
    </>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

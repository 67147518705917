import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postAddItem } from '../../../api/rentals';
import {
  getCategoryData,
  getPricingTypeOptions,
  getRentalItems,
  getRentalVendors,
} from '../../../redux/slices/rentals';
import { useDispatch, useSelector } from '../../../redux/store';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { formatMoney } from '../../../utils/formatNumber';

AddRentalItem.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function AddRentalItem({ open, setOpen }) {
  const {
    data: { categoryData, rentalVendors, pricingTypes },
  } = useSelector((state) => state.rentals);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    description: '',
    category: '',
    subcategory: '',
    vendor: '',
    vendorItemID: '',
    uomType: '',
    uomAmount: '',
    price: '',
    pricingType: '',
    capAmount: '',
    daysToCap: '',
    vendorItemLink: '',
    imageUrl: '',
  });
  const dispatch = useDispatch();
  const steps = ['Item Details', 'Vendor Information', 'Additional Information'];
  const uomTypes = ['EA', 'Per Case'];

  const handleNext = () => {
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const isStepValid = useMemo(() => {
    switch (activeStep) {
      case 0:
        return formData.description && formData.category && !!formData.subcategory;
      case 1:
        return formData.vendor && formData.vendorItemID && formData.uomType && formData.uomAmount;
      case 2:
        return formData.price && formData.pricingType;
      default:
        return false;
    }
  }, [
    activeStep,
    formData.category,
    formData.description,
    formData.price,
    formData.pricingType,
    formData.subcategory,
    formData.uomAmount,
    formData.uomType,
    formData.vendor,
    formData.vendorItemID,
  ]);

  const handleAddItem = async () => {
    const response = await postAddItem(formData);
    if (response.data.success) {
      dispatch(getRentalItems());
      enqueueSnackbar('Rental Item Added', { variant: 'success' });
    } else {
      enqueueSnackbar('Error while adding item', { variant: 'error' });
    }

    navigate(`/dashboard/rentals/rental-catalog`);
    setOpen(false);
  };

  const seenCategories = new Set();
  const categoryOptions = useMemo(
    () =>
      categoryData.reduce((acc, item) => {
        if (!seenCategories.has(item.categoryName)) {
          seenCategories.add(item.categoryName);
          acc.push({
            label: item.categoryName,
            id: item.categoryId,
          });
        }
        return acc;
      }, []),
    [categoryData]
  );

  const setSubcategoryOptions = () =>
    categoryData
      .filter((item) => item.categoryId === formData.category?.id && !!item.subcategoryId)
      .map((item) => ({
        subcategoryId: item.subcategoryId,
        subcategoryName: item.subcategoryName,
      }));

  const vendorOptions = rentalVendors.map((vendor) => ({
    id: vendor.id,
    name: vendor.name,
  }));

  useEffect(() => {
    dispatch(getCategoryData());
    dispatch(getRentalVendors());
    dispatch(getPricingTypeOptions());
  }, [dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          color: 'rgba(112, 112, 112, 0.5)',
        }}
      >
        Add New Rental Item
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel sx={{ color: 'rgba(112, 112, 112, 0.5)', mb: 4 }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <>
            <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Description *</DialogContentText>
            <TextField
              fullWidth
              margin="dense"
              value={formData.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
            />
            <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Category *</DialogContentText>
            <Autocomplete
              options={categoryOptions}
              renderInput={(params) => <TextField {...params} margin="dense" />}
              getOptionLabel={(option) => option?.label || ''}
              value={formData.category}
              onChange={(e, value) => {
                setFormData({ ...formData, category: value, subcategory: null });
              }}
            />
            <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Subcategory *</DialogContentText>
            <Autocomplete
              options={setSubcategoryOptions()}
              renderInput={(params) => <TextField {...params} margin="dense" />}
              getOptionLabel={(option) => option?.subcategoryName || ''}
              value={formData.subcategory}
              onChange={(e, value) => {
                handleInputChange('subcategory', value);
              }}
              noOptionsText="No subcategories found"
            />
          </>
        )}
        {activeStep === 1 && (
          <>
            <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Vendor *</DialogContentText>
            <Autocomplete
              options={vendorOptions}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => <TextField {...params} margin="dense" />}
              value={formData.vendor}
              onChange={(e, value) => handleInputChange('vendor', value)}
            />
            <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Vendor Item ID *</DialogContentText>
            <TextField
              fullWidth
              margin="dense"
              value={formData.vendorItemID}
              onChange={(e) => handleInputChange('vendorItemID', e.target.value)}
            />
            <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>UOM Amount *</DialogContentText>
            <TextField
              fullWidth
              margin="dense"
              value={formData.uomAmount}
              onChange={(e) => handleInputChange('uomAmount', e.target.value)}
            />
            <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>UOM Type *</DialogContentText>
            <Autocomplete
              options={uomTypes}
              renderInput={(params) => <TextField {...params} margin="dense" />}
              value={formData.uomType}
              onChange={(e, value) => handleInputChange('uomType', value)}
            />
          </>
        )}
        {activeStep === 2 && (
          <>
            <div style={{ display: 'flex', gap: '16px', marginBottom: '8px' }}>
              <div style={{ flex: 1 }}>
                <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Price *</DialogContentText>
                <TextField
                  fullWidth
                  margin="dense"
                  value={formData.price}
                  onChange={(e) => handleInputChange('price', formatMoney(e.target.value))}
                />
              </div>
              <div style={{ flex: 1 }}>
                <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                  Pricing Type *
                </DialogContentText>
                <Autocomplete
                  options={pricingTypes}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => <TextField {...params} margin="dense" />}
                  value={formData.pricingType}
                  onChange={(e, value) => handleInputChange('pricingType', value)}
                />
              </div>
            </div>
            <div style={{ display: 'flex', gap: '16px', marginBottom: '8px' }}>
              <div style={{ flex: 1 }}>
                <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Cap Rate ($)</DialogContentText>
                <TextField
                  fullWidth
                  margin="dense"
                  value={formData.capAmount}
                  onChange={(e) => handleInputChange('capAmount', formatMoney(e.target.value))}
                />
              </div>
              <div style={{ flex: 1 }}>
                <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Days to Cap</DialogContentText>
                <TextField
                  fullWidth
                  margin="dense"
                  value={formData.daysToCap}
                  onChange={(e) => handleInputChange('daysToCap', e.target.value)}
                />
              </div>
            </div>
            <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Vendor Item Link</DialogContentText>
            <TextField
              fullWidth
              margin="dense"
              value={formData.vendorItemLink}
              onChange={(e) => handleInputChange('vendorItemLink', e.target.value)}
            />
            <DialogContentText style={{ fontWeight: 'bold', marginBottom: '4px' }}>Image URL</DialogContentText>
            <TextField
              fullWidth
              margin="dense"
              value={formData.imageUrl}
              onChange={(e) => handleInputChange('imageUrl', e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep > 0 && (
          <Button onClick={handleBack} color="primary" variant="contained">
            Previous
          </Button>
        )}
        {activeStep < steps.length - 1 ? (
          <Button onClick={handleNext} color="primary" variant="contained" disabled={!isStepValid}>
            Next
          </Button>
        ) : (
          <Button
            onClick={handleAddItem}
            variant="contained"
            color="primary"
            sx={{ boxShadow: 3, fontWeight: 'bold' }}
            disabled={!isStepValid}
          >
            Add Item
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

export async function getReports() {
  const response = await axios.get('/Report/Reports');
  return response;
}

export async function getReportOptions(reportId) {
  const response = await axios.post(`/Report/GetReportTypes?reportId=${reportId}`);
  return response;
}

export async function getOrDownloadItemsOrderedReport(formValues, allItems, download) {
  const formData = objectToFormData({
    startDate: formValues.startDate,
    endDate: formValues.endDate,
    facilities: formValues.facilities?.map((f) => f.value),
    allItems
  });

  const response = download ?
    await axios.post('/Report/DownloadItemsOrderedReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/ItemsOrderedReport', formData);

  return response;
}

export async function getOrDownloadFacilityComparisonReport(formValues, download) {
  const formData = objectToFormData({
    facilities: formValues.facilities?.map((f) => f.value),
    categories: formValues.categories?.map((f) => f.value),
    startDate: formValues.startDate,
    endDate: formValues.endDate,
  });

  const response = download ?
    await axios.post('/Report/DownloadFacilityComparisonReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/FacilityComparisonReport', formData);

  return response;
}

export async function getOrDownloadVendorComparisonReport(formValues, download) {
  const formData = objectToFormData({
    vendors: formValues.vendors?.map((f) => f.value),
    categories: formValues.categories?.map((f) => f.value),
    startDate: formValues.startDate,
    endDate: formValues.endDate,
  });

  const response = download ?
    await axios.post('/Report/DownloadVendorComparisonReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/VendorComparisonReport', formData);

  return response;
}

export async function getOrDownloadTopLevelBudgetReport(formValues, download) {
  const formData = objectToFormData({
    companies: formValues.companies?.map((f) => f.value),
    facilities: formValues.facilities?.map((f) => f.value),
    subcategories: formValues.subcategories?.map((f) => f.value),
    categories: formValues.categories?.map((f) => f.value),
    startDate: formValues.startDate,
    endDate: formValues.endDate,
  });

  const response = download ?
    await axios.post('/Report/DownloadTopLevelBudgetReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/TopLevelBudgetReport', formData);

  return response;
}

export async function getOrDownloadFacilityHistoryReport(formValues, download) {
  const dateRange = [];
  if (formValues?.dateValues) {
    formValues.dateValues.forEach((range) => {
      range.forEach((date) => {
        const formattedDate = date.format('YYYY-MM-DD');
        if (dateRange?.length > 0) {
          const lastDate = dateRange[dateRange?.length - 1];
          const nextDay = new Date(lastDate);
          nextDay.setDate(nextDay.getDate() + 1);

          const nextDayFormatted = nextDay.toISOString().split('T')[0];

          if (nextDayFormatted === formattedDate) {
            dateRange.pop(); // Remove the last date if it's the day before the current date
          }
        }
        dateRange.push(formattedDate);
      });
    });
  }

  const formData = objectToFormData({
    categories: formValues.categories?.map((f) => f.value),
    dates: dateRange,
    facilityId: formValues.facilities && formValues.facilities.length > 0 ? formValues.facilities[0].value : 0
  });

  const response = download ?
    await axios.post('/Report/DownloadFacilityHistoryReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/FacilityHistoryReport', formData);

  return response;
}

export async function getOrDownloadPoInvoiceMatchupReports(formValues, download) {
  const formData = objectToFormData({
    facilities: formValues.facilities?.map((f) => f.value),
    vendors: formValues.vendors?.map((f) => f.value),
    startDate: formValues.startDate,
    endDate: formValues.endDate,
  });

  const response = download ?
    await axios.post('/Report/DownloadPOInvoiceMatchupReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/POInvoiceMatchupReport', formData);

  return response;
}

export async function getOrDownloadSubstituteReport(formValues, download) {
  const formData = objectToFormData({
    companies: formValues.companies?.map((f) => f.value),
    categories: formValues.categories?.map((f) => f.value),
    subcategories: formValues.subcategories?.map((f) => f.value),
    facilities: formValues.facilities?.map((f) => f.value),
    startDate: formValues.startDate,
    endDate: formValues.endDate,
  });

  const response = download ?
    await axios.post('/Report/DownloadSubstituteReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/SubstituteReport', formData);

  return response;
}

export async function getOrDownloadFacilitiesInvoiceReport(formValues, download) {
  const formData = objectToFormData({
    facilities: formValues.facilities?.map((f) => f.value),
    vendors: formValues.vendors?.map((f) => f.value),
    startDate: formValues.startDate,
    endDate: formValues.endDate,
  });

  const response = download ?
    await axios.post('/Report/DownloadFacilitiesInvoiceReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/InvoicesReport', formData);

  return response;
}

export async function getOrDownloadInvoiceIssuesReport(formValues, download) {
  const formData = objectToFormData({
    facilities: formValues.facilities?.map((f) => f.value),
    startDate: formValues.startDate,
    endDate: formValues.endDate,
  });

  const response = download ?
    await axios.post('/Report/DownloadInvoiceIssuesReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/InvoiceIssuesReport', formData);

  return response;
}

export async function getOrDownloadCheckInvoicePDFsReport(formValues, download) {
  const formData = objectToFormData({  
    vendors: formValues.vendors?.map((f) => f.value),
    facilityId: formValues.facilities && formValues.facilities.length > 0 ? formValues.facilities[0].value : 0,
    skip: formValues.skip,
    take: formValues.take
  });

  const response = download ?
    await axios.post('/Report/DownloadCheckInvoicePDFsReport', formData, { responseType: 'blob' }) :
    await axios.post('/Report/CheckInvoicePDFsReport', formData);

  return response;
}
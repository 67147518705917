import { IconButton, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';

RentalSearch.propTypes = {
  setDebouncedValue: PropTypes.func,
  debouncedValue: PropTypes.string,
};

export default function RentalSearch({ debouncedValue, setDebouncedValue }) {
  const baseUrl = window.location.href;
  const savedSearch = JSON.parse(sessionStorage.getItem(baseUrl));
  const isRecent = savedSearch && new Date().getTime() - savedSearch?.timestamp < 300000;
  const [searchValue, setSearchValue] = useState(isRecent ? savedSearch.search : '');
  const [filteredData, setFilteredData] = useState([]);
  const {
    data: { items },
  } = useSelector((state) => state.rentals);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(searchValue), 400);
    return () => clearTimeout(handler);
  }, [searchValue, setDebouncedValue]);

  useEffect(() => {
    if (debouncedValue.trim()) {
      const results = items.filter((item) => item?.description?.toLowerCase().includes(debouncedValue.toLowerCase()));
      setFilteredData(results);
    } else {
      setFilteredData([]);
    }
  }, [debouncedValue, items]);

  useEffect(() => {
    if (debouncedValue.trim()) {
      sessionStorage.setItem(baseUrl, JSON.stringify({ search: debouncedValue, timestamp: Date.now() }));
    }
  }, [debouncedValue]);

  const handleSearch = (event) => {
    setSearchValue(event?.target.value);
  };

  const handleClearText = () => {
    setSearchValue('');
    setFilteredData([]);
    sessionStorage?.removeItem(baseUrl);
  };

  return (
    <>
      <TextField
        value={searchValue}
        onChange={handleSearch}
        size="small"
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClearText}>
                <Iconify
                  icon={'ri:close-fill'}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
          style: { height: '30px', borderRadius: '4px', border: `solid 1px #404042` },
        }}
      />
    </>
  );
}

import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Grid, Tooltip} from '@mui/material';
import PropTypes from 'prop-types';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import NotInterestedIcon from '@mui/icons-material/NotInterestedRounded';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { useDispatch } from '../../../redux/store';
import { COMPLETED_INVOICES } from '../../../reusable-components/datagrid/invoiceColumns';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import { addInvoiceLog } from '../../../redux/slices/invoicing';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { ScopeGuard } from '../../../reusable-components/scopes';
import { deleteInvoice, getCompleteInvoicesData } from '../../../api/invoicing';
import { HOST_API_URL } from '../../../config-global';
import PdfPopupButton from '../../../reusable-components/pdf-popup/PdfPopupButton';
import { fMonthDayYear } from '../../../utils/formatTime';
import InvoiceStatusModal from './InvoiceStatusModal';

CompletedInvoices.propTypes = {
  CustomToolbar: PropTypes.func,
  setActiveRows: PropTypes.func,
};

export default function CompletedInvoices({ CustomToolbar, setActiveRows }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getCompleteInvoicesData({ startDate: searchParams.get('start'), endDate: searchParams.get('end') })
      .then(res => {
        setData((res.data ?? []).sort((a, b) => new Date(b.date) - new Date(a.date)));
      })
      .finally(() => setLoading(false));
  }, [searchParams]);

  const handleDelete = (id) => {
    deleteInvoice(id).then(() => {
      enqueueSnackbar('Invoice deleted successfully', { variant: 'success' });
      setData(data => data.filter(invoice => invoice.id !== id))
    }).catch(() => enqueueSnackbar('Something has gone wrong', { variant: 'error' }));
  };

  const StatusButtons = (params) => {
    const { alternateSent, invoiceBookedTo, total, deleteFlag, dateDeleted, deletedBy } = params.row;
    return <>
      <Tooltip title={
        deleteFlag ? <div>Deleted by {deletedBy} on: {dateDeleted && fMonthDayYear(dateDeleted)}</div>
        : total === 0 ? <div>Total $0.00</div>
        : alternateSent ? <div>Booking vendor rejected invoice due to already having an invoice with that billing number</div>
        : !invoiceBookedTo ? <div>Sent to booking vendor</div>
        : <div>
          {invoiceBookedTo?.sentTo ? <div>Sent To: {invoiceBookedTo?.sentTo}</div> : ''}
          {invoiceBookedTo?.addedBy ? <div>Sent By: {invoiceBookedTo.addedBy}</div> : ''}
          {invoiceBookedTo?.dateAdded ? <div>Sent On: {fMonthDayYear(invoiceBookedTo?.dateAdded)}</div> : ''}
          {invoiceBookedTo?.sentAutomatically ? <div>Sent through the automatic invoice system</div> : ''}
        </div>}
      >
        {deleteFlag ? <CancelIcon color={'error'} />
          : total !== 0 ? <CheckCircleIcon color={alternateSent ? 'warning' : 'success'} />
          : total === 0 ? <NotInterestedIcon color={'warning'} />
          : ''}
      </Tooltip>
      <InvoiceStatusModal row={params.row} />
    </>;
  };

  const status = {
    field: 'status',
    headerName: 'Status',
    width: 75,
    renderCell: (params) => StatusButtons(params),
    id: 'status',
    className: 'status',
    sortable: false,
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    id: 'actions',
    className: 'actions',
    sortable: false,
    renderCell: (params) => (
      <>
        <ScopeGuard
          scopes={params.row.sourceMethod === 'Manual' ? ['ManualInvoice-Edit'] : ['Invoice-Edit']}
          allowAdmin
        >
          <ConfirmDialog
            content={`Are you sure you want to delete this invoice?`}
            action={() => handleDelete(params.row.id)}
          />
        </ScopeGuard>
        {params.row.hasMainFile && (
          <PdfPopupButton
            icon={<RemoveRedEyeIcon />}
            url={`${HOST_API_URL}Api/Invoices/ViewInvoiceFile?id=${params.row.id}&mainFile=true`}
            tooltipText={
              <iframe
                style={{ width: '100%', height: '100%' }}
                title="testing"
                src={`${HOST_API_URL}Api/Invoices/ViewInvoiceFile?id=${params.row.id}&mainFile=true`}
              />
            }
            largeTooltip
            row={params.row}
          />
        )}
      </>
    ),
   
  };

  return <Grid item xs={12}>
    <CustomDataGrid
      gridId="invoicing-invoice-complete"
      applyQuickFilter
      data={data}
      gridColumns={[...COMPLETED_INVOICES, status, actions]}
      CustomLeftToolbar={CustomToolbar}
      setActiveRows={setActiveRows}
      onCellClick={(row) => {
        const selection = window.getSelection().toString();
        if (selection.length > 0 || row.field === 'actions' || row.field === 'status') return;
        dispatch(addInvoiceLog({
          invoiceId: row.id,
          messageType: 'View',
        }));
        navigate(`/dashboard/invoicing/previous/${row.id}`);
      }}
      isLoading={!data || loading}
    />
  </Grid>;
}

import { Box, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';
import ElectronicVendorsTab from './ElectronicVendorsTab';
import InvoicingVendorTable from './InvoicingVendorTable';
import ManualVendorTable from './ManualVendorTable';
import ManualGLTable from './ManualGLTable';

export default function InvoicingVendors() {
  const [tab, setTab] = useState(0);

  const isAdmin = useScopeCheck(['Admin'], true);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const renderTabContent = () => {
    switch (tab) {
      case 0:
        return (
          <ManualVendorTable />
        );
      case 1:
        return (
          <Box sx={{ width: "100%" }}>
            <ElectronicVendorsTab />
          </Box>
        );
      case 2:
        return (
          <ManualGLTable/>
        );

      default:
        return (
          <InvoicingVendorTable />
        );
    }
  };


  return (
    <>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="transparent"
        sx={{
          borderRadius: '4px 4px 0px 0px',
        }}
      >
        <CustomTab
          label={
            <Box>
              <Typography variant="subtitle1" color="success.contrastText" component="span">
                Invoice Vendors
              </Typography>
            </Box>
          }
        />

        {isAdmin ?
          <CustomTab
            label={
              <Box>
                <Typography variant="subtitle1" color="success.contrastText" component="span">
                  Purchasing Vendors
                </Typography>
              </Box>
            }
          />
          : <ScopeGuard scopes={['Admin']} >
            <CustomTab />
          </ScopeGuard>
        }

        <CustomTab
          label={
            <Box>
              <Typography variant="subtitle1" color="success.contrastText" component="span">
                Invoice GLs
              </Typography>
            </Box>
          }
        />

      </Tabs>

      {renderTabContent()}

    </>
  );
}


const CustomTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '160px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

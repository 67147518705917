import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { orderGuideBulkSubmit, vendorPreferencesBulkSubmit, orderGuideBulkSubmitExcel } from '../../../../api/order-guides';
import { getAllProducts, getManageOrderGuide, getOrderGuides } from '../../../../redux/slices/orderGuides';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { MANAGE_ITEMS } from '../../../../reusable-components/datagrid/orderGuidesColumns';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import OrderGuideExcelUpload from './OrderGuideExcelUpload';

export default function ManageOrderGuides() {
  const { control, setValue, getValues, watch, reset } = useForm({});
  const { enqueueSnackbar } = useSnackbar();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'preferredSubcategories',
  });
  const {
    fields: fieldsRest,
    append: appendRestricted,
    remove: removeRestricted,
  } = useFieldArray({
    control,
    name: 'restrictedSubcategories',
  });

  const {
    data: { groups, facilities, categories, vendors, subcategories, allProducts },
  } = useSelector((state) => state.orderGuides);

  const isLoading = useSelector((state) => state.orderGuides.isLoading);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [excelUploadData, setExcelUploadData] = useState(null);

  const modeWatch = watch('mode');
  const orderGuideTypeWatch = watch('orderGuideType');

  useEffect(() => {
    if (open && (subcategories?.length === 0 || vendors.length === 0)) {
      dispatch(getManageOrderGuide());
    }

    if (open && allProducts?.length === 0) {
      dispatch(getAllProducts());
    }
  }, [dispatch, open]);

  useEffect(() => {
    const categories = watch('categories');
    setTableData(allProducts.filter((product) => categories?.[0]?.value === product?.agoraCategory?.id));

    const existingSubsRest = watch('restrictedSubcategories').filter((sub) =>
      categories?.map((cat) => cat.value).includes(sub.mainCategoryId)
    );
    const newSubsRest = subcategories
      .filter(
        (subs) =>
          categories?.map((cat) => cat.value).includes(subs.mainCategoryId) &&
          !existingSubsRest.map((existingSub) => existingSub.mainCategoryId).includes(subs.mainCategoryId)
      )
      ?.map((subs) => ({ ...subs, vendor: [] }));
    removeRestricted();
    appendRestricted([...existingSubsRest, ...newSubsRest]);

    const existingSubsPref = watch('preferredSubcategories').filter((sub) =>
      categories?.map((c) => c.value).includes(sub.mainCategoryId)
    );
    const newSubsPref = subcategories
      .filter(
        (sub) =>
          categories?.map((cat) => cat.value).includes(sub.mainCategoryId) &&
          !existingSubsPref.map((existingSub) => existingSub.mainCategoryId).includes(sub.mainCategoryId)
      )
      ?.map((sub) => ({ ...sub, vendor: null }));
    remove();
    append([...existingSubsPref, ...newSubsPref]);
  }, [watch('categories'), allProducts]);

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset({
      orderGuideType: null,
      mode: 'Add',
      groups: [],
      facilities: [],
      categories: [],
      autofillVendor: null,
      restrictedAutofillVendor: [],
      preferredSubcategories: [],
      restrictedSubcategories: []
    });
    setExcelUploadData(null);
  };

  const categoryLookup = (id) => categories.find((cat) => cat.value === id)?.label;

  const handleSave = async () => {
    if (orderGuideTypeWatch === 'Vendor preferences' || orderGuideTypeWatch === 'Vendor restrictions') {
      const response = await vendorPreferencesBulkSubmit({ ...getValues() });

      if (response.status === 200) {
        enqueueSnackbar('Vendor preferences saved successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Vendor preferences failed to save', { variant: 'error' });
      }
    } else if (orderGuideTypeWatch === 'Order Guide Items') {
      const resp = await orderGuideBulkSubmit({ ...getValues() });

      if (resp.status === 200) {
        enqueueSnackbar('Order guide items saved successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Order guide items failed to save', { variant: 'error' });
      }
    } else if (orderGuideTypeWatch === 'Order Guide Excel Upload') {
      const resp = await orderGuideBulkSubmitExcel({ ...excelUploadData, mode: getValues().mode });

      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Order Guide Bulk Submit.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        enqueueSnackbar('Order guide items saved successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Order guide items failed to save', { variant: 'error' });
      }
    }
    dispatch(getOrderGuides());
    handleClose();
  };


  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={handleOpen}
        startIcon={<Iconify icon="mdi:gear-outline" />}
      >
        Manage Order Guides
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <form>
          <DialogTitle
            sx={{ textAlign: 'center', mt: 2 }}>
            Manage Order Guides
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Iconify icon="ic:sharp-close" width={28} height={28} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ overflowX: 'hidden', overflowY: 'scroll', height: '100%', width: '100%' }}>
            <>
              <Controller
                name={'orderGuideType'}
                control={control}
                rules={{ required: true }}
                defaultValue={null}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    sx={{ marginY: 1 }}
                    options={['Order Guide Items', 'Vendor preferences', 'Vendor restrictions', "Order Guide Excel Upload"]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Manage..."
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                    onChange={(e, inputValue) => {
                      field.onChange(inputValue);

                      setValue('items', []);
                      if (inputValue === 'Order Guide Items' && watch('categories')?.length > 1) {
                        setValue('categories', [watch('categories')?.[0]]);
                      }
                    }}
                  />
                )}
              />

              <Typography>Mode</Typography>
              <Controller
                name={'mode'}
                control={control}
                defaultValue={'Add'}
                rules={{ required: { required: true } }}
                render={({ field }) => (
                  <>
                    <Checkbox checked={modeWatch === 'Add'} onChange={() => field.onChange('Add')} /> Add
                    <Checkbox checked={modeWatch === 'Remove'} onChange={() => field.onChange('Remove')} /> Remove
                    <Checkbox checked={modeWatch === 'Replace'} onChange={() => field.onChange('Replace')} /> Replace
                    all
                  </>
                )}
              />
              {orderGuideTypeWatch === "Order Guide Excel Upload" && <OrderGuideExcelUpload excelUploadData={excelUploadData} setExcelUploadData={setExcelUploadData} />}
              {(orderGuideTypeWatch !== null && orderGuideTypeWatch !== "Order Guide Excel Upload") && (
                <>
                  <Controller
                    name={'groups'}
                    control={control}
                    defaultValue={[]}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        sx={{ my: 1 }}
                        fullWidth
                        value={watch('groups')}
                        filterOptions={(options, params) => {
                          const filter = createFilterOptions();
                          const filtered = filter(options, params);
                          return [{ label: 'Select All...', all: true }, ...filtered];
                        }}
                        onChange={(event, newValue) => {
                          const newVals = newValue.map(v => v.value);
                          setValue('facilities', facilities.filter(f => newVals.includes(f.extraValue)))
                          if (newValue.some((option) => option.all)) {
                            field.onChange(groups);
                            setValue('facilities', facilities)
                          } else {
                            field.onChange(newValue);
                          }
                        }}
                        options={groups}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Groups"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />

                  <Controller
                    name={'facilities'}
                    control={control}
                    defaultValue={[]}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        sx={{ my: 1 }}
                        fullWidth
                        value={watch('facilities')}
                        filterOptions={(options, params) => {
                          const filter = createFilterOptions();
                          const filtered = filter(options, params);
                          return [{ label: 'Select All...', all: true }, ...filtered];
                        }}
                        onChange={(event, newValue) => {
                          if (newValue.some((option) => option.all)) {
                            field.onChange(facilities);
                          } else {
                            field.onChange(newValue);
                          }
                        }}
                        options={facilities}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Facilities"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />

                  <Controller
                    name={'categories'}
                    control={control}
                    defaultValue={[]}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect={orderGuideTypeWatch !== 'Order Guide Items'}
                        value={watch('categories')}
                        sx={{ marginY: 1 }}
                        options={categories}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Categories"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                        onChange={(e, val) => {
                          if (orderGuideTypeWatch === 'Order Guide Items' && val.length > 1) {
                            field.onChange([val[val.length - 1]]);
                          } else {
                            field.onChange(val);
                          }
                        }}
                      />
                    )}
                  />

                  {orderGuideTypeWatch === 'Vendor preferences' && (
                    <>
                      <Controller
                        name={'autofillVendor'}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <Autocomplete
                            sx={{ my: 1 }}
                            fullWidth
                            value={watch('autofillVendor') || []}
                            onChange={(event, val) => {
                              field.onChange(val);
                              const subsWithNewVendor = watch('preferredSubcategories').map((s) => ({
                                ...s,
                                vendor: val,
                              }));
                              remove();
                              append([...subsWithNewVendor]);
                            }}
                            options={vendors}
                            renderInput={(params) => <TextField {...params} label="Autofill Vendor" />}
                          />
                        )}
                      />
                      {fields?.map((subcat, index) => (
                        <Box
                          key={`${subcat.mainCategoryId}_${subcat.id}`}
                          sx={{ width: '50%', display: 'inline-block', padding: 1 }}
                        >
                          <Autocomplete
                            sx={{ display: 'inline' }}
                            options={vendors}
                            value={watch(`preferredSubcategories.${index}.vendor`)}
                            onChange={(e, value) => {
                              setValue(`preferredSubcategories.${index}.vendor`, value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${categoryLookup(subcat.mainCategoryId)} - ${subcat.name}`}
                              />
                            )}
                          />
                        </Box>
                      ))}
                    </>
                  )}
                  {orderGuideTypeWatch === 'Vendor restrictions' && (
                    <>
                      <Controller
                        name={'restrictedAutofillVendor'}
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            sx={{ my: 1 }}
                            fullWidth
                            value={watch('restrictedAutofillVendor') || []}
                            filterOptions={(options, params) => {
                              const filter = createFilterOptions();
                              const filtered = filter(options, params);
                              return [{ label: 'Select All...', all: true }, ...filtered];
                            }}
                            options={vendors}
                            renderInput={(params) => <TextField {...params} label="Autofill Vendor" />}
                            onChange={(event, val) => {
                              if (val.some((option) => option.all)) {
                                field.onChange(vendors);
                              } else {
                                field.onChange(val);
                                const subsWithNewVendor = watch('restrictedSubcategories').map((s) => ({
                                  ...s,
                                  vendor: [...val],
                                }));
                                removeRestricted();
                                appendRestricted([...subsWithNewVendor]);
                              }
                            }}
                          />
                        )}
                      />
                      {fieldsRest?.map((subcat, index) => (
                        <Box
                          key={`${subcat.mainCategoryId}_${subcat.id}`}
                          sx={{ width: '50%', display: 'inline-block', padding: 1 }}
                        >
                          <Autocomplete
                            sx={{ display: 'inline' }}
                            multiple
                            disableCloseOnSelect
                            options={vendors}
                            value={watch(`restrictedSubcategories.${index}.vendor`)}
                            onChange={(e, value) => {
                              setValue(`restrictedSubcategories.${index}.vendor`, [...value]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${categoryLookup(subcat.mainCategoryId)} - ${subcat.name}`}
                              />
                            )}
                          />
                        </Box>
                      ))}
                    </>
                  )}
                </>
              )}
              {orderGuideTypeWatch === 'Order Guide Items' && (
                <>
                  <Controller name="items" control={control} render={() => null} />
                  <Grid item xs={12}>
                    <CustomDataGrid
                      gridId="admin-manage-order-guide-items"
                      checkboxSelection
                      keepNonExistentRowsSelected
                      boxSX={{ height: 'calc(100vh - 260px)' }}
                      data={[...tableData]}
                      gridColumns={[...MANAGE_ITEMS]}
                      sort={[{ field: 'subcategory', sort: 'asc' }]}
                      isLoading={isLoading}
                      disableSelectionOnClick
                      isModal
                      onRowSelectionModelChange={(selectedItemIds) => {
                        setValue('items', selectedItemIds);
                      }}
                      getRowId={(row) => row.id}
                    />
                  </Grid>
                </>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
              Cancel
            </Button>

            <AwaitButton
              variant="contained"
              color="primary"
              size="medium"
              sx={{ width: 150 }}
              type="submit"
              onClick={handleSave}
            >
              Save
            </AwaitButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

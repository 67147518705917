import PropTypes from 'prop-types';
import { useScopeCheck } from '../scopes';
import ManagerChips from './ManagerChips';

ManagerChipsWrapper.propTypes = {
  onDeleteSuccess: PropTypes.func,
  type: PropTypes.string,
  props: PropTypes.object,
};

export default function ManagerChipsWrapper(onDeleteSuccess, type, noFlex = false, props) {
  const hasFacilityAccess = useScopeCheck(['Facility-Manager'], true);

  return {
    field: 'manager',
    overrideArray: 'facilityManagerAssignments',
    headerName: 'Manager',
    type: 'customText',
    minWidth: 80,
    flex: !noFlex ? 1.5 : 0,
    valueGetter: (params) => params.row.facilityManagerAssignments?.map((manager) => manager.manager).join(', ') ?? '',

    renderCell: (params) => (
      <ManagerChips
        params={params}
        hasFacilityAccess={hasFacilityAccess}
        onDeleteSuccess={onDeleteSuccess}
        type={type}
      />
    ),
    ...props,
  };
}

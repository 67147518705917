import EditIcon from '@mui/icons-material/Edit';
import {
  Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { postPurchasingVendorForm } from '../../../../api/invoicing';
import { getVendors } from '../../../../redux/slices/vendors';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import FormTextWithValue from '../../../../reusable-components/form/FormTextWithValue';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';

ElectronicVendorModal.propTypes = {
  row: PropTypes.object,
  edit: PropTypes.bool,
};

export default function ElectronicVendorModal({ row = null, edit = false }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [edited, setEdited] = useState(false);

  const { vendors } = useSelector((state) => state.vendors.data);
  const vendorNames = useMemo(() => vendors.map((v) => v.name.toLowerCase().trim()), [vendors]);

  const [value, setValue] = useState({
    id: '',
    name: '',
    emailTo: '',
    emailToCC: '',
    address: '',
    zip: '',
    city: '',
    state: '',
    phone: '',
    logo: '',
    hasInvoices: true,
  });

  const vendorNullError = useMemo(() => value.name === '', [value]);
  const vendorNameError = useMemo(() => vendorNames.includes(value?.name?.toLowerCase().trim())
    && row?.name?.toLowerCase().trim() !== value?.name?.toLowerCase().trim() || vendorNullError
    , [value]);

  const handleSave = async () => {
    const response = await postPurchasingVendorForm(value);
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      enqueueSnackbar(`Successfully added ${response.data.name}`, {
        variant: 'success',
      });
      dispatch(getVendors());
      setOpen(false);
    } else
      enqueueSnackbar(`Error: Could not add vendor`, {
        variant: 'error',
      });
  };

  const handleClickOpen = () => {
    setValue({
      id: edit ? row.id : '',
      name: edit ? row.name : '',
      emailTo: edit ? row.emailTo : '',
      emailToCC: edit ? row.emailToCC : '',
      address: edit ? row.address : '',
      zip: edit ? row.zip : '',
      city: edit ? row.city : '',
      state: edit ? row.state : '',
      phone: edit ? row.phone : '',
      logo: edit ? row.logo : '',
      hasInvoices: edit ? row.hasInvoices : true,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setValue({
      id: '',
      name: '',
      emailTo: '',
      emailToCC: '',
      address: '',
      zip: '',
      city: '',
      state: '',
      phone: '',
      logo: '',
      hasInvoices: true,
    });
    setOpen(false);
  };

  function isObject(value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  useEffect(() => {
    if (open) {
      const onEdit = !Object.keys(value).every((key) => {
        let value1 = value?.[key];
        let value2 = edit ? row[key] : key === 'hasInvoices' ? true : '';
        if (isObject(value1)) value1 = value1.label;
        if (isObject(value2)) value2 = value2.label;
        console.log("values", { key, value1, value2 });
        if (!Number.isNaN(+value2)) return Number(value1) === Number(value2);
        return (value1 === null && value2 === '') || (value1 === '' && value2 === null) || value1 === value2;
      });
      setEdited(onEdit);
    }
  }, [value]);


  return (
    <>
      {
        edit ?
          (<IconButton
            variant="outlined"
            sx={{ mr: 1 }}
            size="small"
            color="secondary"
            onClick={handleClickOpen}
          >
            <EditIcon />
          </IconButton>)
          :

          (<Button
            variant="outlined"
            sx={{ mr: 1 }}
            size="small"
            color="secondary"
            onClick={handleClickOpen}
            startIcon={<Iconify icon="eva:plus-outline" />}
          >
            New Purchasing Vendor
          </Button>)
      }

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ textAlign: 'center' }}>
          {edit ? 'Edit Purchasing Vendor' : 'New Purchasing Vendor'}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
                <FormTextWithValue value={value} title="Name*" flex={12} name="name" setValue={setValue} />
                {vendorNameError && edited && (
                  <Alert severity="error" sx={{ width: '100%' }}>
                    {vendorNullError ? "Missing vendor name" : "Vendor already exists"}
                  </Alert>
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="Send PO to" flex={6} name="emailTo" setValue={setValue} />
                <FormTextWithValue value={value} title="Copy on PO" flex={6} name="emailToCC" setValue={setValue} />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="Address" flex={12} name="address" setValue={setValue} />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="City" flex={6} name="city" setValue={setValue} />
                <FormTextWithValue value={value} title="State" flex={6} name="state" setValue={setValue} />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="Zip" flex={3} name="zip" setValue={setValue} />

                <FormTextWithValue value={value} title="Phone Number" flex={9} name="phone" setValue={setValue} />
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={vendorNameError}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
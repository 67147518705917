import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { Box, Button, Grid } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { postSplitOrder } from '../../../../api/orders';
import { useTabs } from '../../../../context/TabContext';
import {
  addItemsToAnOrderInRedux,
  clearTempOrderList,
  getAddToOrderList,
  getPendingOrderInfo,
  getPendingOrders
} from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { CollapseSidebar } from '../../../../reusable-components/collapse-sidebar';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { ADD_TO_ORDER, PENDING_ORDER } from '../../../../reusable-components/datagrid/pendingOrderColumns';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { BudgetBox } from '../new-order';
import AddItemQuantityField from './AddItemQuantityField';
import CustomToolbar from './CustomToolbar';
import OrderInfo from './OrderInfo';
import OrderReviewers from './OrderReviewers';
import OrderTicket from './OrderTicket';
import VendorInfo from './VendorInfo';
import ConfirmOrderDialog from './ConfirmOrderModal';

export default function PendingOrderView() {
  const { id = '' } = useParams();
  const {
    orderById,
    addToOrderListById,
    tempOrderListById = [],
    cartCheckItemsById,
    data: { pendingOrders }
  } = useSelector((state) => state.orders);

  const { items = [], linkedTickets = [], facilityId, budgets = [], vendorDates } = orderById[id] || {};
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { addNewTabAndNavigate } = useTabs();

  const [data, setData] = useState([]);
  const [currentTicket, setCurrentTicket] = useState(0);
  const [allReqDates, setAllReqDates] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [addItemData, setAddItemData] = useState([]);
  const [addItemCategoryId, setAddItemCategoryId] = useState(null);
  const [openProcessDialog, setOpenProcessDialog] = useState(false);

  const [altAddress, setAltAddress] = useState({
    state: '',
    street: '',
    zip: '',
    city: '',
    phoneNumber: '',
  });

  useEffect(() => {
    const arrayOfIdsThatHaveErrors = cartCheckItemsById[id]
      ?.filter((item) => item?.errors?.length > 0)
      ?.map((item) => item.id);

    if (items) {
      if (arrayOfIdsThatHaveErrors?.length > 0) {
        // place error items at the top of the list
        const filteredItems = items
          ?.filter((item) => !item.temporary)
          .sort((a, b) => {
            const x = a.vendor?.toLowerCase();
            const y = b.vendor?.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
        const sortedItems = filteredItems.sort((a, b) => {
          if (arrayOfIdsThatHaveErrors.includes(a.id) && !arrayOfIdsThatHaveErrors.includes(b.id)) {
            return -1;
          }
          if (!arrayOfIdsThatHaveErrors.includes(a.id) && arrayOfIdsThatHaveErrors.includes(b.id)) {
            return 1;
          }
          return 0;
        });
        setData(sortedItems || []);
      } else {
        const filteredItems = items.filter((item) => !item.temporary);
        setData(filteredItems || []);
      }
    }
  }, [items, cartCheckItemsById]);

  const handleCancel = () => {
    setAddItemCategoryId(null);
    dispatch(clearTempOrderList(id));
  };

  const saveOrderFunction = () => {
    dispatch(
      addItemsToAnOrderInRedux({
        orderId: id,
        arrayOfItems: tempOrderListById[id].map((item) => ({
          ...item,
          approved: true,
          temporary: false,
          unsavedId: 0,
        })),
      })
    );
    setAddItemCategoryId(null);
    dispatch(clearTempOrderList(id));
  };

  useEffect(() => {
    setAllReqDates(vendorDates?.map(vendorDate => ({ vendorId: vendorDate.vendorId, date: new Date(vendorDate.requestedDate) })) ?? []);
  }, [vendorDates]);

  useEffect(() => {
    if (addItemCategoryId) {
      setAddItemData([]);
      dispatch(getAddToOrderList(id, facilityId, addItemCategoryId));
    }
  }, [addItemCategoryId, facilityId, id]);

  useEffect(() => {
    setAddItemData(addToOrderListById[id] || []);
  }, [addToOrderListById, id]);

  const [selected, setSelected] = useState([]);
  const [splitOrder, setSplitOrder] = useState(false);
  const [openTicket, setOpenTicket] = useState('close');
  useEffect(() => {
    setOpenTicket('close');
  }, [id]);
  const [addItemsToTicket, setAddItemsToTicket] = useState('');

  const departments = useMemo(() => {
    const seen = new Set();
    const result = [];
    const allItems = [...(items || []), ...(tempOrderListById[id] || [])];
    allItems.forEach((row) => {
      if (row.agoraCategory && row.agoraCategory.name && !seen.has(row.agoraCategory.name)) {
        seen.add(row.agoraCategory.name);
        result.push({ name: row.agoraCategory.name, id: row.agoraCategory.id });
      }
    });

    return result;
  }, [items, id]);

  const selectedRows = useMemo(() => data?.filter((row) => selected.includes(row.id)), [data, selected]);

  const isLoading = useSelector((state) => state.orders.isLoading);
  const itemsIsLoading = useSelector((state) => state.orderSheet.isLoading);

  const CustomLeftToolbar = useCallback(
    () => (
      <CustomToolbar
        data={data}
        setSelected={setSelected}
        setSplitOrder={setSplitOrder}
        addItemCategoryId={addItemCategoryId}
        setAddItemCategoryId={setAddItemCategoryId}
        setOpenProcessDialog={setOpenProcessDialog}
        allReqDates={allReqDates}
      />
    ),
    [addItemCategoryId, data, allReqDates]
  );
  const fetchData = async () => {
    await dispatch(getPendingOrders()); 
  };

  useEffect(() => {
    if ( pendingOrders === undefined || pendingOrders?.length === 0 ) {
      fetchData();
    } 
  }, [])
  
  const splitOrderFunction = async () => {
    const response = await postSplitOrder({ orderId: id, selectedRows, data });
    if (response.status === 200) {
      dispatch(getPendingOrderInfo(id));
      setSelected([]);
      setSplitOrder(false);
      enqueueSnackbar(`Order #${id} split ${selectedRows?.length} items to `, {
        variant: 'success',
        autoHideDuration: 4000,
        action: (key) => (
          <>
            <Button
              onClick={() => {
                addNewTabAndNavigate(`/dashboard/orders/${response.data[1]?.id}`);
              }}
              color="secondary"
              endIcon={<Iconify icon="material-symbols:open-in-new" />}
            >
              #{response.data[1]?.id}
            </Button>

            <Button
              onClick={() => {
                closeSnackbar(key);
              }}
              sx={{
                color: '#fff',
              }}
            >
              X
            </Button>
          </>
        ),
      });
    } else {
      enqueueSnackbar('Error splitting order', { variant: 'error', persist: true });
    }
  };

  const quantity = {
    field: 'quantity',
    headerName: 'Qty',
    width: 100,
    renderCell: (params) => (
      <AddItemQuantityField row={params.row} isIdFound={data?.some((item) => item.itemId === params.row.id)} />
    ),
  };

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={openSidebar ? 9.3 : 11.2}>
          <Grid container>
            {splitOrder && (
              <Grid item xs={2}>
                <Box
                  sx={{
                    ml: 2,
                    p: 1.5,
                    mt: 7,
                  }}
                >
                  <AwaitButton
                    variant="outlined"
                    color="primary"
                    fullWidth
                    sx={{ mb: 1, width: '180px', boxShadow: 3, fontWeight: 'bold' }}
                    startIcon={<MergeTypeIcon />}
                    onClick={splitOrderFunction}
                  >
                    Split from {id}
                  </AwaitButton>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    {selectedRows.map((row, index) => (
                      <Box key={index}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: 'solid 2px #b128bc',
                            width: '180px',
                            height: 'auto',
                            mr: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: 'auto',
                              backgroundColor: 'secondary.main',
                              display: 'flex',
                              height: '100%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: '#fff',
                              pr: '2px',
                            }}
                          >
                            {index + 1}
                          </Box>
                          <Box sx={{ ml: 1, color: 'secondary.main', fontSize: '0.8rem' }}>{row.description}</Box>
                        </Box>
                        {index < selectedRows?.length - 1 && (
                          <Box
                            sx={{
                              width: '2px',
                              height: '20px',
                              backgroundColor: '#b128bc',
                              alignSelf: 'center',
                              ml: 1,
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>

                  <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Grid item xs={6}>
                      <Button
                        variant="text"
                        color="inherit"
                        sx={{
                          mb: 1,
                          width: '100%',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          setSelected([]);
                          setSplitOrder(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
            <Grid item xs={splitOrder > 0 ? 10 : 12}>
              <Box>
                {addItemCategoryId && (
                  <>
                    <CustomDataGrid
                      gridId="orders-pending-add-to-order"
                      isModal
                      scrollbarHeight={58}
                      boxSX={{ height: '38vh' }}
                      data={addItemData}
                      gridColumns={[...ADD_TO_ORDER, quantity]}
                      getRowClassName={(params) => {
                        const isIdFound = data?.some((item) => item.itemId === params.row.id);

                        if (isIdFound) {
                          return 'disabled';
                        }
                        return '';
                      }}
                      dontGetRowHeight
                      pinnedColumns={{
                        left: ['picture'],
                        right: ['vendor', 'total', 'quantity'],
                      }}
                      isLoading={data?.length === 0 || itemsIsLoading}
                      CustomLeftToolbar={CustomLeftToolbar}
                    />
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ my: 1 }}>
                      <Grid item>
                        <Button sx={{ mr: 1 }} size="small" variant="outlined" color="error" onClick={handleCancel}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <AwaitButton
                          color="primary"
                          size="small"
                          variant="contained"
                          sx={{ mr: 1 }}
                          onClick={saveOrderFunction}
                          disabled={tempOrderListById[id]?.length === 0}
                        >
                          Add to order
                        </AwaitButton>
                      </Grid>
                    </Grid>
                  </>
                )}

                <CustomDataGrid
                  gridId="orders-pending-order-view"
                  scrollbarHeight={addItemCategoryId ? 58 : 100}
                  boxSX={{ height: addItemCategoryId ? '38vh' : 'calc(100vh - 270px)' }}
                  data={data}
                  gridColumns={[...PENDING_ORDER]}
                  getRowClassName={(params) => {
                    if (params.row.deleted === true) {
                      return 'disabled';
                    }
                    return '';
                  }}
                  dontGetRowHeight
                  onRowSelectionModelChange={(newSelection) => {
                    setSelected(newSelection);
                  }}
                  pinnedColumns={{
                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'picture'],
                    right: ['vendor', 'total', 'quantity', 'approved'],
                  }}
                  checkboxSelection={splitOrder || !!addItemsToTicket}
                  isLoading={data?.length === 0 || isLoading}
                  hideToolbar={!!addItemCategoryId}
                  CustomLeftToolbar={CustomLeftToolbar}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={openSidebar ? 2.7 : 0.8} style={{ alignSelf: 'stretch', position: 'relative' }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <CollapseSidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}>
              {openSidebar ? (
                <Scrollbar sx={{ height: 'calc(100vh - 170px)' }}>
                  <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ pb: '50px' }}>
                    <OrderReviewers pendingOrders={pendingOrders} />
                    <OrderInfo altAddress={altAddress} setAltAddress={setAltAddress} />
                    <VendorInfo allReqDates={allReqDates} setAllReqDates={setAllReqDates} />
                    <ScopeGuard scopes={['View-Order']} allowAdmin>
                      {departments?.map((department, index) => (
                        <Grid item key={index} sx={{ mt: 2 }}>
                          <BudgetBox
                            changeBudget
                            budget={budgets?.find((budget) => budget.category === department.name)}
                            cart={[...(items || []), ...(tempOrderListById[id] || [])].filter(
                              (item) =>
                                item.quantity > 0 &&
                                item.approved === true &&
                                item.deleted !== true &&
                                item.agoraCategory?.name === department.name
                            )}
                            catId={department?.id || null}
                            facId={facilityId || null}
                          />
                        </Grid>
                      ))}
                    </ScopeGuard>
                  </Grid>
                </Scrollbar>
              ) : (
                <>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    sx={{ height: 'calc(100vh - 180px)' }}
                  >
                    <Grid item>
                      {linkedTickets?.length > 0 &&
                        linkedTickets.map((ticket) => (
                          <Button
                            key={ticket}
                            onClick={() => {
                              setCurrentTicket(ticket);
                              setOpenSidebar(true);
                              setOpenTicket('open');
                            }}
                            size="small"
                            sx={{ mb: 1 }}
                            startIcon={
                              <Iconify icon="icomoon-free:bubble" width={20} height={20} sx={{ color: '#fff' }} />
                            }
                            variant="contained"
                          >
                            {ticket}
                          </Button>
                        ))}
                      <Button
                        onClick={() => {
                          setCurrentTicket(0);
                          setOpenSidebar(true);
                          setOpenTicket('open');
                        }}
                        size="small"
                        sx={{ mb: 1 }}
                        startIcon={<Iconify icon="fa6-solid:plus" width={20} height={20} sx={{ color: '#fff' }} />}
                        variant="contained"
                      >
                        Ticket
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              {openSidebar && (
                <OrderTicket
                  currentTicket={currentTicket}
                  setCurrentTicket={setCurrentTicket}
                  openTicket={openTicket}
                  setOpenTicket={setOpenTicket}
                  addItemsToTicket={addItemsToTicket}
                  setAddItemsToTicket={setAddItemsToTicket}
                  selectedRows={splitOrder ? [] : selectedRows}
                  setSelected={setSelected}
                />
              )}
            </CollapseSidebar>
          </Grid>
        </Grid>
        <ConfirmOrderDialog
          openConfirm={openProcessDialog}
          setOpenConfirm={setOpenProcessDialog}
          id={id}
          allReqDates={allReqDates}
          altAddress={ altAddress}
        />
      </Grid>
    </>
  );
}

import { useState, useEffect, useMemo, useCallback } from 'react';
import { DialogContent, Grid, Button, CircularProgress, Box, Tooltip, Typography, Alert, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CollapseSidebar } from '../../../reusable-components/collapse-sidebar';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import Iconify from '../../../reusable-components/iconify';
import ReportsSideBar from './ReportSideBar';
import ReportSelectTypeStep from './ReportSelectTypeStep';
import ReportSelectSpecificationsStep from './ReportSelectSpecificationsStep';
import {
  getOrDownloadItemsOrderedReport, getOrDownloadFacilitiesInvoiceReport, getOrDownloadFacilityComparisonReport, getOrDownloadFacilityHistoryReport,
  getOrDownloadInvoiceIssuesReport, getOrDownloadPoInvoiceMatchupReports, getOrDownloadSubstituteReport, getOrDownloadTopLevelBudgetReport,
  getOrDownloadVendorComparisonReport, getOrDownloadCheckInvoicePDFsReport, getReportOptions, getReports
} from '../../../api/reports';
import {
  PURCHASING_ITEMS_ORDERED, FACILITY_COMPARISON_REPORT, VENDOR_COMPARISON_REPORT, TOP_LEVEL_BUDGET_REPORT, FACILITY_HISTORY_REPORT, PO_INVOICE_REPORT,
  FACILITIES_INVOICES_REPORT, INVOICES_ISSUES_REPORT, FACILITIES_INVOICES_DISCREPANCY, SUBSTITUTE_REPORT, ITEMS_ORDERED_FULL
} from '../../../reusable-components/datagrid/reportsColumns';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { fMonthDayYear } from '../../../utils/formatTime';
import { fCurrency } from '../../../utils/formatNumber';
import { DateRangePickerSelect } from '../../../reusable-components/date-range-picker';

export default function ReportsView() {
  const { enqueueSnackbar } = useSnackbar();

  const [loadingMainData, setLoadingMainData] = useState(true)
  const [loadingReportSpecificationOptions, setLoadingReportSpecificationOptions] = useState(false)
  const [loadingReportResults, setLoadingReportResults] = useState(false)
  const [downloadingReportResults, setDownloadingReportResults] = useState(false)
  const [activeStep, setActiveStep] = useState(0);
  const [reportColumns, setReportColumns] = useState({});
  const [openSidebar, setOpenSidebar] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selectedReportId, setSelectedReportId] = useState(0);
  const [createReportData, setCreateReportData] = useState({});
  const [reportResults, setReportResults] = useState([]);
  const [reportSpecificationOptions, setReportSpecificationOptions] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState(null);
  const [toMuchData, setToMuchData] = useState(false);

  useEffect(() => {
    getReports()
      .then(res => {
        res.data.reports = res.data.reports.sort((a, b) => a.name.localeCompare(b.name))
          .map(report => ({ ...report, value: report.id, label: report.name }));
        res.data.reportTypes = res.data.reportTypes.sort((a, b) => a.name.localeCompare(b.name))
          .map(type => ({ ...type, value: type.id, label: type.name }));
        res.data.facilities = res.data.facilities.map(facility => ({ ...facility, value: facility.id, label: facility.name }));
        res.data.subcategories = res.data.subcategories.map(subcategory => ({ ...subcategory, value: subcategory.id, label: subcategory.name }));
        res.data.categories = res.data.categories.map(category => ({ ...category, value: category.id, label: category.name }));
        setCreateReportData(res.data);
      })
      .catch(() => enqueueSnackbar(`Failed to fetch report types`, { variant: 'error' }))
      .finally(() => setLoadingMainData(false));
  }, []);

  const startStepReportSpecificationOptions = () => {
    setLoadingReportSpecificationOptions(true);
    getReportOptions(selectedReportId)
      .then(res => {
        setReportSpecificationOptions(res.data ?? []);
        const presetDate = res.data.some(o => o.name === 'DateFilter' && !o.multiselect);
        setFormValues({
          selectedReportType: formValues.selectedReportType,
          selectedReport: formValues.selectedReport,
          startDate: presetDate ? new Date(new Date().setDate(new Date().getDate() - 29)) : null,
          endDate: presetDate ? new Date() : null
        });
      })
      .catch(() => enqueueSnackbar(`Failed to fetch report specifications`, { variant: 'error' }))
      .finally(() => { setLoadingReportSpecificationOptions(false); setActiveStep(1); });
  }

  const getReportResults = (download) => {
    if (selectedReportId === 0) return;
    if (download) setDownloadingReportResults(true)
    else setLoadingReportResults(true);
    setToMuchData(false);
    (selectedReportId === 1 ? getOrDownloadItemsOrderedReport(formValues, false, download)
      : selectedReportId === 2 ? getOrDownloadFacilityComparisonReport(formValues, download)
        : selectedReportId === 3 ? getOrDownloadVendorComparisonReport(formValues, download)
          : selectedReportId === 4 ? getOrDownloadTopLevelBudgetReport(formValues, download)
            : selectedReportId === 5 ? getOrDownloadFacilityHistoryReport(formValues, download)
              : selectedReportId === 6 ? getOrDownloadPoInvoiceMatchupReports(formValues, download)
                : selectedReportId === 7 ? getOrDownloadSubstituteReport(formValues, download)
                  : selectedReportId === 8 ? getOrDownloadFacilitiesInvoiceReport(formValues, download)
                    : selectedReportId === 9 ? getOrDownloadCheckInvoicePDFsReport(formValues, download)
                      : selectedReportId === 10 ? getOrDownloadInvoiceIssuesReport(formValues, download)
                        : selectedReportId === 11 ? getOrDownloadItemsOrderedReport(formValues, true, download)
                          : getOrDownloadItemsOrderedReport(formValues, false, download))
      .then(res => {
        if (!download) setReportResults(res.data);
        else if (!res.data?.size) enqueueSnackbar(`Report has no data`, { variant: 'error' });
        else
        {        
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${formValues.selectedReport?.name ?? "Report"}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => enqueueSnackbar(`Failed to fetch report`, { variant: 'error' }))
      .finally(() => {
        if (download) {
          setDownloadingReportResults(false);
          return;
        }
        setLoadingReportResults(false);
        setActiveStep(2);
        setReportColumns(
          selectedReportId === 1 ? { columns: PURCHASING_ITEMS_ORDERED }
            : selectedReportId === 2 ? {
              columns: FACILITY_COMPARISON_REPORT,
              dynamicColumns: 'facilities',
              dynamicKey: ['facility'],
              dynamicFields: [
                {
                  field: 'ppdPrice',
                  headerName: 'PPD',
                  isCurrency: true,
                },
                {
                  field: 'quantity',
                  headerName: 'QTY',
                  isNumber: true,
                },
                {
                  field: 'total',
                  headerName: 'Total',
                  isCurrency: true,
                  tooltip: 'Price * Quantity',
                },
              ],
            }
              : selectedReportId === 3 ? {
                columns: VENDOR_COMPARISON_REPORT,
                nestedDataArray: true,
                dynamicColumns: 'vendors',
                dynamicKey: ['vendor'],
                dynamicFields: [
                  {
                    field: 'manufacturer',
                    headerName: 'Manufacturer',
                  },
                  {
                    field: 'uom',
                    headerName: 'UOM',
                  },
                  {
                    field: 'baseUom',
                    headerName: 'Base UOM',
                    combineTwoFields: ['baseUomAmount', 'baseUomType'],
                  },
                  {
                    field: 'perItem',
                    headerName: 'Per Item',
                    isCurrency: true,
                  },
                  {
                    field: 'perUnit',
                    headerName: 'Per Unit',
                    isCurrency: true,
                  },
                  {
                    field: 'price',
                    headerName: 'Price',
                    isCurrency: true,
                  },
                  {
                    field: 'vendorItemId',
                    headerName: 'SKU',
                  },
                ],
              }
                : selectedReportId === 4 ? { columns: TOP_LEVEL_BUDGET_REPORT }
                  : selectedReportId === 5 ? {
                    columns: FACILITY_HISTORY_REPORT,
                    nestedData: 'items',
                    nestedDataKey: 'item',
                    dynamicColumns: 'orderedItems',
                    topLevelColumns: ['censusDateValues'],
                    topLevelColumnsKey: 'date',
                    dynamicKey: ['date'],
                    dynamicFields: [
                      {
                        field: 'total',
                        headerName: 'Total',
                        isCurrency: true,
                      },
                      {
                        field: 'quantity',
                        headerName: 'QTY',
                        isNumber: true,
                      },
                      {
                        field: 'ppdTotal',
                        headerName: 'PPD Total',
                        isCurrency: true,
                      },
                    ],
                  }
                    : selectedReportId === 6 ? { columns: PO_INVOICE_REPORT }
                      : selectedReportId === 7 ? { columns: SUBSTITUTE_REPORT }
                        : selectedReportId === 8 ? { columns: FACILITIES_INVOICES_REPORT }
                          : selectedReportId === 9 ? { columns: INVOICES_ISSUES_REPORT }
                            : selectedReportId === 10 ? {
                              columns: FACILITIES_INVOICES_DISCREPANCY,
                              getRowId: (row) => `${row.id}_${row.account}_${row.facility}_${row.item}_${row.vendor}_${row.vendorInvoiceId}_${row.sent}_${row.sent}_${row.invoiceDate}_${row.issue}`
                            }
                              : selectedReportId === 11 ? { columns: ITEMS_ORDERED_FULL }
                                : { columns: PURCHASING_ITEMS_ORDERED });
      });  
  };

  useEffect(() => {
    if (reportColumns?.dynamicColumns && reportResults) {
      const allDynamicColumnsNames = [...new Set(reportColumns.topLevelColumns ? reportColumns.topLevelColumns.map((col) => fMonthDayYear(col?.[reportColumns?.topLevelColumnsKey])) :
        Array.isArray(reportResults) ? reportResults.filter(item => item[reportColumns?.dynamicColumns]).flatMap(item => item[reportColumns?.dynamicColumns]
          .map((subItem) => subItem?.[reportColumns?.dynamicKey])) : [])];
      setToMuchData(allDynamicColumnsNames.length > 10 && reportResults.length > 500 || allDynamicColumnsNames.length > 20 && reportResults.length > 20 ||
        reportResults.length > 20000);
      if (allDynamicColumnsNames.length > 10 && reportResults.length > 500 || allDynamicColumnsNames.length > 20 && reportResults.length > 20)
        setDynamicColumns(allDynamicColumnsNames.slice(0, 10));
      else setDynamicColumns(allDynamicColumnsNames);
      if (reportResults.length > 20000 || allDynamicColumnsNames.length > 10 && reportResults.length > 500)
        setReportResults(allDynamicColumnsNames.length > 10 && reportResults.length > 500 ? reportResults.slice(0, 500) : reportResults.slice(0, 20000));     
    } else if (reportResults && reportResults.length > 20000) {
      setToMuchData(true);
      setReportResults(reportResults.slice(0, 20000));
    }
  }, [formValues.selectedReport, reportResults, reportColumns]);

  const allColumns = useMemo(() => {
    if (!reportColumns?.dynamicColumns || !dynamicColumns) return reportColumns?.columns || [];
    return [
      ...reportColumns?.columns,
      ...dynamicColumns.map((col) => ({
        field: col,
        headerName: col,
        width: selectedReportId === 3 ? 500 : 250,
        alignItems: 'center',
        renderCell: (params) => {
          const nameLookup = reportColumns?.topLevelColumnsKey === 'date'
            ? params?.row[reportColumns?.dynamicColumns]?.find((item) => fMonthDayYear(item[reportColumns?.dynamicKey]) === col)
            : params?.row[reportColumns?.dynamicColumns]?.filter((item) => item[reportColumns?.dynamicKey] === col);

          return <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            {reportColumns?.dynamicFields && reportColumns?.dynamicFields.map((fieldObj) => {
              const { combineTwoFields, isCurrency, headerName, field, isNumber, tooltip } = fieldObj;
              return (
                <Tooltip key={field} title={tooltip} arrow placement="top">
                  <Grid item key={field}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                      <Grid item>
                        <Box component="div" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', fontSize: '0.75rem' }}>
                          {headerName}
                        </Box>
                      </Grid>
                      {nameLookup?.length > 0 ? nameLookup?.map((item) => <Grid item key={item.id}>
                        <Box component="div" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', fontSize: '0.75rem' }}>
                          {combineTwoFields
                            ? `${item?.[combineTwoFields[0]] || 0} / ${item?.[combineTwoFields[1]] || 0}`
                            : isCurrency
                              ? fCurrency(item?.[field])
                              : isNumber
                                ? item?.[field] || 0
                                : item?.[field] || ''}
                        </Box>
                      </Grid>) : (
                        <Box component="div" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', fontSize: '0.75rem' }}>
                          {isCurrency
                            ? fCurrency(nameLookup?.[field])
                            : isNumber
                              ? nameLookup?.[field] || 0
                              : nameLookup?.[field] || ''}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Tooltip>
              );
            })}
          </Grid>;
        },
        valueGetter: (params) => {
          const nameLookup = reportColumns?.topLevelColumnsKey === 'date'
            ? params?.row[reportColumns?.dynamicColumns]?.filter((item) => fMonthDayYear(item[reportColumns?.dynamicKey]) === col)
            : params?.row[reportColumns?.dynamicColumns]?.filter((item) => item[reportColumns?.dynamicKey] === col);
          return nameLookup?.map((item) => item?.[reportColumns?.dynamicKey]) || '';
        },
      })),
    ];
  }, [selectedReportId, dynamicColumns, reportColumns?.columns]);

  const CustomLeftToolbar = useCallback(() => <Grid container justifyContent="flex-start" alignItems="center">
    <Grid item>
      <Button variant="text"
        onClick={() => setActiveStep(1)}
        startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
        sx={{ color: '#454F5B', fontWeight: 'bold', }}
      >
        Modify Report
      </Button>
    </Grid>
    <Grid container item display="flex" gap={1}>
      <Grid item alignSelf="center">
        <Typography variant="subtitle1" color="secondary">
          {formValues?.selectedReport?.name}
        </Typography>
      </Grid>
      <Grid item alignSelf="end">
        <LoadingButton
          variant={"outlined"}
          loading={downloadingReportResults}
          size="small"
          onClick={() => getReportResults(true)}
          startIcon={<Iconify icon="mdi:tray-arrow-down" />}
          endIcon={<Iconify icon="vscode-icons:file-type-excel" />}
        >
          Download
        </LoadingButton>
      </Grid>
      <Grid item>
        {formValues.startDate && formValues.endDate && <DateRangePickerSelect noStickyFilter value={[formValues.startDate, formValues.endDate]} 
          setValue={(start, end) => { setFormValues({ ...formValues, startDate: start, endDate: end }); getReportResults(false); }} />}
      </Grid>
    </Grid>
    {toMuchData && <Grid item sx={{ mt: 1 }}><Alert severity="error">This report has to much data to display correctly. Please download the report to see all data.</Alert></Grid>}
  </Grid>, [formValues, downloadingReportResults, toMuchData]);

  return <Box sx={{ width: "100%", justifyContent: "center", display: "flex", alignItems: "center", minHeight: "calc(100vh - 250px)" }} >
    {activeStep !== 2 && <Card sx={{ maxWidth: showDatePicker ? "950px" : "600px", width: "100%", '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }} >
      <Typography component="div" sx={{ display: 'flex', color: '#8cc53f', mb: -3, mt: 2 }}>
        {activeStep !== 0 && <Button title="Back" onClick={() => setActiveStep(step => step - 1)} style={{ color: 'gray', maxWidth: '20' }}>
          <Iconify icon="ic:baseline-arrow-back" width={20} height={20} />
        </Button>}
        <Box sx={{ flex: 1, textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginRight: activeStep !== 0 ? '60px' : 0 }}>
          {activeStep === 1 ? formValues.selectedReport?.name : 'New Report'}
        </Box>
      </Typography>

      <DialogContent>
        {(!loadingMainData && !loadingReportSpecificationOptions) ? <>
          {activeStep === 0 && <ReportSelectTypeStep
            reportsList={createReportData?.reports ?? []}
            reportTypesList={createReportData?.reportTypes ?? []}
            formValues={formValues}
            setFormValues={setFormValues}
            setSelectedReportId={setSelectedReportId}
          />}
          {activeStep === 1 && <ReportSelectSpecificationsStep
            formValues={formValues}
            setFormValues={setFormValues}
            createReportData={createReportData}
            reportSpecificationOptions={reportSpecificationOptions}
            setShowDatePicker={setShowDatePicker}
            showDatePicker={showDatePicker}
          />}

          <Grid container direction="row" justifyContent="center" alignItems="center">
            {activeStep === 0 && <Button
              title="Next"
              variant="contained"
              disabled={!formValues.selectedReport}
              onClick={startStepReportSpecificationOptions}
              sx={{ display: 'flex', justifyContent: 'center', width: '50%', mt: 2 }}
            >
              Next
            </Button>}
            {activeStep === 1 && <LoadingButton
              title="Generate Report"
              variant="contained"
              loading={loadingReportResults}
              onClick={() => getReportResults(false)}
              endIcon={<Iconify icon={'tabler:send'} />}
              sx={{ display: 'flex', justifyContent: 'center', width: '60%', mt: 2 }}
            >
              Generate
            </LoadingButton>}
          </Grid>
        </> : <Box justifyContent="center" display="flex"> <CircularProgress sx={{ alignSelf: "center", margin: "50px" }} /></Box>}
      </DialogContent>
    </Card>}

    {activeStep === 2 && <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={openSidebar ? 9.5 : 11}>
        <CustomDataGrid
          gridId="reports"
          boxSX={{ height: 'calc(100vh - 310px)', width: '100%' }}
          data={Array.isArray(reportResults) ? reportResults : Array.isArray(reportResults?.items) ? reportResults.items : []}
          gridColumns={allColumns || []}
          CustomLeftToolbar={CustomLeftToolbar}
          getRowId={(row) => reportColumns?.getRowId
            ? reportColumns.getRowId(row)
            : reportColumns?.nestedDataKey
              ? row?.[reportColumns?.nestedDataKey]?.id
              : row.id
          }
          pinnedColumns={reportColumns?.pinnedColumns || {}}
          isLoading={loadingReportResults}
        />
      </Grid>
      <Grid item xs={openSidebar ? 2.5 : 1} style={{ alignSelf: 'stretch', position: 'relative' }}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <CollapseSidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}>
            {openSidebar ? <ReportsSideBar formValues={formValues} /> : <></>}
          </CollapseSidebar>
        </Grid>
      </Grid>
    </Grid>}
  </Box>;
}

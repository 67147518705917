import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCategoryData } from '../../../../redux/slices/rentals';
import { useSelector, useDispatch } from '../../../../redux/store';

export default function RentalCategoryDropdown() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [category, setCategory] = useState(searchParams.get('category'));
  const [facilityId, setFacilityId] = useState(searchParams.get('facilityId'));
  const [residentId, setResidentId] = useState(searchParams.get('residentId'));
  const {
    data: { categoryData },
  } = useSelector((state) => state.rentals);
  const dispatch = useDispatch();
  const handleCategoryClick = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    navigate(
      `/dashboard/rentals/new-rental?facilityId=${facilityId}&category=${selectedCategory}&residentId=${residentId}`
    );
  };
  const uniqueCategories = categoryData.reduce((acc, category) => {
    if (!acc.find((item) => item.categoryName === category.categoryName)) {
      acc.push(category);
    }
    return acc;
  }, []);

  useEffect(() => {
    dispatch(getCategoryData());
  }, [dispatch]);

  useEffect(() => {
    setCategory(searchParams.get('category'));
    setFacilityId(searchParams.get('facilityId'));
    setResidentId(searchParams.get('residentId'));
  }, []);
  return (
    <>
      <Select
        label="Select Category"
        value={category || ''}
        onChange={handleCategoryClick}
        sx={{
          color: '#8cc53f',
          fontWeight: '15px',
          '& .MuiSelect-icon': {
            color: '#8cc53f',
          },
        }}
      >
        <MenuItem value="" disabled>
          {!categoryData?.length ? 'Loading categories...' : ''}
        </MenuItem>
        {uniqueCategories.map((category) => (
          <MenuItem key={category.categoryName} value={category.categoryName}>
            {category.categoryName}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Autocomplete, Typography, Box, Button, TextField, DialogActions, Checkbox, Stack } from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { copyBudget } from '../../../../redux/slices/budget';
import { useDispatch } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';

CopyBudget.propTypes = {
  facilities: PropTypes.array,
  agoraCategories: PropTypes.array,
};

export default function CopyBudget({ facilities, agoraCategories }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({});

  const handleSave = async () => {
    const response = await dispatch(copyBudget(value.facilities, value.facilityCopy.id, value.facilityCopyGL?.id, value.agoraCategories));
    if (response) {
      enqueueSnackbar('Copied successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Failed to copy', { variant: 'error' });
    }
    setOpen(false);
  };

  const clearData = () => {
    setValue({
      facilities: [],
      facilityCopy: '',
      facilityCopyGL: '',
      agoraCategories: [],
    });
  }

  const handleClickOpen = () => {
    clearData();
    setOpen(true);
  };

  const handleClose = () => {
    clearData();
    setOpen(false);
  };

  const facilityOtps = facilities?.map((type) => ({
    id: type.id,
    label: type.facilityName,
    facilityBookingTypeId: type.facilityBookingTypeId,
  }));

  const agoraCategoriesOpts = agoraCategories?.map((type) => ({ id: type.id, label: type.name })) ?? [];

  const defaultAutocompleteOpts = {
    fullWidth: true,
    size: "small",
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
    renderInput: (params) => <TextField {...params} label="Search or select" />,
    sx: {
      mt: 1,
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 },
      '& .MuiFormLabel-root ': { display: 'none' },
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ mr: 1 }}
        size="small"
        color="secondary"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        Copy Budget
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth size="small">
        <DialogTitle sx={{ textAlign: 'center' }}>
          Copy Budget
          <CloseIconButton onClick={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <Stack sx={{ p: 1 }} spacing={2}>
            <Box>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Facilities
                </Typography>

                <Autocomplete
                  {...defaultAutocompleteOpts}
                  multiple
                  id="checkboxes-tags-demo"
                  options={[
                    ...facilityOtps,
                  ]}
                  limitTags={3}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.label}
                  value={value.facilities || []}
                  onChange={(event, newValue) => {
                    if (newValue.some((option) => option.id === 'selectAll')) {
                      setValue({ ...value, facilities: value.facilities?.length === facilityOtps.length ? [] : facilityOtps });
                      return;
                    }
                    const newValueIds = newValue.map(val => val.id);
                    setValue({
                      ...value,
                      facilities: facilityOtps.filter(cat => newValueIds.includes(cat.id)) ?? [],
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={value.facilities != null && value.facilities.findIndex((x) => x.id === option.id) > -1}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Checkboxes" placeholder="Select" />}
                />

            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Facility to copy budgets from
              </Typography>
              <Autocomplete
                {...defaultAutocompleteOpts}
                value={value.facilityCopy || null}
                onChange={(event, newValue) => setValue({ ...value, facilityCopy: newValue })}
                options={facilityOtps}
              />
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Facility to copy GL codes from
              </Typography>
              <Autocomplete
                {...defaultAutocompleteOpts}
                value={value.facilityCopyGL || null}
                onChange={(event, newValue) => setValue({ ...value, facilityCopyGL: newValue})}
                options={facilityOtps}
              />
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                PS Categories
              </Typography>
              
              <Autocomplete
                {...defaultAutocompleteOpts}
                multiple
                id="checkboxes-tags-demo"
                options={[
                  { id: 'selectAll', label: 'Select All' },
                  ...agoraCategoriesOpts,
                ]}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.label}
                value={value.agoraCategories || []}
                onChange={(event, newValue) => {
                  if (newValue.some((option) => option.id === 'selectAll')) {
                    setValue({ ...value, agoraCategories: value.agoraCategories?.length === agoraCategoriesOpts.length ? [] : agoraCategoriesOpts });
                    return;
                  }
                  const newValueIds = newValue.map(val => val.id);
                  setValue({
                    ...value,
                    agoraCategories: agoraCategoriesOpts.filter(cat => newValueIds.includes(cat.id)) ?? [],
                  });
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={value.agoraCategories != null && value.agoraCategories.findIndex((x) => x.id === option.id) > -1}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Checkboxes" placeholder="Select" />}
              />

            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.facility === ''}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

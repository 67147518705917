import React, { useState } from 'react';
import { ButtonGroup, MenuItem, Menu, Button, Paper, Grow, Popper, MenuList, Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const options = [' Submit Invoice', ' Submit for review'];

const SubmitSwitcher = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box>
        <ButtonGroup variant="outlined" ref={anchorRef} aria-label="Button group with a nested menu">
          {React.cloneElement(children[selectedIndex], {
            onClick: children[selectedIndex].props.onClick, // Preserve the onClick handler
          })}
         { <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            color="secondary"
            sx={{ boxShadow: '0 8px 16px 0 rgba(177, 40, 188, 0.24)' }}
          >
            <ArrowDropDownIcon />
          </Button>}
        </ButtonGroup>
        <Popper sx={{ zIndex: 999 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={
                {
                  // transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
                }
              }
            >
              <Paper zIndex={1}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id="split-button-menu"
                    sx={
                      {
                        // backgroundColor: (theme) => theme.palette.secondary.light,
                        // color: (theme) => theme.palette.secondary.contrastText,
                        // '& .MuiMenuItem-root.Mui-selected': {
                        //   backgroundColor: (theme) => theme.palette.secondary.light, // Secondary color from theme
                        //   color: (theme) => theme.palette.secondary.contrastText, // Text contrast color
                        //   opacity: 0.75,
                        // },
                        // '& .MuiMenuItem-root.Mui-selected:hover': {
                        //   backgroundColor: (theme) => theme.palette.secondary.dark, // Darker shade on hover
                        // },
                      }
                    }
                    autoFocusItem
                  >
                    <MenuItem
                      color="secondary"
                      onClick={(event) => handleMenuItemClick(event, selectedIndex === 0 ? 1 : 0)}
                    >
                      {options[selectedIndex === 0 ? 1 : 0]}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </>
  );
};

export default SubmitSwitcher;

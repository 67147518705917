import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Tooltip, Grid, TextField, Button, Stack, MenuItem, Menu } from '@mui/material';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { patchInvoiceItems, getManualInvoicing } from '../../../../../redux/slices/invoicing';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import Iconify from '../../../../../reusable-components/iconify';
import { bookInvoice2 } from '../../../../../api/invoicing';
import { useScopeCheck } from '../../../../../reusable-components/scopes';
import { NotificationDialog } from '../../../../../reusable-components/dialog';
import ApproveInvoice from '../ApproveInvoice';
import SubmitForReview from './SubmitForReview';
import SubmitApprovers from './SubmitApprovers';
import SubmitSwitcher from './SubmitSwitcher';
import SubmitInvoice from './SubmitInvoice';

Submit.propTypes = {
  glRows: PropTypes.array,
  setEnableMarkAsSent: PropTypes.func,
  unsavedData: PropTypes.object,
  setUnsavedData: PropTypes.func,
  selectedApprovers: PropTypes.any,
  editingApprovers: PropTypes.bool,
};
export default function Submit({
  glRows,
  setEnableMarkAsSent,
  unsavedData,
  setUnsavedData,
  selectedApprovers,
  editingApprovers,
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const section = location.pathname.split('/')[3];
  const {
    data: { invoiceApprovers, invoiceItemData },
  } = useSelector((state) => state.invoicing);

  const { invoice } = invoiceItemData || {};
  const { user } = useSelector((state) => state.user);

  const hasManualInvoiceAccess = useScopeCheck(['Manual-Invoice'], true);
  const hasEditManualAccess = useScopeCheck(['ManualInvoice-Edit'], true);
  const canUpdateApprovers = hasManualInvoiceAccess && hasEditManualAccess;

  const hasManualSubmitAccess = useScopeCheck(['ManualInvoice-Submit'], false, true);
  const hasManualEditAccess = useScopeCheck(['ManualInvoice-Edit'], false, true);
  const [approvalRequired, setApprovalRequired] = useState(false);
  const requiredFieldsFilled =
    invoice?.facilityId > 0 &&
    invoice?.vendorId > 0 &&
    invoice?.vendorInvoiceId !== null &&
    invoice?.glPostingDate &&
    glRows?.length > 0;
  useEffect(() => {
    const approver = invoiceApprovers?.find((x) => x.approverEmail === user.email);
    if (approver) {
      setApprovalRequired(approver.status === 1); //status=1:pending
    }
  }, [invoice]);

  return (
    <>
      {section !== 'manual' ? (
        <>
          {approvalRequired ? (
            <ApproveInvoice />
          ) : (
            <SubmitInvoice
              selectedApprovers={selectedApprovers}
              editingApprovers={editingApprovers}
              glRows={glRows}
              setEnableMarkAsSent={setEnableMarkAsSent}
              unsavedData={unsavedData}
              setUnsavedData={setUnsavedData}
            />
          )}
        </>
      ) : (
        //section===manual
        <>
          {editingApprovers && canUpdateApprovers ? (
            <SubmitApprovers selectedApprovers={selectedApprovers} />
          ) : hasManualSubmitAccess ? (
            <>
              {requiredFieldsFilled ? (
                <SubmitSwitcher>
                  <SubmitInvoice
                    selectedApprovers={selectedApprovers}
                    editingApprovers={editingApprovers}
                    glRows={glRows}
                    setEnableMarkAsSent={setEnableMarkAsSent}
                    unsavedData={unsavedData}
                    setUnsavedData={setUnsavedData}
                  />
                  <SubmitForReview unsavedData={unsavedData} setUnsavedData={setUnsavedData} glRows={glRows} />
                </SubmitSwitcher>
              ) : (
                <SubmitInvoice
                  selectedApprovers={selectedApprovers}
                  editingApprovers={editingApprovers}
                  glRows={glRows}
                  setEnableMarkAsSent={setEnableMarkAsSent}
                  unsavedData={unsavedData}
                  setUnsavedData={setUnsavedData}
                />
              )}
            </>
          ) : (
            hasManualEditAccess && (
              <SubmitForReview unsavedData={unsavedData} setUnsavedData={setUnsavedData} glRows={glRows} />
            )
          )}
        </>
      )}
    </>
  );
}

import { Helmet } from 'react-helmet-async';
import RentalOrdersView from '../../../components/dashboard/rentals/RentalOrdersView';

export default function PendingRentals() {

  return (
    <>
      <Helmet>
        <title>Pending Rentals | Platform Solutions</title>
      </Helmet>

      <RentalOrdersView orderStatus="Pending" />
    </>
  );
}

import { Box, Button } from '@mui/material';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { getDocument } from 'pdfjs-dist';
import { Document, Page, pdfjs } from 'react-pdf';
import { Font } from '@react-pdf/renderer';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'simplebar-react/dist/simplebar.min.css';
import Scrollbar from '../scrollbar/Scrollbar';
import { useSnackbar } from '../snackbar';
import Toolbar from './toolbar/Toolbar';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();
pdfjs.GlobalWorkerOptions.useSystemFonts = false;
pdfjs.GlobalWorkerOptions.standardFontDataUrl = 'https://cdn.jsdelivr.net/npm/pdfjs-dist/standard_fonts/';

Viewer.propTypes = {
  blobUrl: PropTypes.blobUrl | undefined,
};
export default function Viewer({ blobUrl }) {
  Font.register({
    family: 'Almarai',
    src: '/fonts/Almarai/Almarai-Regular.ttf',
  });

  Font.register({
    family: 'Padauk',
    src: '/fonts/Padauk/Padauk-Regular.ttf',
  });
  pdfjs.RenderingContext = {
    canvasContext: {
      alpha: false,
    },
  };
  const pdfContainerRef = useRef();
  const scrollRef = useRef();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfDimensions, setPdfDimensions] = useState({ width: 0, height: 0 });
  const [zoom, setZoom] = useState(1);
  const [selectionBox, setSelectionBox] = useState(null);
  const [selectionText, setSelectionText] = useState('');
  const [highlightedWord, setHighlightedWord] = useState(null);
  const [rotation, setRotation] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const copyButtonRef = React.useRef(null);

  const updatePdfDimensions = throttle(() => {
    const pdfContainer = pdfContainerRef.current;
    if (pdfContainer) {
      const { width } = pdfContainer.getBoundingClientRect();
      setPdfDimensions({ width });
    }
  }, 500);
  useEffect(() => {
    updatePdfDimensions();
    window.addEventListener('resize', updatePdfDimensions);

    return () => {
      window.removeEventListener('resize', updatePdfDimensions);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    const handleScroll = () => {
      setSelectionBox(null);
      setHighlightedWord(null);
    };
    scrollRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [pdfContainerRef, setHighlightedWord]);

  const handleWordHover = (event) => {
    const target = event?.target;
    const wordBox = target?.getBoundingClientRect();
    if (!pdfContainerRef.current) return; // Ensure the container ref is valid

    if (!scrollRef.current || !pdfContainerRef.current) return;

    const containerBox = scrollRef.current.getBoundingClientRect();

    setHighlightedWord({
      top: Math.max(wordBox.top + window.scrollY, containerBox.top + window.scrollY),
      left: Math.max(wordBox.left + window.scrollX, containerBox.left + window.scrollX),
      width: Math.min(wordBox.width, containerBox.right - wordBox.left),
      height: Math.min(wordBox.height, containerBox.bottom - wordBox.top),
    });
  };

  const handleMouseLeave = () => {
    setHighlightedWord(null);
  };
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const textElements = pdfContainerRef.current.querySelectorAll('.react-pdf__Page__textContent span ');
      textElements.forEach((element) => {
        element.removeEventListener('mouseenter', handleWordHover);
        element.removeEventListener('mouseleave', handleMouseLeave);

        element.addEventListener('mouseenter', handleWordHover);
        element.addEventListener('mouseleave', handleMouseLeave);
      });
    });

    if (pdfContainerRef.current) {
      observer.observe(pdfContainerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => observer.disconnect();
  }, []);

  const handleMouseUp = async (event) => {
    if (copyButtonRef.current && copyButtonRef.current.contains(event?.target)) {
      return;
    }
    setSelectionBox(null);
    const selection = window.getSelection();

    if (selection && selection.toString().trim()) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      if (!scrollRef.current || !pdfContainerRef.current) return;

      const containerBox = scrollRef.current.getBoundingClientRect();
      setSelectionText(selection.toString());

      setSelectionBox({
        top: Math.max(rect.top + window.scrollY, containerBox.top + window.scrollY),
        left: Math.max(rect.left + window.scrollX, containerBox.left + window.scrollX),
        width: Math.min(rect.width, containerBox.right - rect.left),
        height: Math.min(rect.height, containerBox.bottom - rect.top),
      });
    } else {
      setSelectionBox(null);
    }
  };
  const handleCopyToClipboard = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(selectionText).then(() => {
      enqueueSnackbar('Text copied to clipboard!', {
        variant: 'info',
      });
      setSelectionBox(null);
    });
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Toolbar
        numPages={numPages}
        zoom={zoom}
        setZoom={setZoom}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setRotation={setRotation}
        blobUrl={blobUrl}
      />

      <Box
        sx={{ overflow: 'hidden', maxWidth: '100%' }}
        display={'center'}
        justifyContent={'center'}
        onMouseUp={handleMouseUp}
        ref={pdfContainerRef}
        id="pdf-content"
      >
        <Scrollbar sx={{ maxHeight: '600px' }} scrollableNodeProps={{ ref: scrollRef }}>
          <Document file={blobUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={zoom} rotate={rotation} width={pdfDimensions.width} maxWidth="100%" />
          </Document>
        </Scrollbar>
        {highlightedWord && (
          <div
            style={{
              position: 'absolute',
              top: highlightedWord.top,
              left: highlightedWord.left,
              width: highlightedWord.width,
              height: highlightedWord.height,
              backgroundColor: '#208ee05e',
              zIndex: 999,
              pointerEvents: 'none',
            }}
          />
        )}
        {selectionBox && (
          <>
            <div
              id="selection"
              style={{
                position: 'absolute',
                top: selectionBox.top,
                left: selectionBox.left,
                width: selectionBox.width,
                maxWidth: pdfDimensions.width,
                height: selectionBox.height,
                backgroundColor: 'rgba(0, 0, 255, 0.2)',
                pointerEvents: 'none',
              }}
            />
            <Button
              id="copy-button"
              ref={copyButtonRef}
              variant="contained"
              color="secondary"
              cursor="pointer"
              sx={{
                position: 'absolute',
                top: selectionBox?.top,
                left: selectionBox?.left,
                transform: 'translate(0, -100%)',
                zIndex: 999,
              }}
              onClick={handleCopyToClipboard}
            >
              Copy
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}

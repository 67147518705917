import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from '../../../../redux/store';
import {
  createColumns
} from '../../../../reusable-components/datagrid/custom-filters';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { VENDORS } from '../../../../reusable-components/datagrid/vendorColumns';

export default function InvoicingVendorTable({ CustomToolbar, setActiveRows }) {
  const [data, setData] = useState([]);

  const { data: { invoicingVendors } } = useSelector((state) => state.invoicing);

  const isLoading = useSelector((state) => state.invoicing.isLoading);

  useEffect(() => {
    setData(invoicingVendors);
  }, [invoicingVendors]);

  const columns = createColumns(VENDORS);

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="invoicing-vendors-invoicing-vendors"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          sort={[{ field: 'invoiceVendorId', sort: 'asc' }]}
          gridColumns={[...columns]}
          CustomLeftToolbar={CustomToolbar}
          setActiveRows={setActiveRows}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}

import { Helmet } from 'react-helmet-async';
import RentalOrdersView from '../../../components/dashboard/rentals/RentalOrdersView';

export default function CurrentRentals() {

  return (
    <>
      <Helmet>
        <title>Current Rentals | Platform Solutions</title>
      </Helmet>

      <RentalOrdersView orderStatus="Current" />
    </>
  );
}

import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { getGLAPIData, postAPIGLForm, refreshGLAPIData } from '../../../../api/invoicing';
import { getGLs } from '../../../../redux/slices/invoicing';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { GL_API_SETTINGS } from '../../../../reusable-components/datagrid/invoiceColumns';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';


export default function GlAPIModal() {
  const dispatch = useDispatch();
  const lastStep = 1;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const { facilities } = useSelector((state) => state.invoicing.data.invoicingVendors);
  const { invoicingGLs } = useSelector((state) => state.invoicing.data);

  const [value, setValue] = useState({
    id: '',
    facility: '',
  });

  const existingFacilityCodes = useMemo(() =>
    invoicingGLs?.filter(v => v.facilityId === value?.facility?.value)
      ?.map((v) => ({ code: v.glCode, name: v.subcategory.toLowerCase().trim() }))
    , [invoicingGLs, value?.facility?.value]);

  const filteredData = useMemo(() => data.filter(d => !existingFacilityCodes.some(c => c.code === d.code))
    , [data, existingFacilityCodes]);

  const isStepValid = useMemo(() => {
    switch (activeStep) {
      case 0:
        return !!value?.facility?.label;
      case 1:
        return rowSelectionModel.length > 0;
      default:
        return true;
    }
  }, [activeStep, value, rowSelectionModel]);

  const handleClickOpen = () => {
    setValue({
      id: '',
      facility: '',
    });
    setOpen(true);
  };

  const handleClose = () => {
    setActiveStep(0);
    setRowSelectionModel([]);
    setValue({
      id: '',
      facility: '',
    });
    setOpen(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep);
  };

  const handleNext = async () => {
    if (activeStep === lastStep) {
      await saveGLData();
      handleClose();
    } else {
      await getGLData();
      if (isStepValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const getGLData = async () => {
    await setIsLoading(true);
    const res = await getGLAPIData(value.facility.value);

    if (res.status === 200) {
      if (res.data) setData(res.data);
      setIsLoading(false);
      setIsRefreshLoading(true);
    }
    if (res.status === 204) {
      setIsLoading(false);
      setIsRefreshLoading(true);
    }
  }

  const refreshAPIGLData = async () => {
    const res = await refreshGLAPIData(value.facility.value);

    if (res.status === 200) {
      if (res.data) setData(res.data);
      setIsRefreshLoading(false);
    }
    if (res.status === 204) {
      setIsRefreshLoading(false);
    }
  }

  useEffect(() => {
    if (isRefreshLoading) {
      refreshAPIGLData();
    }
  }, [isRefreshLoading])


  const saveGLData = async () => {
    const response = await postAPIGLForm(value, rowSelectionModel);
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      enqueueSnackbar(`Successfully added ${response.data} GL${response.data === 1 ? '' : 's'}`, {
        variant: 'success',
      });
      dispatch(getGLs());
      setOpen(false);
    } else
      enqueueSnackbar(`Error: Could not add GLs`, {
        variant: 'error',
      });
  };

  useEffect(() => {
    setRowSelectionModel([]);
    setData([]);
  }, [value.facility]);


  return (
    <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
      <Button
        variant="outlined"
        sx={{ mr: 1 }}
        size="small"
        color="secondary"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="simple-icons:sage" />}
      >
        Import GLs
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth={activeStep === lastStep ? "lg" : "sm"} fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {activeStep > 0 && <IconButton title="Back" onClick={handleBack}
            style={{
              position: 'absolute',
              left: 8,
              top: 8, color: 'gray'
            }}>
            <Iconify icon="ic:baseline-arrow-back" width={28} height={28} />
          </IconButton>}
          Import Intacct GLs
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel color="secondary" width="600px">
            <Step key={"1"}>
              <StepLabel sx={{ color: 'rgba(112, 112, 112, 0.5)', mb: 4 }}>{"Choose Facility"}</StepLabel>
            </Step>
            <Step key={"2"}>
              <StepLabel sx={{ color: 'rgba(112, 112, 112, 0.5)', mb: 4 }}>{"Add GLs"}</StepLabel>
            </Step>
          </Stepper>
          <Box>
            {activeStep === 0 &&
              <Stack spacing={3} justifyContent="center" sx={{ px: 8 }}>
                <Typography variant={"subtitle1"} sx={{ mb: 1 }}>
                  Facility
                </Typography>
                <Autocomplete
                  size="small"
                  value={value.facility}
                  isOptionEqualToValue={(option, value) => option?.id === value?.facility?.id}
                  options={facilities || []}
                  onChange={(e, newValue) => setValue({ ...value, facility: newValue })}
                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                  sx={{
                    width: "95%",
                    mt: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabel-root ': { display: 'none' },
                  }}
                />
              </Stack>
            }
            {activeStep === lastStep && <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                <Typography variant="h5" sx={{ ml: 1, mb: 1, flex: 1 }}>
                  {value?.facility?.label}
                </Typography>

                {isRefreshLoading &&
                  <Typography variant="caption" sx={{ color: "warning.main" }}>
                    <CircularProgress color="warning" size={18} sx={{ mr: 0.5 }} /> Refreshing...
                  </Typography>
                }

                <Typography variant="h5" sx={{ ml: 1, mb: 1, flex: 1 }}>
                  {/*Placeholder to center text*/}
                </Typography>
              </Box>
              <Stack spacing={3} width={"100%"}>
                <CustomDataGrid
                  checkboxSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => { setRowSelectionModel(newRowSelectionModel) }}
                  keepNonExistentRowsSelected
                  rowSelectionModel={rowSelectionModel}
                  gridId="inv-api-gls"
                  keepNon
                  tableBoxSX={{ height: '330px' }}
                  boxSX={{ height: '400px' }}
                  scrollbarHeight={'250px'}
                  data={filteredData || []}
                  gridColumns={[...GL_API_SETTINGS]}
                  maxValue={{ ppdBudget: 2 }}
                  pinnedColumns={{ right: ['actions'] }}
                  sort={[{ field: 'name', sort: 'asc' }]}
                  isLoading={isLoading}
                />
              </Stack>
            </>}
          </Box>
        </DialogContent>
        <DialogActions>
          <AwaitButton
            variant='contained'
            color="primary"
            size="medium"
            disabled={!isStepValid}
            sx={{ width: 150 }}
            onClick={handleNext}
          >
            {activeStep === lastStep ? `Save ${rowSelectionModel.length > 0 ? `(${rowSelectionModel.length})` : ''}` : 'Next'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </ScopeGuard >
  );
}

import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// External libraries
import { DialogTitle, DialogContentText, TextField, Box, Autocomplete } from '@mui/material';
import { getResidentsForFacility } from '../../../../redux/slices/rentals';
import { getOrderingFacilities } from '../../../../redux/slices/orderSheet';
import { useDispatch } from '../../../../redux/store';
import { getCompaniesAndFacilities } from '../../../../api/rentals';

AccountInformation.propTypes = {
  setFacility: PropTypes.func,
  facility: PropTypes.object,
  setCompany: PropTypes.func,
  company: PropTypes.object,
};
export default function AccountInformation({ facility, setFacility, company, setCompany }) {
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [openNewOrderDialog, setOpenNewOrderDialog] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getCompaniesAndFacilities();
      setCompanies(response);
    } catch (error) {
      console.error('Error fetching company/facility data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (openNewOrderDialog) dispatch(getOrderingFacilities());
  }, [dispatch, openNewOrderDialog]);

  useEffect(() => {
    setFacilities(companies.find((c) => c.name === company?.name)?.listFacilities ?? []);
  }, [company, companies]);

  useEffect(() => {
    if (!facility?.id) return;
    try {
      dispatch(getResidentsForFacility(facility?.id));
    } catch (error) {
      console.error('Error fetching resident data:', error);
    }
  }, [facility?.id]);

  return (
    <>
      <DialogTitle
        sx={{
          textAlign: 'center',
          color: 'rgba(112, 112, 112, 0.5)',
        }}
      >
        Account Information
      </DialogTitle>
      <DialogContentText sx={{ fontWeight: 'bold' }}>Group Name</DialogContentText>{' '}
      <Autocomplete
        sx={{
          width: '100%',
          mt: 1,
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiFormLabel-root ': 'none',
        }}
        type="new-password"
        options={companies}
        autoHighlight
        getOptionLabel={(option) => option?.name || ''}
        getOptionSelected={(option, value) => option?.label === value?.label}
        value={company}
        onChange={(event, newValue) => {
          setCompany(newValue);
          setFacility('');
        }}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option?.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        )}
      />
      <DialogContentText sx={{ fontWeight: 'bold', mt: 3 }}>Facility Name</DialogContentText>
      <Autocomplete
        sx={{
          width: '100%',
          mt: 1,
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiFormLabel-root ': 'none',
        }}
        type="new-password"
        options={facilities}
        autoHighlight
        getOptionLabel={(option) => option?.facilityName || ''}
        getOptionSelected={(option, value) => option?.facilityName === value?.facilityName}
        value={facility}
        onChange={(event, newValue) => {
          setFacility(newValue);
        }}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option?.facilityName}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        )}
        disabled={company === null}
      />
    </>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useNavigate } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  TextField,
  Typography,
  Box,
  Button,
  Autocomplete,
  Grid,
  Stack,
  DialogActions,
} from '@mui/material';
import { Icon } from '@iconify/react';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { NotificationDialog } from '../../../../reusable-components/dialog';
import { useScopeCheck } from '../../../../reusable-components/scopes';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import { uploadInvoiceFiles } from '../../../../redux/slices/invoicing';
import { useSelector, useDispatch } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';

export default function ManualInvoiceUploader() {
  const newInvoiceAccess = useScopeCheck(['Manual-Invoice'], true);
  const hasEditAccess = useScopeCheck(['ManualInvoice-Edit'], true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: { manualInvoicing },
  } = useSelector((state) => state.invoicing);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [open, setOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [pdfFiles, setPdfFiles] = useState(null);
  const [step, setStep] = useState(0);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      handleFileInputChange({ target: { files: acceptedFiles } });
    },
  });
  useEffect(() => {
    if (uploadComplete && manualInvoicing.length > 0) {
      const nextId = manualInvoicing?.[0]?.id;

      navigate(`/dashboard/invoicing/manual/${nextId}`);
    }
  }, [manualInvoicing, uploadComplete]);

  const handleClose = () => {
    setOpen(false);
    setPdfFiles(null);
    setStep(0);
  };

  const handleFileInputChange = (event) => {
    const { files } = event.target;
    if (files) {
      const newFiles = Array.from(files);
      const mergedFiles = pdfFiles ? [...pdfFiles, ...newFiles] : newFiles;

      setPdfFiles(mergedFiles);
    }
  };

  const handleRemoveImage = (index) => {
    const removedPictures = [...pdfFiles];
    removedPictures.splice(index, 1);
    setPdfFiles(removedPictures);
  };

  const upload = async () => {
    setUploadComplete(false);
    await dispatch(uploadInvoiceFiles(pdfFiles));
    setOpen(false);
    setStep(0);
    setUploadComplete(true);
  };
  const stepButton = () => {
    switch (step) {
      case 0:
        return (
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {
              if (!newInvoiceAccess || !hasEditAccess) {
                setShowNotification(true);
                return;
              }
              setPdfFiles(null);
              setOpen(true);
              setStep(1);
            }}
            startIcon={<Iconify icon="eva:plus-outline" />}
          >
            Manual invoice
          </Button>
        );
      case 1:
        return (
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {}}
            startIcon={<Iconify icon="mdi:tray-arrow-up" />}
          >
            Upload invoice
          </Button>
        );

      default:
        return <div />;
    }
  };
  return (
    <>
      {stepButton()}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
          <Typography color="secondary" variant="h6" sx={{ mt: '20px' }}>
            Manual invoice
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              border: 'dashed 1px #4ca5e7',
              borderRadius: '4px',
              height: '250px',
              cursor: 'pointer',
              m: '10px',
            }}
            {...getRootProps()}
            onClick={() => {
              openDropzone();
            }}
          >
            <input {...getInputProps()} />
            <Stack spacing={1} direction={'column'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
              <Icon icon="arcticons:folder-upload" width="5rem" height="5rem" color="#8dc63f" />
              <Stack direction={'row'}>
                <Typography color="text.secondary">Drag and drop or &nbsp;</Typography>
                <Typography color="text.secondary" sx={{ textDecoration: 'underline' }}>
                  click here
                </Typography>
                <Typography color="text.secondary">&nbsp; to upload file(s) </Typography>
              </Stack>
            </Stack>
          </Box>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
            {pdfFiles?.map((file, index) => (
              <Grid item xs={2} key={index}>
                <Box
                  m={1}
                  boxShadow={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FileThumbnail key={file} file={file} tooltip sx={{ width: 32, height: 32 }} />

                  <IconButton onClick={() => handleRemoveImage(index)}>
                    <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="secondary"
            size="medium"
            disabled={!pdfFiles}
            sx={{ width: 150 }}
            onClick={upload}
          >
            Upload
          </AwaitButton>
        </DialogActions>
      </Dialog>

      {showNotification && (
        <NotificationDialog
          handleClose={() => {
            setShowNotification(false);
          }}
          title="Permission missing"
          content={"You don't have permission to create an invoice."}
        />
      )}
    </>
  );
}

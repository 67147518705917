import { IconButton, Badge, Box } from '@mui/material';
import { styled } from '@mui/system';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export function createColumns(COLUMNS, filters, setAnchorEl, setActiveFilter, key, activeFilter) {
  return COLUMNS.map((col) => {
    if (col?.type === 'customSelect') {
      return {
        ...col,
        filterOperators: [
          {
            value: 'isAnyOf',
            label: 'is any of',
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem ||
                !filterItem?.value ||
                !Array.isArray(filterItem?.value) ||
                filterItem?.value.length === 0
              ) {
                return null;
              }
              return (params) => {
                const { value } = params;
                return filterItem?.value?.includes(value);
              };
            },
          },
          {
            value: 'contains',
            label: 'contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem || !filterItem?.value) {
                return null;
              }
              return (params) => {
                const { value } = params;
                return value ? String(value)?.includes(filterItem?.value) : false;
              };
            },
          },
        ],
        renderHeader: (params) => (
          <Box draggable="true" sx={{ p: 1 }}>
            {col?.checkBox && col.checkBox()}
            {params?.colDef?.headerName}
            <Box
              component="span"
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
                setActiveFilter(col.field);
              }}
            >
              <StyledBadge badgeContent={filters[col.field]?.value?.length} color="info">
                <IconButton
                  disabled={activeFilter && activeFilter !== col.field}
                  color={filters[col.field]?.value?.length ? 'secondary' : 'white'}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <FilterAltIcon
                    sx={{
                      height: activeFilter === col.field ? '30px' : '17px',
                    }}
                  />
                </IconButton>
              </StyledBadge>
            </Box>
          </Box>
        ),
      };
    }
    if (col?.type === 'customNumber') {
      return {
        ...col,
        //sortable: false,
        filterable: false,
        filterOperators: [
          {
            value: '=',
            label: '=',
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem ||
                !filterItem?.value ||
                !Array.isArray(filterItem?.value) ||
                filterItem?.value.length === 0
              ) {
                return null;
              }
              return (params) => {
                const { value } = params;
                return filterItem?.value?.includes(value);
              };
            },
          },
          {
            value: 'contains',
            label: 'contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem || !filterItem?.value) {
                return null;
              }
              return (params) => {
                const { value } = params;
                return value ? String(value)?.includes(filterItem?.value) : false;
              };
            },
          },
        ],
        renderHeader: (params) => (
          <Box draggable="true">
            {params?.colDef?.headerName}
            <Box
              component="span"
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
                setActiveFilter(col.field);
              }}
            >
              <StyledBadge badgeContent={filters[col.field]?.value ? 1 : 0} color="info">
                <IconButton
                  disabled={activeFilter && activeFilter !== col.field}
                  color={filters[col.field]?.value?.length ? 'secondary' : 'white'}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <FilterAltIcon
                    sx={{
                      height: activeFilter === col.field ? '30px' : '17px',
                    }}
                  />
                </IconButton>
              </StyledBadge>
            </Box>
          </Box>
        ),
      };
    }
    if (col?.type === 'customMoney') {
      return {
        ...col,
        // //sortable: false,
        // filterable: false,
        filterOperators: [
          {
            value: 'range',
            label: 'range',
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem ||
                !filterItem?.value ||
                !Array.isArray(filterItem?.value) ||
                filterItem?.value.length !== 2
              ) {
                return null;
              }
              return (params) => {
                const { value } = params;
                return value >= filterItem?.value[0] && value <= filterItem?.value[1];
              };
            },
          },
          {
            value: 'contains',
            label: 'contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem || !filterItem?.value) {
                return null;
              }
              return (params) => {
                const { value } = params;
                return value ? String(value)?.includes(filterItem?.value) : false;
              };
            },
          },
        ],
        renderHeader: (params) => (
          <Box draggable="true">
            {params?.colDef?.headerName}
            <Box
              component="span"
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
                setActiveFilter(col.field);
              }}
            >
              <StyledBadge badgeContent={filters[col.field]?.value ? 1 : 0} color="info">
                <IconButton
                  disabled={activeFilter && activeFilter !== col.field}
                  color={filters[col.field]?.value?.length ? 'secondary' : 'white'}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <FilterAltIcon
                    sx={{
                      height: activeFilter === col.field ? '30px' : '17px',
                    }}
                  />
                </IconButton>
              </StyledBadge>
            </Box>
          </Box>
        ),
      };
    }
    if (col?.type === 'customText') {
      return {
        ...col,
        // //sortable: false,
        // filterable: false,
        filterOperators: [
          {
            value: 'contains',
            label: 'contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem || !filterItem?.value) {
                return null;
              }
              return (params) => {
                const value = String(params.value);
                const filterValue = String(filterItem?.value);
                return value ? value.toLowerCase().includes(filterValue.toLowerCase()) : false;
              };
            },
          },
        ],
        renderHeader: (params) => (
          <Box draggable="true" pr={.5}>
            {col?.checkBox && col.checkBox()}
            {params?.colDef?.headerName}
            <Box
              component="span"
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
                setActiveFilter(col.field);
              }}
            >
              <StyledBadge badgeContent={filters[col.field]?.value ? 1 : 0} color="info">
                <IconButton
                  disabled={activeFilter && activeFilter !== col.field}
                  color={filters[col.field]?.value?.length ? 'secondary' : 'white'}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <FilterAltIcon
                    sx={{
                      height: activeFilter === col.field ? '30px' : '17px',
                    }}
                  />
                </IconButton>
              </StyledBadge>
            </Box>
          </Box>
        ),
      };
    }
    if (col?.type === 'customDate') {
      return {
        ...col,
        // //sortable: false,
        // filterable: false,
        filterOperators: [
          {
            value: 'dateRange',
            label: 'date range',
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem ||
                !filterItem?.value ||
                !Array.isArray(filterItem?.value) ||
                filterItem?.value.length !== 2
              ) {
                return null;
              }
              return (params) => {
                const { value } = params;
                const start = new Date(filterItem?.value[0]);
                const end = new Date(filterItem?.value[1]);
                const val = typeof value === 'string' ? new Date(value.slice(1)) : new Date(value); //in orders tables the date gets concatinated as a string with a number in front for sorting, so removing the number where it exists
                return start <= val && val <= end;
              };
            },
          },
          {
            value: 'contains',
            label: 'contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem || !filterItem?.value) {
                return null;
              }
              return (params) => {
                const { value } = params;
                return value ? String(value)?.includes(filterItem?.value) : false;
              };
            },
          },
        ],
        renderHeader: (params) => (
          <Box draggable="true">
            {params?.colDef?.headerName}
            <Box
              component="span"
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
                setActiveFilter(col.field);
              }}
            >
              <StyledBadge badgeContent={filters[col.field]?.value ? 1 : 0} color="info">
                <IconButton
                  disabled={activeFilter && activeFilter !== col.field}
                  color={filters[col.field]?.value?.length ? 'secondary' : 'white'}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <FilterAltIcon
                    sx={{
                      height: activeFilter === col.field ? '30px' : '17px',
                    }}
                  />
                </IconButton>
              </StyledBadge>
            </Box>
          </Box>
        ),
      };
    }

    return { ...col, filterable: false };
  });
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'transparent', // Remove background color
    color: theme.palette.secondary.main, // Set text color to secondary
    boxShadow: 'none', // Remove any shadow or outline
    mr: 1,
  },
}));

import { createSlice } from '@reduxjs/toolkit';
import {
  getCarts,
  getCategories,
  getFacilityVendorsForFacility,
  getItems,
  getPricingTypes,
  getRentalOrders,
  getResidents,
  getVendors,
  postAddNewCart,
  postAddToCart,
  postCreateResident,
  postRemoveCartItem,
  postSubmitOrderApi,
  postUpdateOrderStatus,
} from '../../api/rentals';

const initialState = {
  isLoading: false,
  error: null,
  data: {
    cart: [],
    items: [],
    rentalVendors: [],
    facilityVendors: [],
    badge: 0,
    categoryData: [],
    residentsList: [],
    pendingRentals: [],
    pricingTypes: [],
  },
};

const slice = createSlice({
  name: 'rentals',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addToCartSuccess(state, action) {
      state.isLoading = false;
      state.data.badge = 1;
      state.data.cart = action.payload;
      state.data.cart.map((rentalItem) => {
        rentalItem.isSelected = true;
        return rentalItem;
      });
    },
    getCartSuccess(state, action) {
      state.isLoading = false;
      state.data.cart = action.payload;
    },

    removeFromReduxCart(state, action) {
      const { cart } = state.data;
      const cartItemId = action.payload;
      const index = cart?.findIndex((rentalItem) => cartItemId === rentalItem.cartItemId);
      if (cart[index].quantity === 1) {
        cart?.splice(index, 1);
      } else cart[index].quantity--;
    },

    clearBadge(state, action) {
      state.data.badge = 0;
    },

    selectedItem(state, action) {
      const { cart } = state.data;
      const { cartItem, isSelected } = action.payload;
      const index = cart?.findIndex(
        (rentalItem) => cartItem.itemId === rentalItem.itemId && cartItem.residentId === rentalItem.residentId
      );
      cart[index].isSelected = !cart[index].isSelected;
    },

    deselectResidentCartItems(state, action) {
      const { cart } = state.data;
      const residentId = action.payload;
      cart.map((rentalItem) => {
        if (residentId === rentalItem.residentId) {
          rentalItem.isSelected = false;
        }
        return rentalItem;
      });
    },
    getItemsSuccess(state, action) {
      state.isLoading = false;
      state.data.items = action.payload;
    },
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.data.categoryData = action.payload;
    },
    getVendorsSuccess(state, action) {
      state.isLoading = false;
      state.data.rentalVendors = action.payload;
    },
    getResidentsSuccess(state, action) {
      state.isLoading = false;
      state.data.residentsList = action.payload;
    },
    getFacilityVendorsSuccess(state, action) {
      state.isLoading = false;
      state.data.facilityVendors = action.payload;
    },
    postCreateResidentSuccess(state, action) {
      state.isLoading = false;
      state.data.residentsList = [...state.data.residentsList, action.payload];
    },
    postSubmitOrderSuccess(state, action) {
      state.isLoading = false;
    },
    getPendingRentalsSuccess(state, action) {
      state.isLoading = false;
      state.data.pendingRentals = action.payload;
    },
    postUpdateOrderStatusSuccess(state, action) {
      state.isLoading = false;
    },
    getPricingTypesSuccess(state, action) {
      state.isLoading = false;
      state.data.pricingTypes = action.payload;
    },
  },
});

export function addItemToCart(itemId, residentId, userId, cartId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (cartId === 0) {
        const response = await postAddNewCart(residentId, userId);
        const cartId = response.data.id;
        const resp = await postAddToCart(itemId, cartId, userId);
        if (resp.status === 200) {
          dispatch(slice.actions.addToCartSuccess(resp.data));
        }
      } else {
        const resp = await postAddToCart(itemId, cartId, userId);
        if (resp.status === 200) {
          dispatch(slice.actions.addToCartSuccess(resp.data));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCartsForUser() {
  return async (dispatch) => {
    try {
      const response = await getCarts();
      dispatch(slice.actions.getCartSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeItemFromCart(cartItemId, deleteAll) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postRemoveCartItem(cartItemId, deleteAll);

      if (response.status === 200) {
        dispatch(slice.actions.removeFromReduxCart(cartItemId));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearBadgeFromRedux() {
  return async (dispatch) => {
    dispatch(slice.actions.clearBadge());
  };
}

export function updateSelectedItem(cartItem, isSelected) {
  return async (dispatch) => {
    dispatch(slice.actions.selectedItem({ cartItem, isSelected }));
  };
}

export function deselectAllItems(residentId) {
  return async (dispatch) => {
    dispatch(slice.actions.deselectResidentCartItems(Number(residentId)));
  };
}
export function getRentalItems() {
  return async (dispatch) => {
    try {
      const response = await getItems();
      dispatch(slice.actions.getItemsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCategoryData() {
  return async (dispatch) => {
    try {
      const response = await getCategories();
      dispatch(slice.actions.getCategoriesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getRentalVendors() {
  return async (dispatch) => {
    try {
      const response = await getVendors();
      dispatch(slice.actions.getVendorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getResidentsForFacility(facilityId) {
  return async (dispatch) => {
    try {
      const response = await getResidents(facilityId);
      dispatch(slice.actions.getResidentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getFacilityVendors(facilityId) {
  return async (dispatch) => {
    try {
      const response = await getFacilityVendorsForFacility(facilityId);
      dispatch(slice.actions.getFacilityVendorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function postAddResident(resident, facilityId) {
  return async (dispatch) => {
    try {
      const response = await postCreateResident(resident, facilityId);

      dispatch(slice.actions.postCreateResidentSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return false;
  };
}
export function postSubmitOrder(items) {
  return async (dispatch) => {
    try {
      const response = await postSubmitOrderApi(items);
      dispatch(slice.actions.postSubmitOrderSuccess(items));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}
export function getRentalOrdersRedux(orderStatus) {
  return async (dispatch) => {
    try {
      const response = await getRentalOrders(orderStatus);
      dispatch(slice.actions.getPendingRentalsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}
export function updateOrderStatus(orderId, updatedStatus) {
  return async (dispatch) => {
    try {
      const response = await postUpdateOrderStatus(orderId, updatedStatus);
      dispatch(slice.actions.postUpdateOrderStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getPricingTypeOptions() {
  return async (dispatch) => {
    try {
      const response = await getPricingTypes();
      dispatch(slice.actions.getPricingTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export default slice.reducer;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { fCurrency } from '../../utils/formatNumber';
import { renderColorBox } from '../../utils/cssStyles';
import { cloudinaryThumbnail, cloudinaryFull } from '../../utils/formatCloudinary';
import Image from '../image';
import Lightbox from '../lightbox';
import Iconify from '../iconify';
import { fMonthDayYear } from '../../utils/formatTime';

export default function RenderImage({ params }) {
  RenderImage.propTypes = {
    params: PropTypes.object,
  };
  const { pictures } = params.row;
  const picture = pictures?.[0] || null;
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };
  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  if (!picture) return null;
  return (
    <>
      <Image
        alt="attachment"
        src={cloudinaryThumbnail(picture)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={(e) => {
          e.picture = true;
          handleOpenLightbox();
        }}
      />
      <Lightbox
        index={0}
        slides={pictures?.map((picture) => ({ src: cloudinaryFull(picture) }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}
const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);

//Note: Picture valueGetter has manufacturerId data because there isn't a good way to hide the manufacturerId column using the CustomDataGrid.
//The column picture was chosen because it doesn't have a search filter on the column itself.
//manufacturerId is hidden here so you can search for item by manufacturer Id even though its not a column.
const picture = {
  field: 'picture',
  headerName: 'Image',
  flex: 0.4,
  renderCell: (params) => <RenderImage params={params} />,
  valueGetter: (params) => params.row.manufacturerIds,
  sortable: false,
  filterable: false,
};

const description = {
  field: 'description',
  headerName: 'Description',
  flex: 2,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.row.description,
  type: 'customText',
};
const subcategory = {
  field: 'subcategory',
  headerName: 'Subcategory',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  flex: 1,
  minWidth: 100,
  type: 'customSelect',
};

const category = {
  field: 'category',
  headerName: 'Category',
  width: 120,
  type: 'customSelect',
  renderCell: (params) => renderColorBox(params.row.category, 'secondary'),
};

const price = {
  field: 'price',
  headerName: 'Price',
  flex: 0.5,
  minWidth: 100,
  renderCell: (params) => {
    if (params.row.productDetailUOM == null) return fCurrency(params.row.price);
    return (
      <Box>
        {fCurrency(params.row.price)}/{params.row.uomType}
        <br />
        {fCurrency(params.row.productDetailUOM?.price)}/{params.row.productDetailUOM?.uomType}
      </Box>
    );
  },
  valueGetter: (params) => params.row.price,
  type: 'customMoney',
  headerAlign: 'left',
  align: 'left',
};

const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  flex: 1,
  minWidth: 100,
  type: 'customSelect',
};

const vendorItemIds = {
  field: 'vendorItemIds',
  headerName: 'Vendor Ids',
  flex: 1.7,
  minWidth: 125,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
};
const total = {
  field: 'total',
  headerName: 'Total',
  flex: 0.5,
  minWidth: 100,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.row.total,
};

const id = {
  field: 'productId',
  headerName: 'PS ID',
  flex: 0.5,
  minWidth: 70,
  type: 'customText',
  renderCell: (params) => `PS${params.row.productId}`,
  valueGetter: (params) => `PS${params.row.productId}`,
};

const facility = {
  field: 'facility',
  headerName: 'Facility',
  flex: 2,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customSelect',
};
const group = {
  field: 'group',
  headerName: 'Group',
  flex: 2,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customSelect',
};

const orderNumber = {
  field: 'orderNumber',
  headerName: 'PO #',
  flex: 0.5,
  minWidth: 100,
  type: 'customSelect',
};

const dateApproved = {
  field: 'dateApproved',
  headerName: 'Approved On',
  flex: 1,
  minWidth: 100,
  type: 'customDate',
  renderCell: (params) => renderIcon(fMonthDayYear(params.value), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => (params.value ? new Date(params.value) : null),
};
const orderedApproved = {
  field: 'orderedApproved',
  headerName: 'Ordered/Approved',
  flex: 0.5,
  minWidth: 100,
  renderCell: (params) => `${params.row.quantity} | ${params.row.updatedQuantity}`,
  valueGetter: (params) => `${params.row.quantity} | ${params.row.updatedQuantity}`,
};

const submittedBy = {
  field: 'submittedBy',
  headerName: 'Submitted By',
  flex: 1,
  minWidth: 100,
  type: 'customSelect',
};
const uom = {
  field: 'uom',
  headerName: 'UOM',
  renderCell: (params) => {
    if (params.row.productDetailUOM == null) return `${params.row.uomAmount}/${params.row.uomType}`;
    return (
      <Box>
        {params.row.uomAmount}/{params.row.uomType}
        <br />
        {params.row.productDetailUOM?.uomAmount}/{params.row.productDetailUOM?.uomType}
      </Box>
    );
  },
  flex: 0.4,
  minWidth: 100,
  type: 'customSelect',
};

const vendorItemId = {
  field: 'vendorItemId',
  headerName: 'Vendor Item Id',
  flex: 0.6,
  minWidth: 190,
  renderCell: (params) => {
    const hasLink = params.row.vendorItemLink;
    return hasLink ? (
      <a href={params.row.vendorItemLink} target="_blank" rel="noreferrer">
        {params.value}
      </a>
    ) : (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          '&:hover': {
            cursor: 'copy',
          },
        }}
        onClick={() => {
          navigator.clipboard.writeText(params.value);
        }}
      >
        {params.value}
      </Box>
    );
  },
  type: 'customText',
};

const baseUOMAmount = {
  field: 'baseUOMAmount',
  headerName: 'Base UOM',
  renderCell: (params) =>
    `${params.row.baseUOMAmount ? params.row.baseUOMAmount : ''}${params.row.baseUOMType ? '/' : ''}${
      params.row.baseUOMType ? params.row.baseUOMType : ''
    }`,
  flex: 0.6,
  minWidth: 100,
  type: 'customText',
};

const isPPD = {
  field: 'isPPD',
  headerName: 'PPD',
  renderCell: (params) => (params.value ? 'Yes' : ''),
  flex: 0.5,
  minWidth: 100,
  type: 'customSelect',
};
const perItem = {
  field: 'perItem',
  headerName: 'Per Item',
  flex: 0.6,
  minWidth: 100,
  renderCell: (params) => fCurrency(params.row.price / params.row.uomAmount),
  valueGetter: (params) => params.row.price / params.row.uomAmount,
  type: 'number',
  headerAlign: 'left',
  align: 'left',
};

const perUnit = {
  field: 'perUnit',
  headerName: 'Per Unit',
  flex: 0.6,
  minWidth: 100,
  renderCell: (params) =>
    params.row.uomAmount && params.row.baseUOMAmount
      ? fCurrency(params.row.price / params.row.uomAmount / params.row.baseUOMAmount)
      : fCurrency(params.row.price / params.row.uomAmount),
  valueGetter: (params) =>
    params.row.uomAmount && params.row.baseUOMAmount
      ? params.row.price / params.row.uomAmount / params.row.baseUOMAmount
      : params.row.price / params.row.uomAmount,
  type: 'number',
  headerAlign: 'left',
  align: 'left',
};

const manufacturer = {
  field: 'manufacturer',
  headerName: 'Manufacturer',
  flex: 1,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const manufacturerId = {
  field: 'manufacturerId',
  headerName: 'Manufacturer Id',
  flex: 0.7,
  minWidth: 100,
  type: 'customSelect',
};

const addedBy = {
  field: 'addedBy',
  headerName: 'Added By',
  flex: 1,
  minWidth: 120,
  type: 'customSelect',
};

const dateAdded = {
  field: 'dateAdded',
  headerName: 'Date Added',
  flex: 1,
  renderCell: (params) =>
    params.value ? renderIcon(fMonthDayYear(params.value), 'material-symbols:calendar-today-outline') : '',
  valueGetter: (params) => new Date(params.value),
  type: 'customDate',
};

const FULL_CATALOG = [
  picture,
  description,
  category,
  subcategory,
  vendor,
  id,
  price,
  vendorItemIds,
  addedBy,
  dateAdded,
];

const CATALOG_ITEM = [
  picture,
  vendorItemId,
  price,
  uom,
  baseUOMAmount,
  manufacturer,
  manufacturerId,
  perItem,
  perUnit,
  isPPD,
  vendor,
];

const CATALOG_ITEMS_PER_VENDOR = [
  picture,
  description,
  category,
  subcategory,
  id,
  price,
  addedBy,
  dateAdded,
];
const SKU_ITEM = [
  picture,
  description,
  category,
  subcategory,
  vendorItemId,
  id,
  price,
  uom,
  manufacturer,
  manufacturerId,
  vendor
];

const ORDER_HISTORY = [
  orderNumber,
  facility,
  group,
  dateApproved,
  vendor,
  vendorItemId,
  description,
  orderedApproved,
  price,
  total,
  submittedBy,
];

const ORDER_GUIDE = [facility];

export { CATALOG_ITEM, FULL_CATALOG, ORDER_HISTORY, ORDER_GUIDE, SKU_ITEM, CATALOG_ITEMS_PER_VENDOR };

import { useState, useEffect, useMemo } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PropTypes from 'prop-types';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector, useDispatch } from '../../../redux/store';
import { NEW_INVOICES, PENDING_INVOICES } from '../../../reusable-components/datagrid/invoiceColumns';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import {
  getPendingInvoicing,
  getManualInvoicing,
  addInvoiceLog,
  getInvoicingApprovers,
} from '../../../redux/slices/invoicing';
import { createColumns } from '../../../reusable-components/datagrid/custom-filters';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { ScopeGuard, useScopeCheck } from '../../../reusable-components/scopes';
import { deleteInvoice } from '../../../api/invoicing';
import { HOST_API_URL } from '../../../config-global';
import { fMonthDayYear } from '../../../utils/formatTime';
import ManagerChipsWrapper from '../../../reusable-components/datagrid/ManagerChipsWrapper';
import PdfPopupButton from '../../../reusable-components/pdf-popup/PdfPopupButton';

InvoicingView.propTypes = {
  CustomToolbar: PropTypes.func,
  setActiveRows: PropTypes.func,
  showSelection: PropTypes.string,
  setSelectedToProcess: PropTypes.func,
  setShowSelection: PropTypes.func
};
export default function InvoicingView({ CustomToolbar, setActiveRows, showSelection, setShowSelection, setSelectedToProcess}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const section = location.pathname.split('/').pop();

  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const {
    data: { invoiceData, pendingInvoicing, previousInvoicing, manualInvoicing, invoicingApprovers },
  } = useSelector((state) => state.invoicing);
  const { user } = useSelector((state) => state.user);
  const { queue } = invoiceData;
  const isSubmitManualInvoice = useScopeCheck(['ManualInvoice-Submit', true]);

  const isLoading = useSelector((state) => state.invoicing.isLoading);
  useEffect(() => {
    setShowSelection('');
    if (section === 'manual') {
      setData(manualInvoicing);
    } else if (section === 'pending') {
      setData(pendingInvoicing);
    } else if (section === 'pendingFilter') {
      const pendingFiltered = pendingInvoicing.filter((invoice) =>
        invoice.facilityManagerAssignments.find((fma) => fma.currentUser)
      );
      const invoicesWaitingApproval = invoicingApprovers?.filter(
        (a) => a.approverEmail === user.email && a.status ===1
      ); //pending=1

      const manualFiltered = pendingInvoicing.filter(
        (invoice) =>
          invoice.sourceMethod === 'Manual' &&
          (invoicesWaitingApproval.some((ia) => ia.invoiceId === invoice.id) ||
            invoice.addedBy === user?.email ||
            (isSubmitManualInvoice && invoice.approvalStatus === 0))
      );

      setData(manualFiltered.concat(pendingFiltered));
    } else if (section === 'awaitingSubmission') {
      const awaitingSubmission = pendingInvoicing.filter(
        (invoice) => invoice.sourceMethod === 'Manual' && invoice.approvalStatus === 0
      );
      setData(awaitingSubmission);
    }
  }, [pendingInvoicing, previousInvoicing, manualInvoicing, searchParams, section]);

  const handleDelete = async (id) => {
    const response = await deleteInvoice(id);
    if (response.status === 200) {
      enqueueSnackbar('Invoice deleted successfully', { variant: 'success' });
      if (section === 'manual' || section === 'pendingFilter') {
        dispatch(getManualInvoicing());
        dispatch(getPendingInvoicing({ pending: true }));
      } else if (section === 'pending') {
        dispatch(getPendingInvoicing({ pending: true }));
      }
      dispatch(getInvoicingApprovers());
    }
  };

  const vendorInvoiceId = {
    field: 'vendorInvoiceId',
    headerName: 'Vendor Id',
    minWidth: 90,
    flex: 0.8,
    renderCell: (params) => {
      const isQueued = params.row.queInfo !== null;

      return isQueued ? (
        <>
          <Tooltip
            title={
              <>
                <div>Invoice in Que:</div>
                <div>Added by: {params.row.queInfo?.addedBy || ''}</div>
                <div>Added on: {fMonthDayYear(params.row.queInfo.dateAdded)}</div>
                <div>Que processes: {params.row.queInfo.queRunTime}</div>
                <div>Sends to: {params.row.queInfo.sendTo}</div>
              </>
            }
          >
            <Box
              component="div"
              sx={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                fontSize: '0.75rem',
                color: 'info.main',
              }}
            >
              {params.value}
            </Box>
          </Tooltip>
        </>
      ) : (
        <Box
          component="div"
          sx={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        >
          {params.value}
        </Box>
      );
    },
    type: 'customText',
    valueGetter: (params) => params.row.vendorInvoiceId,
  };

  const ActionButtons = (params) => {
    const { id, sourceMethod } = params.row;
    return (
      <>
        <ScopeGuard scopes={sourceMethod === 'Manual' ? ['ManualInvoice-Edit'] : ['Invoice-Edit']} allowAdmin>
          <ConfirmDialog content={`Are you sure you want to delete this invoice?`} action={() => handleDelete(id)} />
        </ScopeGuard>
        {params.row.hasMainFile && (
          <PdfPopupButton
            icon={<RemoveRedEyeIcon />}
            url={`${HOST_API_URL}Api/Invoices/ViewInvoiceFile?id=${params.row.id}&mainFile=true`}
            tooltipText={
              <iframe
                style={{ width: '100%', height: '100%' }}
                title="testing"
                src={`${HOST_API_URL}Api/Invoices/ViewInvoiceFile?id=${params.row.id}&mainFile=true`}
              />
            }
            largeTooltip
            row={params.row}
          />
        )}
      </>
    );
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
    sortable: false,
  };
  const downloadEmail = async (id) => {
    window.open(`${HOST_API_URL}Api/Invoices/DownloadManualInvoiceEmail?id=${id}`, '_blank');
  };

  const sentBy = {
    field: 'sentBy',
    headerName: 'Sent by',
    type: 'customText',
    renderCell: (params) => {
      const manualInvoiceEmail = params.row.manualInvoiceEmail ?? false
      return (
        <>
        <Typography variant="subtitle2">
        { params.row.sentBy }
        </Typography >
          {manualInvoiceEmail &&
            <EmailIcon
            onClick={() =>  downloadEmail(params.row.id) }
              sx={{ height: "14px", mb: -0.3, ml: 0.5, cursor: 'pointer' }}
              color="secondary"
            />
          }
          </>
      );
    },
    valueGetter: (params) => params.row.sentBy,
    minWidth: 100,
  };
  const manager = ManagerChipsWrapper(() => dispatch(getPendingInvoicing({ pending: true })), 'Invoices');
  const conditionalColumns = useMemo(() => {
    if (section === 'manual') {
      return createColumns([...NEW_INVOICES.slice(0, 11), sentBy, vendorInvoiceId, ...NEW_INVOICES.slice(11)]);
    }
    return createColumns([...PENDING_INVOICES.slice(0, 11), vendorInvoiceId, ...PENDING_INVOICES.slice(11), manager]);
  }, [searchParams, section, queue]);

  // const columns = createColumns([...conditionalColumns]);

  if (!invoiceData) return null;
  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="invoicing-invoice-view"
          applyQuickFilter
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          gridColumns={[...conditionalColumns, actions]}
          CustomLeftToolbar={CustomToolbar}
          setActiveRows={setActiveRows}
          checkboxSelection={showSelection === 'Select invoices'}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedToProcess(newSelection);
            if(newSelection.length !== 0)  localStorage.setItem('selectedInvoices', JSON.stringify(newSelection))
            else(localStorage.removeItem('selectedInvoices'))
          }}
          rowSelectionModel={JSON.parse(localStorage.getItem('selectedInvoices')) || []}

          onCellClick={(row) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            if (row.field !== 'manager' && row.field !== 'actions' && row.field !== 'sentBy' && row.field !== 'status' && row.field !== '__check__') {
              const invoiceId = row.id;
              dispatch(
                addInvoiceLog({
                  invoiceId,
                  messageType: 'View',
                })
              );
              navigate(`/dashboard/invoicing/${section}/${invoiceId}`);
            }
          }}
          isLoading={!data || isLoading}
        />
      </Grid>
    </>
  );
}

import PropTypes from 'prop-types';
import { Typography, Stack, Card } from '@mui/material';
import Scrollbar from '../../../reusable-components/scrollbar';
import Iconify from '../../../reusable-components/iconify';
import ReportsSideBarGroup from './ReportSideBarGroup';

ReportsSideBar.propTypes = {
  formValues: PropTypes.object
};

export default function ReportsSideBar({ formValues })
{
  return <Scrollbar sx={{ height: 'calc(100vh - 200px)' }}>
    <Card sx={{ p: 1, width: '100%', overflow: 'auto' }}>
      <Stack direction="column">
        {formValues.selectedReport && <>
          <Typography sx={{ fontWeight: 'bold', clear: 'both' }} variant="subtitle1" color="info.main">
            <Iconify sx={{ mr: 0.5, display: 'inline' }} icon="mingcute:paper-line" width={16} height={16} color="secondary" />
            {formValues.selectedReport?.name}
          </Typography>
        </>}
        <ReportsSideBarGroup values={formValues.dateValues} title="Dates:" icon={"mingcute:calendar-2-line"} getValue={(value) =>
          `${value[0]?.month.shortName} ${value[0]?.day} ${value[0].year}  -  ${value[1].month.shortName} ${value[1].day} ${value[1].year}`} />
        <ReportsSideBarGroup values={formValues.companies} title="Groups:" icon={"mingcute:group-3-line"} getValue={(value) => value.label} />
        <ReportsSideBarGroup values={formValues.facilities} title="Facilities:" icon={"mingcute:hotel-line"} getValue={(value) => value.label} />
        <ReportsSideBarGroup values={formValues.categories} title="Categories:" icon={"mingcute:hexagons-2-line"} getValue={(value) => value.label} />
        <ReportsSideBarGroup values={formValues.subcategories} title="Subcategories:" icon={"mingcute:filter-line"} getValue={(value) => value.label} />
        <ReportsSideBarGroup values={formValues.vendors} title="Vendors:" icon={"mingcute:user-star-line"} getValue={(value) => value.label} />  
      </Stack>
    </Card>
  </Scrollbar>;
}
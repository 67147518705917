import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ticketReducer from './slices/tickets';
import budgetReducer from './slices/budget';
import orderGuidesReducer from './slices/orderGuides';
import orderSheetReducer from './slices/orderSheet';
import cartReducer from './slices/cart';
import censusReducer from './slices/census';
import usersReducer from './slices/users';
import userReducer from './slices/user';
import dashboardReducer from './slices/dashboard';
import catalogReducer from './slices/catalog';
import warehouseReducer from './slices/warehouse';
import emailRecipientReducer from './slices/emailRecipient';
import ordersReducer from './slices/orders';
import invoicingReducer from './slices/invoicing';
import vendorsReducer from './slices/vendors';
import rentalsReducer from './slices/rentals';

export const userPersistConfig = {
  key: 'cart',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['user'],
};

// Function to create a store for a specific tab
export function createStoreForTab(tabKey) {
  const rootReducer = combineReducers({
    budget: budgetReducer,
    cart: cartReducer,
    catalog: catalogReducer,
    census: censusReducer,
    dashboard: dashboardReducer,
    invoicing: invoicingReducer,
    orderGuides: orderGuidesReducer,
    orderSheet: orderSheetReducer,
    orders: ordersReducer,
    tickets: ticketReducer,
    user: persistReducer(userPersistConfig, userReducer),
    users: usersReducer,
    vendors: vendorsReducer,
    warehouse: warehouseReducer,
    emailRecipient: emailRecipientReducer,
    rentals: rentalsReducer,
  });

  const rootPersistConfig = {
    key: `root-${tabKey}`, // Unique per tab
    storage,
    keyPrefix: 'redux-',
    whitelist: [], // Customize this per your needs
  };

  const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
    devTools: {
      name: `Tab-${tabKey}`, // Name your store based on the tabKey
    },
  });
}

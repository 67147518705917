import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  FormControl,
  Tooltip,
  Grid,
  MenuItem,
  Typography,
  TextField,
  IconButton,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { saveApprovers, getInvoiceBreakdown } from '../../../../redux/slices/invoicing';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { CustomAvatar } from '../../../../reusable-components/custom-avatar';
import { ChevronBox } from '../../../../reusable-components/chevron-box';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import Dnd from '../../../../reusable-components/DragAndDrop/Dnd';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';

Approvers.propTypes = {
  glRows: PropTypes.any,
  selectedApprovers: PropTypes.any,
  setSelectedApprovers: PropTypes.func,
  editingApprovers: PropTypes.bool,
  setEditingApprovers: PropTypes.func,
};
export default function Approvers({
  selectedApprovers,
  setSelectedApprovers,
  editingApprovers,
  setEditingApprovers,
  glRows,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    data: { invoiceViewers, invoiceApprovers, invoiceItemData },
  } = useSelector((state) => state.invoicing);
  const { invoice } = invoiceItemData || {};
  const { user } = useSelector((state) => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const section = location.pathname.split('/')[3];
  const selectRef = useRef();
  const containerRef = useRef(null);
  const popperRef = useRef(null);
  const hasManualAccess = useScopeCheck(['Manual-Invoice'], true);
  const hasEditAccess = useScopeCheck(['ManualInvoice-Edit'], true);

  const canUpdateApprovers = hasManualAccess && hasEditAccess;
  const requiredFieldsFilled =
    invoice?.facilityId > 0 && invoice?.vendorId > 0 && invoice?.vendorInvoiceId !== null && glRows?.length > 0;
  const colors = ['#f19e3b', '#b128bc', '#208ee0', '#8dc63f'];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [value, setValue] = useState('');
  const reOrderApprovals = useCallback(async (newApprovers) => {
    const updatedApprovers = newApprovers.map((approver, index) => ({
      ...approver,
      approvalIndex: index,
    }));
    setSelectedApprovers(updatedApprovers);
    return updatedApprovers;
  }, []);
  const handleChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setSelectedApprovers((prev) => [
        ...prev,
        {
          approvalIndex: selectedApprovers.length,
          approverEmail: newValue,
          approverFullName: newValue,
          status: 1,
          id: 0,
        },
      ]);
    } else if (newValue && newValue.value) {
      const isAlreadySelected = selectedApprovers.some((item) => item.approverEmail === newValue.value);
      reOrderApprovals(
        isAlreadySelected
          ? selectedApprovers?.filter((item) => item.approverEmail !== newValue.value)
          : [
              ...selectedApprovers,
              {
                approvalIndex: selectedApprovers.length,
                approverEmail: newValue.value,
                approverFullName: newValue.name,
                status: 1,
                id: 0,
              },
            ]
      );
    }
  };
  const handleDelete = useCallback(
    async (item) => {
      const updatedApprovers = selectedApprovers?.filter((a) => a.approverEmail !== item.approverEmail);
      await reOrderApprovals(updatedApprovers);
    },
    [selectedApprovers, reOrderApprovals]
  );
  const handleAddApprover = () => {
    setOpenList(!openList);
  };
  const handleSaveApprovers = async () => {
    setIsSubmitting(true);
    const response = await dispatch(saveApprovers(id, selectedApprovers));
    if (response === 'success') {
      await dispatch(getInvoiceBreakdown(id));
      enqueueSnackbar('Approvers modified successfully', { variant: 'success' });
      setEditingApprovers(false);
    } else {
      enqueueSnackbar('Error modifying approvers', { variant: 'error' });
    }
    setOpenList(false);
    setIsDeleting(false);
    setIsSubmitting(false);
  };
  const handleCopyToClipboard = (email) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        enqueueSnackbar('Email copied to clipboard!', {
          variant: 'info',
        });
      })
      .catch((err) => {
        console.error('Failed to copy email: ', err);
      });
  };
  const moveItem = useCallback(
    (sourceItem, destinationItem) => {
      if (!canUpdateApprovers) return;
      const sourceItemIndex = selectedApprovers?.findIndex((item) => item.approverEmail === sourceItem?.approverEmail);
      const destinationItemIndex = selectedApprovers?.findIndex(
        (item) => item.approverEmail === destinationItem?.approverEmail
      );
      if (sourceItemIndex > -1 && destinationItemIndex > -1) {
        const newArr = [...selectedApprovers];
        newArr[sourceItemIndex] = destinationItem;
        newArr[destinationItemIndex] = sourceItem;
        reOrderApprovals(newArr);
      }
    },
    [selectedApprovers, reOrderApprovals]
  );

  const handleCancel = () => {
    setIsDeleting(false);
    reOrderApprovals(invoiceApprovers);
  };

  // useEffect(() => {
  //   if (!trashRef.current) return;
  //   dropTargetForElements({
  //     element: trashRef.current,
  //     getData: ({ input, element }) => ({ type: 'TRASH_BIN' }),
  //     onDrop: ({ source }) => {
  //       if (source?.data?.type === 'ITEM') {
  //         handleDelete(source.data.item);
  //       }
  //     },
  //   });
  // }, [trashRef, handleDelete]);
  useEffect(() => {
    setSelectedApprovers(
      invoiceApprovers?.map((approver) => ({
        approvalIndex: approver.approvalIndex,
        approverEmail: approver.approverEmail,
        approverFullName: approver.approverFullName,
        status: approver.status,
        id: approver.id,
      }))
    );
  }, [invoiceApprovers]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const containerClicked = containerRef.current?.contains(event.target);
      const popperClicked = popperRef.current?.contains(event.target);

      // Close if the click is outside both the container and the dropdown
      if (!containerClicked && !popperClicked) {
        setOpenList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpenList]);

  useEffect(() => {
    if (openList && selectRef.current) {
      // Wait for the DOM to update before focusing
      setTimeout(() => selectRef.current.focus(), 0);
    }
  }, [openList]);

  useEffect(() => {
    const hasNewApprover = selectedApprovers.some((item) => {
      const existingApprover = [...invoiceApprovers]?.find(
        (x) => x.approvalIndex === item.approvalIndex && x.approverEmail === item.approverEmail
      );
      return !existingApprover;
    });

    if (hasNewApprover || selectedApprovers.length !== invoiceApprovers?.length) {
      setEditingApprovers(true);
    } else {
      setEditingApprovers(false);
    }
  }, [selectedApprovers]);
  return (
    <>
      <Box
        sx={{
          borderRadius: '4px',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          backgroundColor: '#fff',
          border: 'solid 1px #c1c9d0',
        }}
      >
        <Box
          sx={{
            padding: '15px 25px',
          }}
        >
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: 'info.main' }}>Approvers</Typography>
            </Grid>
            {canUpdateApprovers && (
              <Grid item>
                <Grid container alignItems={'center'}>
                  <>
                    {(editingApprovers || isDeleting) && (
                      <Grid item>
                        <IconButton
                          color="secondary"
                          disabled={!canUpdateApprovers || isSubmitting}
                          onClick={handleCancel}
                        >
                          <Typography variant="subtitle2" component="span" sx={{ textDecoration: 'underline' }}>
                            Cancel
                          </Typography>
                        </IconButton>
                      </Grid>
                    )}
                    {editingApprovers && section !== 'manual' && (
                      <Grid item>
                        <Tooltip title={'Save approvers'}>
                          <IconButton
                            color="secondary"
                            loading={isSubmitting}
                            onClick={handleSaveApprovers}
                            disabled={!canUpdateApprovers || isSubmitting}
                          >
                            <Iconify icon={'material-symbols:save-outline'} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </>

                  {invoiceApprovers?.length > 0 && (
                    <>
                      {!isDeleting && (
                        <Grid item>
                          <Tooltip title="">
                            <IconButton
                              //   ref={trashRef}
                              color={'error'}
                              className="action-button"
                              disabled={!canUpdateApprovers || isSubmitting}
                              onClick={() => {
                                setIsDeleting(true);
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item>
                        <Tooltip title={'Add approvers'}>
                          <IconButton
                            color="secondary"
                            onClick={handleAddApprover}
                            disabled={!canUpdateApprovers || isSubmitting}
                          >
                            <Iconify icon={'eva:plus-outline'} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box sx={{ maxHeight: '400px', mx: 3, paddingBottom: '15px' }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {selectedApprovers?.map((approver, index) => (
              <Dnd
                key={index}
                itemId={approver.approverEmail}
                item={approver}
                moveItem={moveItem}
                sx={{ display: 'inline', cursor: 'move' }}
                disabled={!canUpdateApprovers}
              >
                <Grid key={index} item sx={{ marginBottom: '4px' }}>
                  <ChevronBox
                    reviewed={approver.status !== 1} //1=pending
                    sx={{ cursor: 'move !important' }}
                    color={colors[index % colors.length]}
                    reviewerIndex={index}
                    length={selectedApprovers.length}
                    children={
                      <>
                        <>
                          {isDeleting && (
                            <IconButton
                              onClick={() => {
                                handleDelete(approver);
                              }}
                              sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
                                borderRadius: '50%',
                                padding: '0',
                                color: colors[index % colors.length],
                                '&:hover': {
                                  backgroundColor: 'rgba(255, 0, 0, 0.2)', // Red hover effect
                                },
                              }}
                            >
                              <HighlightOffIcon sx={{ fontSize: 16, color: 'error' }} />
                            </IconButton>
                          )}
                        </>
                        <Tooltip title={approver.approverFullName}>
                          <Typography
                            onClick={() => {
                              handleCopyToClipboard(approver.approverEmail);
                            }}
                            sx={{
                              cursor: 'pointer !important',
                              maxWidth: {
                                xl: '90px', // No width restriction for laptop screens
                              },
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {approver.approverFullName}
                          </Typography>
                        </Tooltip>
                      </>
                    }
                  />
                </Grid>
              </Dnd>
            ))}
          </Grid>
          {(invoiceApprovers?.length === 0 || openList) && (
            <Grid item sx={{ mt: 'auto', ml: 'auto' }} ref={containerRef}>
              <FormControl
                variant="standard"
                sx={{
                  marginTop: 'auto',
                  zIndex: 10,
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  boxShadow: openList ? '0 3px 6px 0 rgba(0, 0, 0, 0.16)' : 0,
                }}
                fullWidth
              >
                <Autocomplete
                  variant="standard"
                  open={openList}
                  inputValue={value}
                  freeSolo
                  PopperComponent={(props) => {
                    const { style, ...other } = props;
                    return (
                      <div
                        ref={popperRef}
                        style={{
                          ...style,
                        }}
                        {...other}
                      />
                    );
                  }}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'input') setValue(newInputValue);
                  }}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                  onChange={(e, newValue) => {
                    setValue('');
                    handleChange(e, newValue);
                  }}
                  options={
                    invoiceViewers
                      ?.map((viewer) => ({
                        name: viewer.value,
                        value: viewer.id,
                        position: viewer.position,
                        approvalCount: viewer.approvalCount,
                      }))
                      ?.sort((a, b) => {
                        const aIsSelected = selectedApprovers.some((item) => item.approverEmail === a.value);
                        const bIsSelected = selectedApprovers.some((item) => item.approverEmail === b.value);
                        return bIsSelected - aIsSelected;
                      }) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={selectRef}
                      onFocus={() => {
                        if (requiredFieldsFilled) setOpenList(true);
                      }}
                      variant="standard"
                      placeholder={requiredFieldsFilled ? 'Add or select approvers' : 'Complete invoice info'}
                      sx={{
                        fontSize: '14px',
                        color: '#060606',
                      }}
                    />
                  )}
                  renderOption={(props, option) => {
                    const isSelected = selectedApprovers.some((item) => item.approverEmail === option.value);
                    return (
                      <MenuItem {...props} key={option.value} sx={{ borderBottom: '1px solid #ccc' }}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item>
                            <CustomAvatar
                              name={option.name}
                              showTooltip={false}
                              sx={{
                                backgroundColor: '#fff',
                                color: isSelected ? 'info.main' : 'text.secondary',
                                borderColor: isSelected ? 'info.main' : 'text.secondary',
                                height: '30px',
                                width: '30px',
                                fontSize: '0.75em',
                              }}
                            />
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <Typography
                              sx={{
                                fontSize: '0.85em',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: isSelected ? 'info.main' : 'text.secondary',
                              }}
                            >
                              {option.name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '0.75em',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: isSelected ? 'info.main' : 'text.secondary',
                              }}
                            >
                              {option.value}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '0.75em',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: isSelected ? 'info.main' : 'text.secondary',
                              }}
                            >
                              {option.position}
                            </Typography>
                          </Grid>{' '}
                          {option.approvalCount > 0 && (
                            <Grid item>
                              <Tooltip title="Frequent approver">
                                <Typography
                                  sx={{
                                    fontSize: '0.75em',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: isSelected ? 'info.main' : 'text.secondary',
                                  }}
                                >
                                  {' '}
                                  <Iconify icon={'mdi-light:clock'} />
                                </Typography>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </MenuItem>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
}

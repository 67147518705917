import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, CardContent, Grid, CssBaseline, Typography, Box, Autocomplete, TextField } from '@mui/material';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector } from '../../../../../redux/store';
import { fCurrency } from '../../../../../utils/formatNumber';
import { CHECKOUT_SINGLE_CART } from '../../../../../reusable-components/datagrid/cartColumns';
import SubmitOrder from './SubmitOrder';

export default function CheckoutView() {
  const { agoraCategoryId = '' } = useParams();

  const [cartItems, setCartItems] = useState([]);
  const [itemErrorCount, setItemErrorCount] = useState(0);
  const [personsArray, setPersonsArray] = useState([]);
  const [thePersonFor, setThePersonFor] = useState('');

  const {
    data: { carts, facility, personsFor, personFor },
  } = useSelector((state) => state.cart);
  useEffect(() => {
    setPersonsArray(personsFor ? personsFor.map((person, index) => ({ name: person, id: index })) : null);
  }, [personsFor]);

  useEffect(() => {
    setThePersonFor(personFor);
  }, [personFor]);

  useEffect(() => {
    let tempItemErrorCount = 0;
    const confirmItems = carts.flatMap((cart) => {
      if (cart.agoraCategoryId === Number(agoraCategoryId)) {
        const validItems = cart.items.filter((item) => item.quantity > 0);
        validItems.forEach((item) => {
          if (item.errors?.length > 0) {
            tempItemErrorCount++;
          }
        });
        return validItems;
      }
      return [];
    });

    setCartItems(confirmItems);
    setItemErrorCount(tempItemErrorCount);
  }, [agoraCategoryId, carts]);

  return (
    <>
      <CssBaseline />
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item sx={{ mx: 1, mb: 1 }}>
          <Typography variant="subtitle1" color="primary">
            {facility?.label}
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 1, mb: 1 }}>
          <Autocomplete
            sx={{
              width: '100%',
              minWidth: 250,
            }}
            freeSolo
            size="small"
            type="new-password"
            options={personsArray}
            getOptionLabel={(option) => option?.name || ''}
            forcePopupIcon
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setThePersonFor(newValue);
              } else {
                setThePersonFor(newValue?.name || '');
              }
            }}
            onInputChange={(event, newInputValue) => {
              setThePersonFor(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={thePersonFor || 'The person for'}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // Ensuring autocomplete is off
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={9.5}>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', height: 'calc(100vh - 205px)' }}>
              <CustomDataGrid
                dontGetRowHeight
                data={cartItems}
                gridColumns={CHECKOUT_SINGLE_CART}
                disableSelectionOnClick
                getEstimatedRowHeight={() => 44}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableColumnReorder
                pinnedColumns={{
                  left: ['picture'],
                  right: ['vendor', 'total', 'quantity', 'actions'],
                }}
                sx={{
                  '& .MuiDataGrid-cell': {
                    cursor: 'default !important',
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={2.5}>
          <CardContent>
            <Typography variant="body1">
              Subtotal ({cartItems.length} item{cartItems.length !== 1 ? 's' : ''}):{' '}
              <Typography variant="body1" component="span" fontWeight="bold">
                {fCurrency(cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0))}
              </Typography>
            </Typography>
            <SubmitOrder cartItems={cartItems} itemErrorCount={itemErrorCount} agoraCategoryId={agoraCategoryId} thePersonFor={thePersonFor} />
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
}

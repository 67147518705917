import { Helmet } from 'react-helmet-async';
import RentalCategoriesView from './RentalCategoriesView';

export default function RentalSettings() {
  return (
    <>
      <Helmet>
        <title>Rentals Settings | Platform Solutions</title>
      </Helmet>
      <RentalCategoriesView />
    </>
  );
}

import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem} from '@mui/material';
import { useSelector, useDispatch } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify/Iconify';
import { deleteInvoices } from '../../../../api/invoicing';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { getManualInvoicing } from '../../../../redux/slices/invoicing';

export default function ProcessManualInvoices({setShowSelection, showSelection}) {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: { manualInvoicing },
  } = useSelector((state) => state.invoicing);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDelete = async () => {
    const ids = JSON.parse(localStorage.getItem("selectedInvoices"));
    const response = await deleteInvoices(ids);
    if (response.status === 200) {
      enqueueSnackbar('Invoices deleted successfully', { variant: 'success' });
        localStorage.removeItem('selectedInvoices')
        dispatch(getManualInvoicing());
        setAnchorEl(null);
        setShowSelection('');
    }
  };

  const closeMenuDel = (e) => {
    setShowSelection(e?.target?.textContent || '');
    setAnchorEl(null);
  };

  const processInvoices = async () => {
    setAnchorEl(null);
    const nextId = JSON.parse(localStorage.getItem('selectedInvoices'))[0];
    navigate(`/dashboard/invoicing/new/${nextId}`);
    localStorage.setItem('selectedInvoices', JSON.stringify(JSON.parse(localStorage.getItem('selectedInvoices')).slice(1)))
    setShowSelection('');
    setAnchorEl(null);
  };

 const cancel = () => {
   localStorage.removeItem('selectedInvoices');
   setShowSelection('');
 }

  const processAllInvoices = () => {
    const nextId = manualInvoicing?.[0]?.id;
    navigate(`/dashboard/invoicing/new/${nextId}`);
    setShowSelection('');
    setAnchorEl(null);
  }

  return (
   <>
 {showSelection !== 'Select invoices' ? <> 
 <Button
    size="small"
    onClick={e => setAnchorEl(e.currentTarget)}
    variant = 'contained'
    color='secondary'
    sx={{ mr: 2 }}
    endIcon={<ArrowDropDownIcon />}
    >Process</Button>

  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
    <MenuItem onClick={closeMenuDel}>
      <Button size="small" color="secondary" variant="text" startIcon={<Iconify icon="ph:check-fill" />}>
        Select invoices
      </Button>
    </MenuItem>
    <MenuItem>

    <Button size="small" color="secondary" onClick = {processAllInvoices} variant="text" startIcon={<Iconify icon="material-symbols:all-match" />}>
      Process all invoices
    </Button>
    </MenuItem>
  </Menu>
</>

   : <>
    <Button variant="contained" size="small" sx={{mr: 1, pr:5, pl:5}} color="secondary" disabled={manualInvoicing?.length === 0} 
            onClick={() => { processInvoices(); }}>
        Process
    </Button>
    <Button variant="outlined" size="small" sx={{mr: 1}} color="secondary" disabled={manualInvoicing?.length === 0} 
            onClick={() => { handleDelete(); }}>
        Delete
    </Button>
    <Button  size="small" sx={{mr: 1, textDecoration: 'underline'}} color="secondary" onClick={cancel}>
      Cancel
    </Button>
    </>}
    </>
  );
}

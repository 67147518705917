import { IconButton } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

PdfPopupButton.propTypes = {
  url: PropTypes.string,
  tooltipText: PropTypes.string,
  icon: PropTypes.node,
  largeTooltip: PropTypes.bool,
  row: PropTypes.object
};

export default function PdfPopupButton({ url, icon, tooltipText, row, largeTooltip = false })
{
  return largeTooltip ? <NoMaxWidthTooltip placement = {'left'} title={tooltipText} arrow >
    {row?.mainFileNumPages !== 0 && <div style = {{color: 'rgb(241,158,59,1)', margin: '0px', padding: '0px', float:'right'}}>{row.mainFileNumPages}</div>}
    <IconButton
      color="warning"
      sx = {{paddingRight: '0px', marginRight: '0px'}} 
      onClick={() => {
        const modal = document.createElement('div');
        modal.style.position = 'fixed';
        modal.style.zIndex = '100000';
        modal.style.left = '0';
        modal.style.bottom = '0';
        modal.style.width = '100%';
        modal.style.height = '100%';
        modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        modal.style.display = 'flex';
        modal.style.justifyContent = 'center';
        modal.style.alignItems = 'center';

        const iframe = document.createElement('iframe');
        iframe.style.width = '70%';
        iframe.style.height = '100%';
        iframe.src = url;

        modal.appendChild(iframe);

        modal.addEventListener('click', (e) => {
          if (e.target === modal) {
            document.body.removeChild(modal);
          }
        });
        document.body.appendChild(modal);
      }}
    >
      {icon}
    </IconButton>
  </NoMaxWidthTooltip> : 
  <Tooltip title={tooltipText} arrow >
    <IconButton
      color="warning"
      onClick={() => {
        const modal = document.createElement('div');
        modal.style.position = 'fixed';
        modal.style.zIndex = '100000';
        modal.style.left = '0';
        modal.style.bottom = '0';
        modal.style.width = '100%';
        modal.style.height = '100%';
        modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        modal.style.display = 'flex';
        modal.style.justifyContent = 'center';
        modal.style.alignItems = 'center';

        const iframe = document.createElement('iframe');
        iframe.style.width = '70%';
        iframe.style.height = '100%';
        iframe.src = url;

        modal.appendChild(iframe);

        modal.addEventListener('click', (e) => {
          if (e.target === modal) {
            document.body.removeChild(modal);
          }
        });
        document.body.appendChild(modal);
      }}
    >
      {icon}
    </IconButton>
  </Tooltip>
}

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    maxHeight: 'none',
    height: '80vh', 
    width:'50vw'
  },
});

import { useMemo, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import { useSelector, useDispatch } from '../../../redux/store';
import Iconify from '../../../reusable-components/iconify';
import { updateUserSiteId } from '../../../redux/slices/user';
import { useTabs } from '../../../context/TabContext';

export default function SiteSwitch() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { siteId, sites } = user;
  const { closeAllTabs } = useTabs();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSiteSwitch = async (siteId) => {
    await dispatch(updateUserSiteId(siteId, closeAllTabs));
  };

  const inAlphabeticalOrderSites = useMemo(() => {
    // Create a shallow copy of the sites array and then sort that copy
    const sortedSites = sites ? [...sites].sort((a, b) => a?.label.localeCompare(b?.label)) : [];
    return sortedSites;
  }, [sites]);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="body2" sx={{ fontWeight: 'bold', cursor: 'pointer', color: '#000' }} onClick={handleClick}>
          {sites?.filter((site) => site?.value === siteId)?.[0]?.label}
          {sites?.length > 1 && (
            <Iconify
              sx={{ ml: 0.5, mt: 0.5 }}
              icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'}
              width={16}
              height={16}
            />
          )}
        </Typography>
      </Grid>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {inAlphabeticalOrderSites?.map((site) => (
          <MenuItem
            key={site?.value}
            onClick={() => {
              handleSiteSwitch(site?.value);
            }}
            disableRipple
          >
            <span style={{ marginLeft: 8, marginRight: 8 }}>
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {site?.label}
              </Typography>
            </span>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

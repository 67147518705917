import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// ExcelUpload;

// GET /ExcelUpload/ExcelTypes;
export async function getExcelTypes() {
  const response = await axios.get('/ExcelUpload/ExcelTypes');
  return response;
}

// GET /ExcelUpload/ExcelTypeData; id
export async function getExcelTypeData(id) {
  const response = await axios.get(`/ExcelUpload/ExcelTypeData?id=${id}`);
  return response;
}

// POST ExcelUpload/ItemLookup; VendorId AttachVendorIds=array[integer] ExcelUploadInputs=array[object] IgnoreRows=array[integer] SheetName=string; TestRun=boolean; File=string($binary)
export async function itemLookup({
  vendorId,
  attachVendorIds,
  excelUploadInputs,
  ignoreRows,
  sheetName,
  testRun,
  file,
}) {
  const formData = objectToFormData({
    vendorId,
    attachVendorIds,
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/ItemLookup', formData, {
    responseType: 'blob',
  });
  return response;
}

// POST ExcelUpload/SiteItemUpload; SiteId ExcelUploadInputs=array[object] IgnoreRows=array[integer] SheetName=string; TestRun=boolean; File=string($binary)
export async function siteItemUpload({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file }) {
  const formData = objectToFormData({
    siteId,
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/SiteItemUpload', formData, {
    responseType: 'blob',
  });
  return response;
}

// POST ExcelUpload/PriceUpdate; VendorId SiteId ExcelUploadInputs=array[object] IgnoreRows=array[integer] SheetName=string; TestRun=boolean; File=string($binary)
export async function priceUpdate({ vendorId, siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file }) {
  const formData = objectToFormData({
    vendorId,
    siteId,
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/PriceUpdate', formData, {
    responseType: 'blob',
  });
  return response;
}

// POST ExcelUpload/WarehouseItemUpload;  VendorId  ExcelUploadInputs=array[object] IgnoreRows=array[integer] SheetName=string; TestRun=boolean; File=string($binary)
export async function warehouseItemUpload({ vendorId, excelUploadInputs, ignoreRows, sheetName, testRun, file }) {
  const formData = objectToFormData({
    vendorId,
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/WarehouseItemUpload', formData, {
    responseType: 'blob',
  });
  return response;
}

// POST ExcelUpload/UploadUsers; SiteId ExcelUploadInputs=array[object] IgnoreRows=array[integer] SheetName=string; TestRun=boolean; File=string($binary)
export async function uploadUsers({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file }) {
  const formData = objectToFormData({
    siteId,
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/UploadUsers', formData, {
    responseType: 'blob',
  });
  return response;
}

// POST ExcelUpload/FacilityBudgetsUpload; SiteId ExcelUploadInputs=array[object] IgnoreRows=array[integer] SheetName=string; TestRun=boolean; File=string($binary)
export async function facilityBudgetsUpload({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file }) {
  const formData = objectToFormData({
    siteId,
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/FacilityBudgetsUpload', formData, {
    responseType: 'blob',
  });
  return response;
}

// POST ExcelUpload/VendorDatesUpload; SiteId ExcelUploadInputs=array[object] IgnoreRows=array[integer] SheetName=string; TestRun=boolean; File=string($binary)
export async function vendorDatesUpload({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file }) {
  const formData = objectToFormData({
    siteId,
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/VendorDatesUpload', formData, {
    responseType: 'blob',
  });
  return response;
}

// POST ExcelUpload/VendorAccountsUpload; SiteId ExcelUploadInputs=array[object] IgnoreRows=array[integer] SheetName=string; TestRun=boolean; File=string($binary)
export async function vendorAccountsUpload({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file }) {
  const formData = objectToFormData({
    siteId,
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/VendorAccountsUpload', formData, {
    responseType: 'blob',
  });
  return response;
}

// POST ExcelUpload/SiteItemUploadWithPSNumber; SiteId ExcelUploadInputs=array[object] IgnoreRows=array[integer] SheetName=string; TestRun=boolean; File=string($binary)
export async function siteItemUploadWithPSNumber({ siteId, excelUploadInputs, ignoreRows, sheetName, testRun, file }) {
  const formData = objectToFormData({
    siteId,
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/SiteItemUploadWithPSNumber', formData, {
    responseType: 'blob',
  });
  return response;
}

export async function orderGuideBulkSubmit({ excelUploadInputs, ignoreRows, sheetName, testRun, file }) {
  const formData = objectToFormData({
    excelUploadInputs,
    ignoreRows,
    sheetName,
    testRun,
    file,
  });
  const response = await axios.post('/ExcelUpload/OrderGuideBulkSubmit', formData, {
    responseType: 'blob',
  });
  return response;
}

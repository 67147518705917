import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
//Api/Rental/CompaniesAndFacilities
export const getCompaniesAndFacilities = async () => {
  const response = await axios.get('Rental/CompaniesAndFacilities');
  return response.data;
};

// GET
//Api/Rental/FacilityAccounts
export const getFacilityAccounts = async () => {
  const response = await axios.get('Rental/FacilityAccounts');
  return response.data;
};

// GET
//Api/Rental/Categories
export const getCategories = async () => {
  const response = await axios.get('Rental/Categories');
  return response.data;
};

// GET
//Api/Rental/Residents facilityId
export const getResidents = async (facilityId) => {
  const response = await axios.get(`Rental/Residents?facilityId=${facilityId}`);
  return response;
};

// GET
//Api/Rental/FacilityVendors facilityId
export const getFacilityVendorsForFacility = async (facilityId) => {
  const response = await axios.get(`Rental/FacilityVendors?facilityId=${facilityId}`);
  return response;
};

// GET
//Api/Rental/Carts
export const getCarts = async () => {
  const response = await axios.get('Rental/Carts');
  return response;
};

// GET
//Api/Rental/facilityInfo facilityId
export const getFacilityInfo = async (facilityId) => {
  const response = await axios.get(`Rental/FacilityInfo?facilityId=${facilityId}`);
  return response;
};

// POST
//Api/Rental/CreateResident form
export async function postCreateResident(form, formFacilityId) {
  const updatedForm = {
    facilityId: formFacilityId,
    ...form,
  };

  const formData = objectToFormData(updatedForm);

  const response = await axios.post('Rental/CreateResident', formData);
  return response;
}

// GET
//Api/Rental/Items
export const getItems = async () => {
  const response = await axios.get('Rental/Items');
  return response;
};

// GET
//Api/Rental/Vendors
export const getVendors = async () => {
  const response = await axios.get(`Rental/Vendors`);
  return response;
};

// POST
//Api/AddToCart (when cart already exists) items array
export async function postAddToCart(itemId, cartId, userId) {
  const formData = objectToFormData({
    itemId,
    cartId,
    userId,
  });
  const response = await axios.post('Rental/AddToCart', formData);
  return response;
}

// POST
//Api/AddToCart (create new cart) items array
export async function postAddNewCart(residentId, userId) {
  const formData = objectToFormData({
    residentId,
    userId,
  });
  const response = await axios.post('Rental/AddNewCart', formData);
  return response;
}

// POST
//Api/Rental/RemoveFromCart cartItemId, deleteAll
export async function postRemoveCartItem(cartItemId, deleteAll) {
  const response = await axios.post(`Rental/RemoveCartItem?cartItemId=${cartItemId}&deleteAll=${deleteAll}`);
  return response;
}

//POST
//Api/Rental/postSubmitOrderApi items
export async function postSubmitOrderApi(items) {
  const formData = new FormData();
  const updateItems = items?.map((item) => ({
    cartItemId: item.cartItemId,
    isSelected: item.isSelected,
  }));
  updateItems.forEach((item, index) => {
    Object.entries(item).forEach(([key, value]) => {
      formData.append(`${'orderItems'}[${index}].${key}`, value !== null && value !== '' ? value : '');
    });
  });

  const response = await axios.post('Rental/SubmitOrder', formData);
  return response;
}
// GET
// /Api/Rental/RentalOrders
export const getRentalOrders = async (orderStatus) => {
  const response = await axios.get(`Rental/RentalOrders?orderStatus=${orderStatus}`);
  return response;
};

// POST
//Api/Rental/UpdateOrderStatus orderId, updatedStatus
export async function postUpdateOrderStatus(orderId, updatedStatus) {
  const response = await axios.post(`Rental/UpdateOrderStatus?orderId=${orderId}&updatedStatus=${updatedStatus}`);
  return response;
}

// POST
// /Api/Rental/setAsPreferredVendor id isPreferred
export async function setAsPreferredVendor(id, isPreferred) {
  const response = await axios.post(`Rental/UpdatePreferredVendor?id=${id}&isPreferred=${isPreferred}`);
  return response;
}

// GET
// /Api/Rental/getIsAfterHours
export const getIsAfterHours = async () => {
  const response = await axios.get('Rental/IsAfterHours');
  return response;
};

// POST
// /Api/Rental/postIsApproveEnabled facilityId
export async function postIsApproveEnabled(facilityId) {
  const response = await axios.post(`Rental/CheckApprovePermission?facilityId=${facilityId}`);
  return response;
}

// POST
// /Api/Rental/postAddCategory name icon
export async function postAddCategory(name, icon) {
  const formData = objectToFormData({
    name,
    icon,
  });

  const response = await axios.post('Rental/AddCategory', formData);
  return response;
}

// POST
// /Api/Rental/postAddSubcategory subcategoryName, categoryId
export async function postAddSubcategory(subcategoryName, categoryId) {
  const response = await axios.post(
    `Rental/AddSubcategory?subcategoryName=${subcategoryName}&categoryId=${categoryId}`
  );
  return response;
}

// POST
// Api/Rental/postDeleteSubcategory; id substituteSubcategoryId substituteCategoryId
export const postDeleteSubcategory = async ({ id, substituteSubcategoryId, substituteCategoryId }) => {
  const formData = objectToFormData({
    id,
    substituteSubcategoryId,
    substituteCategoryId,
  });
  const response = await axios.post('Rental/DeleteSubcategory', formData);
  return response;
};

// GET
//Api/Rental/PricingTypes
export const getPricingTypes = async () => {
  const response = await axios.get(`Rental/PricingTypes`);
  return response;
};

//POST
//Api/Rental/postAddItem item
export const postAddItem = async (item) => {
  const formData = objectToFormData({
    description: item.description,
    categoryId: item.category.id,
    subcategoryId: item.subcategory.subcategoryId,
    vendorId: item.vendor.id,
    vendorItemId: item.vendorItemID,
    price: item.price,
    pricingTypeId: item.pricingType.id,
    image: item.imageUrl,
    uomAmount: item.uomAmount,
    uomType: item.uomType,
    capAmount: item.capAmount,
    vendorUrl: item.vendorItemLink,
  });

  const response = await axios.post('Rental/AddItem', formData);
  return response;
};

import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Tooltip, Grid, TextField, Button } from '@mui/material';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { submitForReview, getManualInvoicing, patchInvoiceItems } from '../../../../../redux/slices/invoicing';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import Iconify from '../../../../../reusable-components/iconify';

SubmitForReview.propTypes = {
  unsavedData: PropTypes.object,
  setUnsavedData: PropTypes.func,
  glRows: PropTypes.any,
};
export default function SubmitForReview({ unsavedData, setUnsavedData, glRows }) {
  const { id } = useParams();
  const {
    data: { manualInvoicing, invoiceItemData },
  } = useSelector((state) => state.invoicing);

  const { invoice } = invoiceItemData || {};

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const requiredFieldsFilled =
    invoice?.facilityId > 0 &&
    invoice?.vendorId > 0 &&
    invoice?.vendorInvoiceId !== null &&
    invoice?.glPostingDate &&
    glRows?.length > 0;

  const submit = async () => {
    await dispatch(submitForReview(invoice.id));
    const res = await dispatch(getManualInvoicing());
    if (res.data?.length > 0) {
      const nextId = res.data?.[0]?.id;
      navigate(`/dashboard/invoicing/manual/${nextId}`);
    } else {
      const pathSegments = location.pathname.split('/');
      const url = pathSegments.slice(0, -1).join('/');
      navigate(url);
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    if (Object.values(unsavedData)?.length === 0 && isSubmitting) {
      submit();
    }
  }, [unsavedData, isSubmitting]);
  const save = async () => {
    if (Object.values(unsavedData)?.length > 0) {
      await dispatch(patchInvoiceItems(invoice.id, Object.values(unsavedData)));
      setUnsavedData({});
    }
    setIsSubmitting(true);
  };

  return (
    <>
      <Tooltip
        title={
          Object.values(unsavedData)?.length > 0
            ? 'Unsaved Changes'
            : !requiredFieldsFilled
            ? 'Complete invoice info - Required fields + Items'
            : ''
        }
        placement="top"
        arrow
      >
        <Box>
          <AwaitButton
            variant="contained"
            color="secondary"
            onClick={save}
            loading={isSubmitting}
            loadingPosition="start"
            disabled={Object.values(unsavedData)?.length > 0 || !requiredFieldsFilled}
            startIcon={<Iconify icon="material-symbols:rate-review-outline" />}
          >
            Submit for review
          </AwaitButton>
        </Box>
      </Tooltip>
    </>
  );
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, DialogContentText, Autocomplete, TextField, Chip, IconButton, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { DateRangePickerSelect, MultiDateRange } from '../../../reusable-components/date-range-picker';
import SimpleAutocompleteProps from '../../../reusable-components/autocomplete/SimpleAutocompleteProps';
import SpacedRow from '../../../reusable-components/spaced-row/SpacedRow';

ReportSelectSpecificationsStep.propTypes = {
  reportSpecificationOptions: PropTypes.array,
  setFormValues: PropTypes.func,
  formValues: PropTypes.object,
  createReportData: PropTypes.object,
  setShowDatePicker: PropTypes.func,
  showDatePicker: PropTypes.bool,
};

export default function ReportSelectSpecificationsStep({ reportSpecificationOptions, createReportData, formValues, setFormValues, showDatePicker, setShowDatePicker  })
{
  const [showAllVendors, setShowAllVendors] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showAllSubCategories, setShowAllSubCategories] = useState(false);
  const [showAllFacilities, setShowAllFacilities] = useState(false);
  const [showAllGroups, setShowAllGroups] = useState(false);

  const renderTags = (value, getTagProps, showAll, setShowAll) => {
    const displayedValues = showAll ? value : value.slice(0, 3);
    return (
      <>
        {displayedValues.map((option, index) => (
          <Chip
            key={option.value}
            label={option.label}
            {...getTagProps({ index })}
            style={{ marginRight: 4 }}
          />
        ))}
        {value.length > 3 && (
          <IconButton
            onClick={() => { setShowAll(!showAll) }}
            size="small"
            style={{ marginLeft: 4 }}
          >
            {showAll ? <ExpandLessIcon /> : <> + {value.length - 3}<ExpandMoreIcon /></>}
          </IconButton>
        )}
      </>
    );
  };

  const skipOption = reportSpecificationOptions.find(o => o.name === 'Skip');
  const takeOption = reportSpecificationOptions.find(o => o.name === 'Take');
  const dateOption = reportSpecificationOptions.find(o => o.name === 'DateFilter');
  const companiesOption = reportSpecificationOptions.find(o => o.name === 'Companies');
  const facilityOption = reportSpecificationOptions.find(o => o.name === 'Facilities');
  const vendorOption = reportSpecificationOptions.find(o => o.name === 'Vendors');
  const categoriesOption = reportSpecificationOptions.find(o => o.name === 'Categories');
  const subcategoriesOption = reportSpecificationOptions.find(o => o.name === 'Subcategories');

  const numberInputDefaults = { type: "number", size: "small" };

  const autoCompleteDefaults = (opt, options, values, showAllTags, setShowAllTags, onChange) => ({
    ...SimpleAutocompleteProps(),
    multiple: opt.multiselect,
    disableCloseOnSelect: opt.multiselect,
    sx: { mt: 1 },
    options: opt.multiselect ? [{ label: "Select All", value: 'All' }, ...(options || [])] : options || [],
    autoHighlight: true,
    value: opt.multiselect ? values || [] : values && values.length > 0 ? values[0] : null,
    getOptionKey: (opt) => opt?.value,
    onChange: (event, newValue) => {
      const newValueArray = newValue ? !opt.multiselect ? [newValue] : newValue : [];
      onChange(newValueArray.some((option) => option.value === "All") ? newValueArray.length + 1 === options?.length ? [] : options : newValueArray);
    },
    renderTags: (value, getTagProps) => renderTags(value, getTagProps, showAllTags, setShowAllTags)
  });

  return (
    <>
      <DialogTitle sx={{ textAlign: 'center', color: 'rgba(112, 112, 112, 0.5)' }}>
        Report Options
      </DialogTitle>
      {dateOption && !dateOption.multiselect && <>
        <DialogContentText sx={{ fontWeight: 'bold', mb: -1 }}>Date:</DialogContentText>
        <DateRangePickerSelect noStickyFilter buttonSx={{ paddingTop: "15px !important", paddingBottom: "15px !important", marginTop: "15px !important" }}
          datePickerSx={{ paddingTop: "5px !important", paddingBottom: "5px !important" }}
          value={[formValues.startDate, formValues.endDate]}
          setValue={(start, end) => setFormValues({ ...formValues, startDate: start, endDate: end })} />
      </>}
      {dateOption?.multiselect && <MultiDateRange datesValue={formValues?.dateValues} setDatesValue={(dateValues) => setFormValues({ ...formValues, dateValues })}
        showFullDatePicker={showDatePicker} setShowFullDatePicker={setShowDatePicker} />}
      {takeOption && skipOption && <SpacedRow>
        <Box>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Check this amount of invoices:</DialogContentText>
          <TextField {...numberInputDefaults}
            fullWidth
            value={formValues.take || 100}
            onChange={(e) => setFormValues({ ...formValues, take: e.target.value })}
          />
        </Box>
        <Box>
          <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Skip this amount of invoices:</DialogContentText>
          <TextField {...numberInputDefaults}
            fullWidth
            value={formValues.skip || 0}
            onChange={(e) => setFormValues({ ...formValues, skip: e.target.value })}
          />
        </Box>
      </SpacedRow>}
      {companiesOption && <>
        <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>{companiesOption.multiselect ? "Groups:" : "Group"}</DialogContentText>
        <Autocomplete
          {...autoCompleteDefaults(companiesOption, createReportData.companies, formValues.companies, showAllGroups, setShowAllGroups,
            (newValues) => setFormValues({
              ...formValues,
              companies: newValues,
              facilities: createReportData.facilities.filter((facility) => newValues.some(v => v.value === facility.companyId))
            }))}
        />
      </>}
      {facilityOption && <>
        <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>{facilityOption.multiselect ? "Facilities" : "Facility"} </DialogContentText>
        <Autocomplete
          {...autoCompleteDefaults(facilityOption, createReportData.facilities, formValues.facilities, showAllFacilities, setShowAllFacilities,
            (newValues) => setFormValues({ ...formValues, facilities: newValues }))}      
        />
      </>}
      {vendorOption && <>
        <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>{vendorOption.multiselect ? "Vendors:" : "Vendor"}</DialogContentText>
        <Autocomplete
          {...autoCompleteDefaults(vendorOption, createReportData.vendors, formValues.vendors, showAllVendors, setShowAllVendors,
            (newValues) => setFormValues({ ...formValues, vendors: newValues }))}  
        />
      </>}
      {categoriesOption && <>
        <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>{categoriesOption.multiselect ? "Categories" : "Category"}</DialogContentText>
        <Autocomplete
          {...autoCompleteDefaults(categoriesOption, createReportData.categories, formValues.categories, showAllCategories, setShowAllCategories,
            (newValues) => setFormValues({
              ...formValues,
              categories: newValues,
              subcategories: createReportData.subcategories.filter((s) => newValues.some(v => v.id === s.mainCategoryId))
            }))}         
        />
      </>}
      {subcategoriesOption && <>
        <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>{subcategoriesOption.multiselect ? "Subcategories:" : "Subcategory:"}</DialogContentText>
        <Autocomplete
          {...autoCompleteDefaults(categoriesOption, createReportData.subcategories, formValues.subcategories, showAllSubCategories, setShowAllSubCategories,
            (newValues) => setFormValues({
              ...formValues,
              subcategories: newValues
            }))}
        />
      </>}
    </>
  );
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid, Tooltip, Menu, Card, LinearProgress, Divider } from '@mui/material';
import { fCurrency, fPercent } from '../../../../utils/formatNumber';
import Iconify from '../../../../reusable-components/iconify';
import { BudgetBar } from './index';

SubcategoryBudget.propTypes = {
  subbudgets: PropTypes.array,
  cart: PropTypes.array,
  censusAmount: PropTypes.number,
  tab: PropTypes.number,
  daysLength: PropTypes.number,
  monthDaysLength: PropTypes.number,
  facId: PropTypes.any,
  includeUnapproved: PropTypes.bool,
  budgetDate: PropTypes.any,
};

export default function SubcategoryBudget({
  subbudgets,
  cart,
  censusAmount,
  tab,
  daysLength,
  monthDaysLength,
  facId,
  includeUnapproved,
  budgetDate,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [budgetOpen, setBudgetOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [allExpanded, setAllExpanded] = useState(false);
  const [expanded, setExpanded] = useState(
    subbudgets.reduce((acc, subbudget, index) => {
      acc[index] = false;
      return acc;
    }, {})
  );
  const setAllSubcategoriesExpanded = () => {
    setExpanded((prev) => {
      const newState = {};
      Object.keys(prev).forEach((key) => {
        newState[key] = !allExpanded;
      });
      return newState;
    });
    // Toggle allExpanded state
    setAllExpanded(!allExpanded);
  };
  const handleChange = (index) => () => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  const sortedSubbudgets = [...subbudgets].sort((a, b) => b.weekBudgetAmount - a.weekBudgetAmount);

  const determineColor = (value) => {
    if (value > 76) {
      return 'error';
    }
    if (value > 50) {
      return 'warning';
    }
    return 'info';
  };

  if (!subbudgets) return null;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          my: 1,
        }}
      >
        <Grid item>
          {open && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={0.5}
              sx={{
                textDecoration: 'underline',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={setAllSubcategoriesExpanded}
            >
              <Typography variant="subtitle2">{allExpanded ? 'Close' : 'Expand'} all subcategories</Typography>
              <Iconify icon={allExpanded ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={0.5}
            sx={{
              textDecoration: 'underline',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => setOpen(!open)}
          >
            <Typography variant="subtitle2">Subcategories</Typography>
            <Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
          </Grid>
        </Grid>
      </Grid>
      {open &&
        sortedSubbudgets.map((subbudget, index) => {
          const {
            category,
            connectedOrders,
            excludeFromMain,
            ppdAmount,
            items,
            monthBudgetAmount,
            monthSpent,
            weekBudgetAmount,
            weekSpent,
            agoraSubcategoryId,
            agoraCategoryId,
          } = subbudget;
          const cartTotalAmount = cart
            ?.filter((item) => item?.removedFromBudget !== true && (item.agoraSubcategory === category || item.agoraSubcategory?.name === category))
            .map((item) => {
              if (Array.isArray(item?.budgetSourceData) && item.budgetSourceData.length > 0) {
                return item.budgetSourceData.reduce((sum, source, index) => {
                  const price = item.budgetPrice[index] || 0;
                  const quantity = item.budgetQty[index] || 0;
                  const tax = item.budgetTax[index] || 0;
                  const shipping = item.budgetShipping[index] || 0;
                  return sum + (price * quantity + tax + shipping);
                }, 0);
              }
              return item.price * item.quantity;
            }).reduce((a, b) => a + b, 0);

          const budget = tab === 1 ? weekBudgetAmount : monthBudgetAmount || 0;
          const total = (tab === 1 ? weekSpent : monthSpent) + (cartTotalAmount || 0);
          const history = tab === 1 ? weekSpent : monthSpent || 0;

          return (
            <Box
              sx={{
                flexGrow: 1,
                position: 'relative',
                mb: 1,

                border: expanded[index] ? '1px solid #ddeec6' : 'none',
                padding: expanded[index] ? 1 : 0,
              }}
              key={index}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={handleChange(index)}
              >
                <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
                  {category}
                </Typography>
                <Iconify icon={expanded[index] ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
              </Grid>
              {expanded[index] ? (
                <>
                  <Grid container sx={{ mb: 1 }}>
                    <Grid item xs={4}>
                      <Tooltip title="Budget">
                        <ExcelBox
                          sx={{
                            borderRight: 'none',
                            '&:hover': {
                              cursor: 'pointer',
                            },
                            borderRadius: '4px 0 0 4px',
                          }}
                          onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                            setBudgetOpen(true);
                          }}
                        >
                          <Grid container direction="row" justifyContent="space-around" alignItems="center">
                            <Iconify icon="noto:money-bag" width={12} height={12} />

                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: '10px',
                              }}
                            >
                              {fCurrency(budget)}
                            </Typography>
                            <Iconify icon={budgetOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
                          </Grid>{' '}
                        </ExcelBox>
                      </Tooltip>
                    </Grid>
                    <Menu
                      open={budgetOpen}
                      onClose={() => {
                        setAnchorEl(null);
                        setBudgetOpen(false);
                      }}
                      anchorEl={anchorEl}
                      sx={{
                        '& .MuiList-root': {
                          paddingTop: 0,
                          paddingBottom: 0,
                        },
                      }}
                    >
                      <Card
                        sx={{
                          padding: 1,
                        }}
                      >
                        <Typography color="secondary">{` Census = ${censusAmount}`}</Typography>
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.info.main,
                          }}
                        >{` PPD = ${fCurrency(ppdAmount)}`}</Typography>
                        <Typography color="primary">{` ${tab === 1 ? 'Week' : 'Month'} = ${
                          tab === 1 ? daysLength : monthDaysLength
                        } days`}</Typography>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        <Typography color="secondary" display="inline">
                          Census
                        </Typography>
                        <Typography display="inline"> X </Typography>
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.info.main,
                          }}
                          display="inline"
                        >
                          PPD
                        </Typography>
                        <Typography display="inline"> X </Typography>
                        <Typography color="primary" display="inline">
                          {tab === 1 ? 'Week' : 'Month'}
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          = {tab === 1 ? fCurrency(weekBudgetAmount) : fCurrency(monthBudgetAmount)}
                        </Typography>
                      </Card>
                    </Menu>

                    <Grid item xs={4}>
                      <Tooltip title="Remaining">
                        <ExcelBox>
                          <Grid container direction="row" justifyContent="space-around" alignItems="flex-top">
                            <Iconify icon="fluent-emoji:dollar-banknote" width={13} height={13} />
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: '10px',
                              }}
                              color={budget - total < 0 ? 'error' : 'default'}
                            >
                              {fCurrency(budget - total)}
                            </Typography>
                          </Grid>
                        </ExcelBox>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <ExcelBox
                        sx={{
                          borderLeft: 'none',
                          borderRadius: '0 4px 4px 0',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: '10px',
                          }}
                        >
                          PPD: {fCurrency(ppdAmount)}
                        </Typography>
                      </ExcelBox>
                    </Grid>
                  </Grid>
                  <BudgetBar
                    budget={budget}
                    total={total}
                    history={history}
                    catId={agoraCategoryId}
                    subcategoryId={agoraSubcategoryId}
                    facId={facId}
                    includeUnapproved={includeUnapproved}
                    budgetDate={budgetDate}
                  />
                </>
              ) : (
                <>
                  <StyledBox onClick={handleChange(index)}>
                    <Typography
                      variant="subtitle2"
                      component="span"
                      sx={{ color: total > budget ? 'white' : 'inherit' }}
                    >
                      {budget > 0
                        ? total - budget > 0
                          ? `Total ${fCurrency(total)} (${fPercent(Math.floor((total / budget) * 100))}) | ${fCurrency(
                              total - budget
                            )} over budget`
                          : total === 0
                          ? 'Total $0.00 (0%)'
                          : `Total ${fCurrency(total)} (${fPercent(Math.floor((total / budget) * 100))})`
                        : 'No budget set'}
                    </Typography>
                  </StyledBox>

                  <StyledLinearProgress
                    variant="determinate"
                    value={(total / budget) * 100 > 100 ? 100 : (total / budget) * 100 || 0}
                    color={determineColor((total / budget) * 100)}
                  />
                </>
              )}
            </Box>
          );
        })}
    </>
  );
}
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: '4px',
  height: 23,
  border: `1px solid ${theme.palette.secondary.light}`,
  backgroundColor: '#fff',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiLinearProgress-barColorInfo': {
    backgroundColor: theme.palette.info.main,
  },
  '& .MuiLinearProgress-barColorError': {
    backgroundColor: theme.palette.error.main,
  },
  '& .MuiLinearProgress-barColorWarning': {
    backgroundColor: theme.palette.warning.main,
  },
}));

const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
  maxHeight: 23, // borderlinearprogress root.height
  textAlign: 'right',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '8px',
  '& span': {
    width: '100%',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  borderRadius: '4px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
}));
const ExcelBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 23,
  border: `2px solid ${theme.palette.secondary.light}`,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
}));

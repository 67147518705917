import { Box, Stack, Typography, Autocomplete, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ExcelUploadInput from './ExcelUploadInput';
import SimpleAutocompleteProps from '../../../../reusable-components/autocomplete/SimpleAutocompleteProps';
import SpacedRow from '../../../../reusable-components/spaced-row/SpacedRow';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';

OrderGuideExcelUpload.propTypes = {
  excelUploadData: PropTypes.object,
  setExcelUploadData: PropTypes.func
}

export default function OrderGuideExcelUpload({ excelUploadData, setExcelUploadData })
{
  const [sheets, setSheets] = useState(null);
  const [headerRow, setHeaderRow] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (excelUploadData?.facilityCol && excelUploadData.categoryCol && excelUploadData.psProductIdCol) {    
      setTableData(Object.values(excelData.map(row => ({
        facility: row[excelUploadData.facilityCol],
        category: row[excelUploadData.categoryCol],
        item: row[excelUploadData.psProductIdCol]
      })).filter(row => row.facility && row.category && row.item).reduce((accumulator, currentValue) => {
        const key = `${currentValue.facility}-${currentValue.category}`;
        accumulator[key] = accumulator[key] ?? { facility: currentValue.facility, category: currentValue.category };
        accumulator[key].items = [...(accumulator[key].items ?? []), currentValue.item];
        accumulator[key].id = key;
        return accumulator;
      }, {})).map(row => ({ ...row, itemCount: new Set(row.items).size })));
    }
  }, [excelUploadData, excelData]);

  const columns = [
    {
      field: "facility",
      headerName: 'Facility',
      minWidth: 100,
      flex: 1
    },
    {
      field: "category",
      headerName: 'Category',
      minWidth: 100,
      flex: 1
    },
    {
      field: "itemCount",
      headerName: 'Item Count',
      minWidth: 100,
      flex: 1
    },
  ];

  return <Stack sx={{ mt: 2 }} spacing={2}>
    <ExcelUploadInput
      setSheets={setSheets}
      setFile={(file) => setExcelUploadData(data => ({ ...(data ?? {}), file }))}
      setSelectedSheet={(sheet) => setExcelUploadData(data => ({ ...(data ?? {}), sheet }))}
      setHeaderRow={setHeaderRow}
      setExcelData={setExcelData}
      selectedSheet={excelUploadData?.sheet}
    />
    <Box>
      <Typography>Sheet</Typography>
      <Autocomplete
        fullWidth
        value={excelUploadData?.sheet ? { value: excelUploadData.sheet, label: excelUploadData.sheet } : null}
        onChange={(event, newValue) => setExcelUploadData(data => ({ ...(data ?? {}), sheet: newValue?.value }))}
        options={sheets?.map(sheet => ({ value: sheet, label: sheet })) ?? []}
        {...SimpleAutocompleteProps()}
      />
    </Box>
    <Grid container>
      <SpacedRow>
        <Box>
          <Typography>Facility</Typography>
          <Autocomplete
            fullWidth
            value={excelUploadData?.facilityCol ? headerRow.find(cell => cell.value === excelUploadData.facilityCol) : null}
            onChange={(event, newValue) => setExcelUploadData(data => ({ ...(data ?? {}), facilityCol: newValue?.value }))}
            options={headerRow}
            {...SimpleAutocompleteProps()}
          />
        </Box>
        <Box>
          <Typography>Category</Typography>
          <Autocomplete
            fullWidth
            value={excelUploadData?.categoryCol ? headerRow.find(cell => cell.value === excelUploadData.categoryCol) : null}
            onChange={(event, newValue) => setExcelUploadData(data => ({ ...(data ?? {}), categoryCol: newValue?.value }))}
            options={headerRow}
            {...SimpleAutocompleteProps()}
          />
        </Box>
        <Box>
          <Typography>PS Product Id</Typography>
          <Autocomplete
            fullWidth
            value={excelUploadData?.psProductIdCol ? headerRow.find(cell => cell.value === excelUploadData.psProductIdCol) : null}
            onChange={(event, newValue) => setExcelUploadData(data => ({ ...(data ?? {}), psProductIdCol: newValue?.value }))}
            options={headerRow}
            {...SimpleAutocompleteProps()}
          />
        </Box>
      </SpacedRow>
    </Grid>
    {excelUploadData?.facilityCol && excelUploadData.categoryCol && excelUploadData.psProductIdCol && <CustomDataGrid
      gridId="admin-manage-order-guide-excel-upload"
      boxSX={{ height: '300px' }}
      scrollbarHeight={null}
      tableBoxSX={{ height: 'calc(100% - 52px)' }}
      data={tableData.length > 0 ? tableData.slice(1) : tableData}
      gridColumns={columns}
      sort={[{ field: 'facility', sort: 'asc' }]}      
    />}
  </Stack>;
}

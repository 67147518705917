import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { postDeleteVendor } from '../../../../api/invoicing';
import { getVendors } from '../../../../redux/slices/invoicing';
import { getVendors as getPurchasingVendors } from '../../../../redux/slices/vendors';
import { useDispatch, useSelector } from '../../../../redux/store';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { createColumns } from '../../../../reusable-components/datagrid/custom-filters';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { INV_VENDORS } from '../../../../reusable-components/datagrid/vendorColumns';
import NewVendorModal from './NewVendorModal';
import VendorCustomToolbar from './VendorCustomToolbar';


export default function ManualVendorTable() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { data: { invoicingVendors } } = useSelector((state) => state.invoicing);
  const { vendors: invVendors } = invoicingVendors;
  const isLoading = useSelector((state) => state.invoicing.isLoading);

  useEffect(() => {
    dispatch(getPurchasingVendors());
    dispatch(getVendors());
  }, []);

  useEffect(() => {
    if (invVendors !== undefined && invVendors !== null) {
      setData(invVendors);
    }
  }, [invVendors]);

  const deleteVendor = async (id) => {
    await postDeleteVendor(id);
    await dispatch(getVendors());
  }

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: (params) => (<>
      <Box>
        <ConfirmDialog
          icon="delete-outline"
          title="Delete"
          color="error"
          content="Are you sure you want to delete this vendor?"
          actionButton="Delete"
          action={() => deleteVendor(params.row.id)}
        /></Box>
      <Box>
        <NewVendorModal row={params.row} edit />
      </Box>
    </>),
    id: 'actions',
  };

  const columns = createColumns([...INV_VENDORS, actionButtons]);


  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="invoicing-vendors-invoicing-vendors"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          sort={[{ field: 'invoiceVendorId', sort: 'asc' }]}
          gridColumns={[...columns]}
          CustomLeftToolbar={VendorCustomToolbar}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}

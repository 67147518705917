import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import { Alert, Autocomplete, Box, IconButton, Stack, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useSelector } from '../../../../redux/store';

const filter = createFilterOptions();
AddMoreCategoryAutocomplete.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  setError: PropTypes.func,
};
export default function AddMoreCategoryAutocomplete({ value, setValue, setError }) {
  const { invoicingGLCategories } = useSelector((state) => state.invoicing.data);
  const [open, setOpen] = React.useState(false);

  const options = useMemo(() => invoicingGLCategories?.filter(c => c.facilityId === value?.facility?.value)
    ?.map(cat => ({ category: cat.category, categoryDesc: cat.description, facilityId: cat.facilityId, categoryId: cat.categoryId }))
    , [invoicingGLCategories, value.facility])

  const goBack = () => {
    setOpen(false);
  }

  const categoryExistsError = useMemo(() => options?.filter(o => o.category?.toUpperCase() === value?.category?.toUpperCase()).length > 0
    , [value.category]);

  const categoryBlankError = useMemo(() => !value.category
    , [value.category]);

  useEffect(() => {
    setError(categoryBlankError);
  }, [categoryBlankError])

  useEffect(() => {
    const catId = options?.find(o => o.category?.toUpperCase() === value?.category?.toUpperCase())?.categoryId;
    if (categoryExistsError)
      setValue({ ...value, categoryId: catId })
  }, [categoryExistsError])

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
        {!open &&
          <Box flex={12}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Category
            </Typography>
            <Autocomplete isClearable
              size="small"
              value={value}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    setOpen(true);
                    setValue({
                      ...value,
                      category: newValue,
                      categoryDesc: '',
                      categoryId: '',
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  setOpen(true);
                  setValue({
                    ...value,
                    category: newValue.inputValue,
                    categoryDesc: '',
                    categoryId: '',
                  });
                } else {
                  setValue({ ...value, ...newValue });
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    category: `Add "${params.inputValue}"`,
                  });

                }
                return filtered;
              }}
              id="free-solo-dialog-demo"
              options={options}
              getOptionLabel={(option) => {
                // for example value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.category;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    {option.category} {option.categoryDesc && <Typography variant="caption">{` - ${option.categoryDesc}`}
                    </Typography>}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} />}
              sx={{ width: "100%" }}
            />
          </Box>
        }{open &&
          <Box flex={12}>
            <Typography variant="subtitle1"> <IconButton onClick={() => goBack()}>
              <ArrowBackIcon />
            </IconButton>
              New Category</Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
              {open &&
                <Box flex={5}>
                  <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                    Code
                  </Typography>
                  <TextField
                    autoFocus
                    size="small"
                    id="name"
                    value={value.category}
                    onChange={(event) =>
                      setValue({
                        ...value,
                        category: event.target.value,
                      })
                    }
                    type="text"
                  /></Box>
              }{open &&
                <Box flex={5}>
                  <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                    Description
                  </Typography>
                  <TextField
                    size="small"
                    id="name"
                    value={value.categoryDesc}
                    onChange={(event) =>
                      setValue({
                        ...value,
                        categoryDesc: event.target.value,
                      })
                    }
                  /></Box>
              }

            </Stack>
            {open && (categoryExistsError || categoryBlankError) && (
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} sx={{ mt: 1 }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                  {categoryBlankError ? 'Missing GL category' : categoryExistsError && 'GL category already exists and will be updated'}
                </Alert>
              </Stack>)}
          </Box>}
      </Stack>

    </>
  );
}
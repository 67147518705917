import { useState, useEffect } from 'react';
import { Box, Typography, Grid, TextField, InputAdornment, IconButton } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSelector, useDispatch } from '../../../redux/store';
import Iconify from '../../../reusable-components/iconify';
import { getCategoryData } from '../../../redux/slices/rentals';
import DeleteRentalCategory from './DeleteRentalCategory';
import AddRentalCategory from './AddRentalCategory';

export default function RentalCategoriesView() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const {
    data: { categoryData },
  } = useSelector((state) => state.rentals);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const rows = categoryData?.map((item, index) => ({
    id: index + 1, // Unique identifier for each row
    categoryName: item.categoryName,
    categoryId: item.categoryId,
    subcategoryName: item.subcategoryName,
    subcategoryId: item.subcategoryId,
  }));

  useEffect(() => {
    dispatch(getCategoryData());
  }, [dispatch]);

  const columns = [
    {
      field: 'categoryName',
      headerName: 'Category',
      width: 250,
    },
    {
      field: 'subcategoryName',
      headerName: 'Subcategory',
      width: 250,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <>
          <DeleteRentalCategory row={params.row} />
        </>
      ),
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <Box>
      <Grid container direction="row" alignItems="center" sx={{ mb: 1, ml: 1 }} spacing={1}>
        {/* Buttons on the left */}
        <Grid item>
          <AddRentalCategory description={'Category'} />
        </Grid>
        <Grid item>
          <AddRentalCategory description={'Subcategory'} />
        </Grid>
        {/* Search bar on the right */}
        <Grid item sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            value={searchValue}
            onChange={handleSearch}
            size="small"
            placeholder="Search..."
            sx={{ mr: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ display: searchValue === '' ? 'none' : 'inline-flex' }}
                    onClick={() => {
                      setSearchValue('');
                      handleSearch({ target: { value: '' } });
                    }}
                  >
                    <Iconify
                      icon={'ri:close-fill'}
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              style: { height: '30px', borderRadius: '4px', border: `solid 1px #404042` },
            }}
          />
        </Grid>
      </Grid>
      <DataGridPro
        rows={rows}
        columns={columns}
        initialState={{
          pinnedColumns: {
            left: ['category'],
          },
        }}
        autoHeight={data.length === 0}
        components={{
          NoRowsOverlay: () => (
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>No results found.</Typography>
            </Box>
          ),
        }}
        hideFooter
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableColumnPinning
        disableColumnReorder
      />
    </Box>
  );
}

import { Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { getRentalItems } from '../../../../redux/slices/rentals';
import { useDispatch, useSelector } from '../../../../redux/store';
import Scrollbar from '../../../../reusable-components/scrollbar';
import RentalCartDropdown from './RentalCartDropdown';
import RentalCategoryDropdown from './RentalCategoryDropdown';
import RentalItem from './RentalItem';
import RentalSearch from './RentalSearch';
import RentalSubcategorySelect from './RentalSubcategorySelect';

export default function RentalItemsSelect() {
  const dispatch = useDispatch();
  const {
    data: { items, categoryData, facilityVendors },
  } = useSelector((state) => state.rentals);

  const [searchParams] = useSearchParams();
  const selectedCategoryName = searchParams.get('category');
  const selectedSubcategoryName = searchParams.get('subcategory');
  const [debouncedValue, setDebouncedValue] = useState('');
  const selectedCategory = categoryData.find((cat) => cat.categoryName === selectedCategoryName);
  const selectedCategoryId = selectedCategory?.categoryId;

  const selectedSubcategory = categoryData.find(
    (subcat) => subcat.categoryName === selectedCategoryName && subcat.subcategoryName === selectedSubcategoryName
  );
  const selectedSubcategoryId = selectedSubcategory?.subcategoryId;

  const itemsJoinedWithVendor = items
    .map((item) => {
      const matchingVendor = facilityVendors.find((fv) => fv.vendorId === item.vendorId);
      return {
        ...item,
        isPreferred: matchingVendor ? matchingVendor.isPreferred : false,
      };
    })
    .sort((a, b) => b.isPreferred - a.isPreferred);

  const filteredItems = useMemo(
    () =>
      itemsJoinedWithVendor.filter((item) => {
        const matchesCategory = item.categoryId === selectedCategoryId;
        const matchesSubcategory = selectedSubcategoryId ? item.subcategoryId === selectedSubcategoryId : true;
        const matchesSearch = item?.description?.toLowerCase().includes(debouncedValue?.toLowerCase());

        return matchesCategory && matchesSubcategory && matchesSearch;
      }),
    [debouncedValue, itemsJoinedWithVendor, selectedCategoryId, selectedSubcategoryId]
  );

  useEffect(() => {
    dispatch(getRentalItems());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>Rental Items | Platform Solutions</title>
      </Helmet>
      <>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item sx={{ ml: 4.5 }}>
            <RentalCategoryDropdown />
          </Grid>
          <Grid item style={{ flexGrow: 1 }} />
          <Grid item>
            <RentalSearch debouncedValue={debouncedValue} setDebouncedValue={setDebouncedValue} />
          </Grid>
          <Grid item>
            <RentalCartDropdown />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item sx={{ ml: 3 }}>
            <RentalSubcategorySelect />
          </Grid>
        </Grid>
      </>

      <Scrollbar sx={{ height: 'calc(100vh - 275px)' }}>
        <Grid container spacing={4} sx={{ justifyContent: 'center', alignItems: 'flex-start', ml: 3 }}>
          {(filteredItems.length > 0 ? filteredItems : itemsJoinedWithVendor).map((item) => (
            <RentalItem key={item.id} item={item} />
          ))}{' '}
        </Grid>
      </Scrollbar>
    </>
  );
}

import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import AwaitButton from '../await-button/AwaitButton';

NotificationDialog.propTypes = {
  title: PropTypes.node,
  cancelAction: PropTypes.func,
  content: PropTypes.node,
  actionButton: PropTypes.string,
  handleClose: PropTypes.func,
};

export default function NotificationDialog({ title, content, actionButton = 'OK', handleClose, ...other }) {
  return (
    <>
      <Dialog fullWidth maxWidth="xs" open onClose={handleClose} {...other}>
        <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>{title}</DialogTitle>
        {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}
        <DialogActions>
          <AwaitButton variant="contained" color={'primary'} onClick={handleClose}>
            {actionButton}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { postDeleteSubcategory } from '../../../api/rentals';
import { getCategoryData } from '../../../redux/slices/rentals';
import { useDispatch, useSelector } from '../../../redux/store';
import { AwaitButton } from '../../../reusable-components/await-button';
import Iconify from '../../../reusable-components/iconify';
import { useSnackbar } from '../../../reusable-components/snackbar';

DeleteRentalCategory.propTypes = {
  row: PropTypes.object,
};

export default function DeleteRentalCategory({ row }) {
  console.log(row);
  const {
    data: { categoryData },
  } = useSelector((state) => state.rentals);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);

  const seenCategories = new Set();

  const categoryOptions = useMemo(
    () =>
      categoryData.reduce((acc, item) => {
        if (!seenCategories.has(item.categoryName)) {
          seenCategories.add(item.categoryName);
          acc.push({
            label: item.categoryName,
            id: item.categoryId,
          });
        }
        return acc;
      }, []),
    [categoryData]
  );
  const subcategoryOptions = useMemo(
    () =>
      categoryData
        .filter((s) => s.subcategoryId != null)
        .map((item) => ({
          label: item.subcategoryName,
          id: item.subcategoryId,
          categoryId: item.categoryId,
        })),
    [categoryData]
  );
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCategory(null);
  };

  const handleDelete = async () => {
    const response = await postDeleteSubcategory({
      id: row.subcategoryId,
      substituteSubcategoryId: subcategory.id,
      substituteCategoryId: category.id,
    });
    if (!response.data.success) {
      enqueueSnackbar('Subcategory cannot be deleted', { variant: 'error' });
      return;
    }
    enqueueSnackbar(`${subcategory.name} removed`, { variant: 'success' });
    dispatch(getCategoryData());
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Delete Subcategory">
        <IconButton onClick={handleClickOpen}>
          <DeleteOutlineIcon color="error" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Delete Subcategory
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <>
            <Typography>Switch existing items from {row.subcategoryName} *</Typography>
            <DialogContentText sx={{ mt: 2, fontWeight: 'bold' }}>Main Category</DialogContentText>
            <Autocomplete
              sx={{
                mt: 1,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': 'none',
              }}
              size="small"
              fullWidth
              type="new-password"
              options={categoryOptions}
              autoHighlight
              getOptionLabel={(option) => option?.label}
              value={category}
              onChange={(event, newValue) => {
                setCategory(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
            <DialogContentText sx={{ mt: 2, fontWeight: 'bold' }}>Subcategory</DialogContentText>
            <Autocomplete
              sx={{
                width: '100%',
                mt: 1,
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabel-root ': 'none',
              }}
              size="small"
              type="new-password"
              options={subcategoryOptions.filter((s) => s.categoryId === category?.id)}
              getOptionDisabled={(option) => option.id === row.subcategoryId}
              autoHighlight
              getOptionLabel={(option) => option?.label}
              value={subcategory}
              onChange={(event, newValue) => {
                setSubcategory(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
          </>
        </DialogContent>
        <DialogActions>
          <AwaitButton
            variant="outlined"
            color="error"
            size="medium"
            sx={{ width: 150, mr: 2 }}
            onClick={handleDelete}
            disabled={row.subcategoryId && (!category || !subcategory)}
          >
            Delete
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

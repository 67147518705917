import PropTypes from 'prop-types';
import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import TextFormField from './IntegrationTextFormField';
import CheckboxFormField from './IntegrationCheckboxFormField';
import Row from './IntegrationFormRow';
import Iconify from '../../../../../reusable-components/iconify/Iconify';

_JSONOrderIntegration.propTypes = {
  value: PropTypes.object,
  sites: PropTypes.array,
  setValue: PropTypes.func,
};

export default function _JSONOrderIntegration({ value, setValue, sites })
{
  const setIdentitiesValue = (id, index, inputValue) => {
    setValue(value => {
      const identities = value.identities?.length > 0 ? value.identities : [{}];
      identities[index][id] = inputValue;
      return { ...value, identities };
    });
  }

  return (
    <>
      <TextFormField
        header={'Order URL'}
        value={value.orderURL}
        onChange={(e) => setValue({ ...value, orderURL: e.target.value })}
      />     
      <Row>
        <TextFormField
          header={'PS Identity'}
          value={value.psIdentity}
          onChange={(e) => setValue({ ...value, psIdentity: e.target.value })}
        />
        <TextFormField
          header={'Vendor Identity'}
          value={value.vendorIdentity}
          onChange={(e) => setValue({ ...value, vendorIdentity: e.target.value })}
        />
      </Row>
      <Typography variant="subtitle1" sx={{ mt: 1 }}>
        Return Identites <IconButton onClick={() => { setValue({ ...value, identities: [...(value.identities || [{}]), {}] }) }}>
          <Iconify icon="mdi:plus-circle-outline" color="primary" width={28} height={28} />
        </IconButton>
      </Typography>
      {(value.identities?.length > 0 ? value.identities : [{}]).map((identity, index) => <Row key={index}>
        <>
          {index === 0 && <Typography variant="subtitle1" sx={{ mt: 1 }}>Return PS Identity</Typography>}
          <TextField
            fullWidth
            size="small"
            value={identity.psIdentity ?? ""}
            onChange={(e) => setIdentitiesValue("psIdentity", index, e.target.value)}
            sx={{
              mt: 1,
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabel-root ': { display: 'none' },
            }}
          />
        </>
        <>
          {index === 0 && <Typography variant="subtitle1" sx={{ mt: 1 }}>Return Vendor Identity</Typography>}
          <TextField
            fullWidth
            size="small"
            value={identity.vendorIdentity ?? ""}
            onChange={(e) => setIdentitiesValue("vendorIdentity", index, e.target.value)}
            sx={{
              mt: 1,
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabel-root ': { display: 'none' },
            }}
          />
        </>
        <>
          {index === 0 && <Typography variant="subtitle1" sx={{ mt: 1 }}>Site</Typography>}
          <Autocomplete
            fullWidth
            size="small"
            value={sites.find(site => site.value === identity.siteId) || null}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            onChange={(event, newValue) => {
              setIdentitiesValue("siteId", index, newValue?.value ?? 0);
            }}
            renderInput={(params) => <TextField {...params} label="Search or select" />}
            sx={{
              mt: 0.5,
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabel-root ': { display: 'none' },
            }}
            options={sites}
          />
        </>
      </Row>)}
      <CheckboxFormField
        label={'Ordering Disabled'}
        checked={value.orderingDisabled ?? false}
        onChange={(e) => setValue({ ...value, orderingDisabled: e.target.checked })}
      />
    </>
  );
}

import { Box, Button, Grid, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { postAddTicket } from '../../../../api/tickets';
import { useTabs } from '../../../../context/TabContext';
import { editTicketData } from '../../../../redux/slices/tickets';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { fMonthDayTimeYear } from '../../../../utils/formatTime';
import EditSubject from './EditSubject';

export default function TicketSubject() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { addNewTabAndNavigate } = useTabs();
  const { id = '' } = useParams();
  const { subtickets, mainTicket } = useSelector((state) => state.tickets.data.ticketInfoById[id]) || {};


  const subticketId = new URLSearchParams(location.search).get('subticket');
  const { ticketNumber } = useSelector((state) => state.tickets.data.ticketById[subticketId || id]) || {};

  const [ticketNum, setTicketNum] = useState(false);
  const [editSubject, setEditSubject] = useState(false);
  const [subject, setSubject] = useState(mainTicket?.extraInfo || '');

  const checkTicketId = (ticketId) => {
    if (subticketId === null) {
      if (id === ticketId) return true;
      return false;
    }
    if (parseInt(subticketId, 10) === ticketId) return true;
    if (subticketId === ticketId) return true;
    return false;
  };

  useEffect(() => {
    if (!subtickets) setTicketNum(id);
    if (subticketId === null) {
      setTicketNum(id);
    } else {
      setTicketNum(subtickets?.find((subticket) => subticket.id === parseInt(subticketId, 10))?.ticketName);
    }
  }, [subtickets, id, subticketId]);

  useEffect(() => {
    setSubject((subticketId ? subtickets?.find((subticket) => subticket.id === parseInt(subticketId, 10))?.extraInfo : null) ?? mainTicket?.extraInfo);
  }, [mainTicket, subtickets, subticketId]);

  const editTicket = async ({ Id, value, updateType }) => {
    const response = await dispatch(editTicketData({ Id: ticketNumber || Id, value, updateType }));
    if (response) {
      enqueueSnackbar(`Ticket subject updated successfully`, { variant: 'success' });
    } else {
      enqueueSnackbar('Failed to update subject', { variant: 'error' });
    }
  };

  const handleEditSubject = () => {
    editTicket({ Id: ticketNumber || id, value: subject, updateType: 'ExtraInfo' });
  };
  const handleCancelSubject = () => {
    setEditSubject(false);
    setSubject(mainTicket?.extraInfo || '');
  };

  const handleSend = async () => {
    try {
      const response = await postAddTicket({
        message: '',
        ParentTicketId: id,
        ExtraInfo: mainTicket?.extraInfo ?? "",
      });
      if (response.data.id) {
        navigate(`/dashboard/tickets/${id}?subticket=${response.data.id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3, mb: 0.5 }}>
        <Grid item>
          <Box>
            <Typography variant="subtitle1" color="info.main">
              Ticket #{ticketNum}
            </Typography>
          </Box>
          {editSubject ? (
            <>
              <Typography variant="subtitle1" color="text.secondary">
                Subject:
                <TextField
                  id="standard-search"
                  variant="standard"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  sx={{
                    width: 'auto',
                    height: '30px',
                    mb: 1,
                    '& .MuiInputBase-input': {
                      height: '30px',
                      fontSize: '16px',
                      fontWeight: '700',
                      margin: 0,
                      marginLeft: 0.5,
                      marginBottom: -1,
                      fontFamily: `Public Sans,sans-serif`,
                      '& .MuiInputBase-input:focus': {
                        height: '30px',
                      },
                    },
                  }}
                />
                <IconButton
                  onClick={() => {
                    handleCancelSubject();
                  }}
                  color="warning"
                >
                  <Iconify icon="iconoir:cancel" />
                </IconButton>
                <IconButton
                  onClick={(subject) => {
                    setEditSubject(false);
                    handleEditSubject(subject);
                  }}
                  color="primary"
                >
                  <Iconify icon="material-symbols:check" />
                </IconButton>
              </Typography>
            </>
          ) : (
            <Typography variant="subtitle1" color="text.secondary">
              {
                <>
                  Subject: {subject}
                  <ScopeGuard scopes={['Tickets-Edit']} allowAdmin>
                    <IconButton onClick={() => setEditSubject(true)} sx={{ ml: 0 }} color="primary">
                      <Iconify icon="material-symbols:edit" width={15} height={5} sx={{ mr: 0.5 }} />
                    </IconButton>
                  </ScopeGuard>
                </>
              }
            </Typography>
          )}
        </Grid>
        <Grid item>
          {mainTicket?.ticketOrderId > 0 ? (
            <>
              <Grid container direction="row" spacing={12} justifyContent="flex-end" alignItems="center">
                <Grid item sx ={{ mb: 1 }}>
                  <Typography variant="subtitle2" color="text.secondary" sx={{ mr: 6 }}>
                    <Link color="info.main" sx={{ textDecoration: 'underline' }}                    >
                      <Box onClick={() => addNewTabAndNavigate(`/dashboard/orders/${mainTicket?.ticketOrderId}`)}> Order Number: {mainTicket?.ticketOrderId}</Box>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={12} justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <Tooltip title="Date created">
                    <Typography variant="subtitle2" color="text.secondary">
                      <Iconify icon="material-symbols:calendar-today-outline" width={12} height={12} color="secondary" />
                      {fMonthDayTimeYear(mainTicket?.createdOn)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ) : mainTicket?.createdOn ? (
            <Grid item sx={{ mr: 6 }}>
              <Typography variant="subtitle2" color="text.secondary">
                <Iconify
                  icon="material-symbols:calendar-today-outline"
                  width={12}
                  height={12}
                  color="secondary"
                  sx={{ mr: 0.5 }}
                />
                {fMonthDayTimeYear(mainTicket?.createdOn)}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: -0.3 }}>
        <Link
          to={`/dashboard/tickets/${id}`}
          component={RouterLink}
          sx={{
            padding: 1,
            backgroundColor: checkTicketId(id) ? 'info.main' : 'transparent',
            borderRadius: '3px',
            border: `1px solid #8BC5EF`,
            borderBottom: 'none',
            color: checkTicketId(id) ? '#fff' : 'text.secondary',
            ':hover': { backgroundColor: 'info.main', color: '#fff !important' },
          }}
          key={'mainTicket'}
        >
          <Typography variant="subtitle2">Main Ticket</Typography>
        </Link>
        {subtickets?.map((subticket, index) => (
          <EditSubject
            subticket={subticket}
            key={index}
            index={index}
            parentTicketId={id}
            checkTicketId={checkTicketId}
          />
        ))}
        <Box>
          {!mainTicket?.locked && (
            <Button
              variant="contained"
              color="info"
              onClick={handleSend}
              size="small"
              startIcon={
                <Iconify
                  icon="ic:baseline-plus"
                  width={15}
                  height={15}
                  color="white"
                  sx={{
                    mr: -1,
                  }}
                />
              }
              sx={{
                cursor: 'pointer',
                backgroundColor: 'info.main',
                paddingX: 0.5,
                pr: 0.6,
                paddingY: 0.1,
                ml: 1,
              }}
            >
              <Typography variant="subtitle2">Subticket</Typography>
            </Button>
          )}
        </Box>
        {/* </Link> */}
      </Grid>
    </>
  );
}

import { Box, Button, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { postDeleteVendor } from '../../../../api/invoicing';
import { HOST_API_URL } from '../../../../config-global';
import { getGLs } from '../../../../redux/slices/invoicing';
import { useDispatch, useSelector } from '../../../../redux/store';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { createColumns } from '../../../../reusable-components/datagrid/custom-filters';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { GL_SETTINGS } from '../../../../reusable-components/datagrid/invoiceColumns';
import Iconify from '../../../../reusable-components/iconify';
import GlAPIModal from './GlAPIModal';
import NewGLModal from './NewGlModal';


export default function ManualGLTable() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { data: { invoicingGLs } } = useSelector((state) => state.invoicing);
  const isLoading = useSelector((state) => state.invoicing.isLoading);

  useEffect(() => {
    dispatch(getGLs());
  }, []);

  useEffect(() => {
    if (invoicingGLs !== undefined && invoicingGLs !== null) {
      setData(invoicingGLs);
    }
  }, [invoicingGLs]);

  const deleteVendor = async (id) => {
    await postDeleteVendor(id);
    await dispatch(getGLs());
  }

  const customToolbar = useCallback(() => (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Grid container direction="row" alignItems="flex-end" spacing={1} sx={{ mb: 1 }}>
          {
            <Grid item>
              <NewGLModal />
              <GlAPIModal />
              <Button disabled
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => {
                  window.open(`${HOST_API_URL}Api/Invoices/DownloadVendors`, '_blank');
                }}
                startIcon={<Iconify icon="file-icons:microsoft-excel" />}
              >
                Download Invoice GLs
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  ), []);

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: (params) => (
      <>
        <Box>
          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content="Are you sure you want to delete this vendor?"
            actionButton="Delete"
            action={() => deleteVendor(params.row.id)}
          /></Box>
        <Box>
          <NewGLModal row={params.row} edit />
        </Box>
      </>),
    id: 'actions',
  };

  const columns = createColumns([...GL_SETTINGS, actionButtons]);


  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="invoicing-vendors-manual-gls"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          sort={[{ field: 'invoiceVendorId', sort: 'asc' }]}
          gridColumns={[...columns]}
          getRowId={(row) => row.subcategoryId}
          CustomLeftToolbar={customToolbar}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}

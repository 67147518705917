import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Tooltip, Grid, TextField, Button, Stack, IconButton, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from '../../../../redux/store';
import { approveInvoice, getInvoiceBreakdown } from '../../../../redux/slices/invoicing';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import Iconify from '../../../../reusable-components/iconify';

ApproveInvoice.propTypes = {};
export default function ApproveInvoice() {
  const { id } = useParams();
  const {
    data: { invoiceApprovers, invoiceItemData },
  } = useSelector((state) => state.invoicing);

  const { invoice } = invoiceItemData || {};
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [readyForApproval, setReadyForApproval] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [comment, setComment] = useState(''); // Stores the user's comment

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const approver = invoiceApprovers?.find((x) => x.approverEmail === user.email);
    if (approver) {
      const higherApprovalRequired = invoiceApprovers
        .filter((a) => a.approvalIndex < approver.approvalIndex)
        .some((a) => a.status === 1);
      setReadyForApproval(!higherApprovalRequired);
    }
  }, [invoice]);

  const handleApprove = async () => {
    const response = await dispatch(approveInvoice(id));
    if (response === 'success') {
      await dispatch(getInvoiceBreakdown(id));
      enqueueSnackbar('Invoice approved successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Error approving invoice', { variant: 'error' });
    }
  };
  const handleReject = async () => {
    if (!rejecting) {
      setRejecting(true);
    } else {
      const response = await dispatch(approveInvoice(id, 'Reject', comment));
      if (response === 'success') {
        await dispatch(getInvoiceBreakdown(id));
        enqueueSnackbar('Invoice rejected successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Error rejecting invoice', { variant: 'error' });
      }
      setRejecting(false);
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Tooltip
            title={!readyForApproval ? 'Awaiting completion by the previous approver in the process.' : ''}
            placement="top"
            arrow
          >
            <Box sx={{ position: 'relative' }}>
              <Button
                variant="outlined"
                color="secondary"
                loadingPosition="start"
                disabled={!readyForApproval}
                onClick={() => {
                  setComment('');
                  setRejecting(!rejecting);
                }}
                endIcon={<Iconify icon="mingcute:down-line" />}
              >
                Reject Invoice
              </Button>
              {rejecting && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    zIndex: 10,
                    background: '#fff',
                    padding: '8px 16px',
                    boxShadow: '0px 4px 6px rgb(17 12 12 / 51%)',
                    width: '300px',
                    borderRadius: '6px',
                    mt: '10px',
                  }}
                >
                  <Grid container alignItems={'center'}>
                    <Grid item flexGrow={1}>
                      <TextField
                        fullWidth
                        variant="standard"
                        value={comment}
                        color="grey"
                        onChange={handleCommentChange}
                        placeholder="Reason for rejecting"
                        inputProps={{
                          sx: {
                            '&::placeholder': {
                              color: 'info.main',
                              opacity: 0.75,
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Stack>
                        <AwaitButton
                          color="secondary"
                          sx={{ padding: 1, borderRadius: '50%', minWidth: 0 }}
                          onClick={handleReject}
                          id="action-button"
                          className="action-button"
                        >
                          <SendIcon />
                        </AwaitButton>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setComment('');
                            setRejecting(false);
                          }}
                        >
                          <Typography component="span" sx={{ textDecoration: 'underline', fontSize: '8px' }}>
                            Cancel
                          </Typography>
                        </IconButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            title={!readyForApproval ? 'Awaiting completion by the previous approver in the process.' : ''}
            placement="top"
            arrow
          >
            <Box>
              <AwaitButton
                variant="contained"
                color="secondary"
                onClick={handleApprove}
                loadingPosition="start"
                disabled={!readyForApproval}
              >
                Approve Invoice
              </AwaitButton>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}

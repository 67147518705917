import { useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, Grid, Tooltip, Typography, Button, TextField, InputAdornment, Autocomplete, Icon, IconButton } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useScopeCheck, ScopeGuard } from '../../../../reusable-components/scopes';
import { useDispatch, useSelector } from '../../../../redux/store';
import { fCurrency, fNumber } from '../../../../utils/formatNumber';
import Iconify from '../../../../reusable-components/iconify';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { patchInvoiceItems, deleteGlItems } from '../../../../redux/slices/invoicing';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import usePrompt from '../../../../reusable-components/prompt/usePrompt';

OrderInformation.propTypes = {
  highlightMissingGls: PropTypes.bool,
  expandBottom: PropTypes.bool,
  setUnsavedData: PropTypes.func,
  unsavedData: PropTypes.object,
};
export default function OrderInformation({ highlightMissingGls, expandBottom, setUnsavedData, unsavedData }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const {
    data: { invoiceData, invoiceItemData, invoiceGLItems },
  } = useSelector((state) => state.invoicing);
 const hasEditAccess = useScopeCheck(
    invoiceItemData?.invoice?.sourceMethod === 'Manual' ? ['ManualInvoice-Edit'] : ['Invoice-Edit'],
    true
  );  const { invoice, maximumItemDiscrepancy } = invoiceItemData || {};
  const { categories: cats, manualCategories, subcategories: subcats, manualSubcategories } = invoiceData;
  const isLoading = useSelector((state) => state.invoicing.isLoading);
  const [rows, setRows] = useState([]);
  const [sortModel, setSortModel] = useState([{ field: 'manual', sort: 'desc' }, { field: 'expectedItemPrice', sort: 'desc' }]);

  const categories = invoiceItemData.invoice.sourceMethod === null ? cats :/*sourceMethod === "Manual"*/ manualCategories;
  const subcategories = invoiceItemData.invoice.sourceMethod === null ? subcats :/*sourceMethod === "Manual"*/ manualSubcategories;

  const editable = !invoiceItemData?.invoice?.multiFacility && invoiceGLItems?.length === 0 && hasEditAccess;

  const apiRef = useGridApiRef();
  const renderEditableNumber = (field, row, currency = false) =>
    editable ? (
      <TextField
        type={'number'}
        disabled={!hasEditAccess}
        size="small"
        value={row[field]}
        onChange={(e) => {
          const updatedRow = { ...row, [field]: Number(e.target.value) };
          setUnsavedData((prev) => ({
            ...prev,
            [row?.id]: updatedRow,
          }));

          apiRef.current.updateRows([updatedRow]);
        }}
        InputProps={currency && {
          startAdornment: (
            <InputAdornment position="start">
              <Typography sx={{ fontSize: '0.8rem', fontWeight: 600, color: '#404042' }}>$</Typography>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '0.8rem',
            fontWeight: 600,
          },
        }}
      />
    ) : (currency ?
      fCurrency(row[field]) : row[field]);

  const renderEditableString = (field, row) =>
    editable ? (
      <TextField
        type={String}
        disabled={!hasEditAccess}
        size="small"
        value={row[field]}
        onChange={(e) => {
          const updatedRow = { ...row, [field]: e.target.value };
          setUnsavedData((prev) => ({
            ...prev,
            [row?.id]: updatedRow,
          }));

          apiRef.current.updateRows([updatedRow]);
        }} 
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '0.8rem',
            fontWeight: 600,
          },
        }}
      />
    ) : (row[field]);
  const renderCategory = (params) => {
    const { row } = params;
    return editable ? (
      <Autocomplete
        sx={{ fontSize: '12px', flexGrow: 1 }}
        options={categories?.filter((category) => category?.facilityId === invoice?.facilityId) || []}
        getOptionLabel={(option) => option.category}
        getOptionValue={(option) => option.categoryId}
        onChange={(e, newValue) => {
          const updatedRow = {
            ...row,
            categoryId: newValue.categoryId,
            subcategoryId: 0,
            glCode: '',
          };
          setUnsavedData((prev) => ({
            ...prev,
            [row?.id]: updatedRow,
          }));
          apiRef.current.updateRows([updatedRow]);
        }}
        disableClearable
        value={categories
          ?.filter((category) => category?.facilityId === invoice?.facilityId)
          ?.find((x) => x.categoryId === row?.categoryId) || null}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: '0.8rem',
                fontWeight: 600,
                padding: 0,
                paddingRight: '25px',
              },
            }}
            variant="outlined"
          />
        )}
      />
    ) : (
      categories
        ?.filter((category) => category.facilityId === invoice?.facilityId)
        ?.find((x) => x.categoryId === row?.categoryId)?.category ?? null
    );
  };
  const renderSubcategory = (params) => {
    const { row } = params;
    return editable ? (
      <Autocomplete
        sx={{ fontSize: '12px', flexGrow: 1 }}
        options={
          subcategories?.filter(
            (sub) => sub.facilityId === invoice?.facilityId && sub.categoryId === row?.categoryId
          ) || []
        }
        getOptionLabel={(option) => `${option.subcategory} ${option.glCode}`}
        getOptionValue={(option) => option.subcategoryId}
        value={
          subcategories
            ?.filter((sub) => sub.facilityId === invoice?.facilityId && sub.categoryId === row?.categoryId)
            ?.find((x) => x.subcategoryId === row?.subcategoryId) ?? null
        }
        onChange={(event, newValue) => {
          const updatedRow = { ...row, subcategoryId: newValue.subcategoryId, glCode: newValue.glCode };
          setUnsavedData((prev) => ({
            ...prev,
            [row?.id]: updatedRow,
          }));
          apiRef.current.updateRows([updatedRow]);
        }}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: '0.8rem',
                fontWeight: 600,
                padding: 0,
                paddingRight: '25px',
              },
            }}
            variant="outlined"
          />
        )}
      />
    ) : (
      subcategories
        ?.filter((sub) => sub.facilityId === invoice?.facilityId && sub.categoryId === row?.categoryId)
        ?.find((x) => x.subcategoryId === row?.subcategoryId)?.subcategory ?? null
    );
  };

  usePrompt(Object.values(unsavedData)?.length > 0);
  const invoiceItemTotal = {
    field: 'total',
    headerName: 'Invoice Total',
    width: 100,
    type: 'customMoney',
    valueGetter: (params) => fCurrency(params.row.quantity * params.row.price + params.row.taxAmount),
  };
  const categoryDropdown = {
    field: 'categoryId',
    headerName: 'Category',
    width: 140,
    maxHeight: 45,
    overflow: 'hidden',
    renderCell: renderCategory,
  };
  const subCategoryDropdown = {
    field: 'subcategoryId',
    headerName: 'Subcategory',
    width: 240,
    renderCell: renderSubcategory,
  };
  const price = {
    field: 'price',
    headerName: 'Invoice Price',
    width: 100,
    renderCell: (params) => renderEditableNumber('price', params.row, true),
    valueGetter: (params) => params.row.price,
  };
  const manual = {
    field: 'manual',
    headerName: 'manual',
    valueGetter: (params) => params.row.manual ?? false
  };
  const taxAmount = {
    field: 'taxAmount',
    headerName: 'Tax Amount',
    width: 100,
    renderCell: (params) => renderEditableNumber('taxAmount', params.row, true),
    valueGetter: (params) => params.row.taxAmount,
  };
  const description = {
    field: 'description',
    headerName: 'Description',
    width: 200,

    renderCell: (params) => params.row.manual ? renderEditableString('description', params.row)
      : <Tooltip title={params.value} placement="bottom" arrow>
          <Box
            component="div"
            sx={{
              fontSize: '0.7rem',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            }}
          >
            {params.value}
          </Box>
        </Tooltip>,
    valueGetter: (params) => params.row.description,
  };

  const vendorItemId = {
    field: 'vendorItemId',
    headerName: 'SKU',
    width: 100,
    renderCell: (params) => params.row.manual ?
      renderEditableString('vendorItemId', params.row) :params.row.vendorItemId,
    valueGetter: (params) => params.row.vendorItemId,
  };
  const expectedItemPrice = {
    field: 'expectedItemPrice',
    headerName: 'PO Price',
    width: 100,
    renderCell: (params) => {
      const overMax = Math.abs(params.row.price - params.row.expectedItemPrice) > maximumItemDiscrepancy;
      const notExact = params.row.price !== params.row.expectedItemPrice;
      return (
        <Tooltip
          title={
            overMax
              ? 'Our price does not match their price'
              : notExact
                ? `Price within acceptable range (${fCurrency(maximumItemDiscrepancy)})`
                : ''
          }
          placement="top"
          arrow
        >
          <Box color={overMax ? 'error.main' : notExact ? 'warning.main' : 'black'}>
            {notExact && <Iconify icon={'material-symbols:error-outline'} width={13} height={13} />}
            {fCurrency(params.row.expectedItemPrice)}
          </Box>
        </Tooltip>
      );
    },
    valueGetter: (params) => {
      const overMax = Math.abs(params.row.price - params.row.expectedItemPrice) > maximumItemDiscrepancy;
      const notExact = params.row.price !== params.row.expectedItemPrice;
      return overMax
        ? `99999999${params.row.expectedItemPrice}`
        : notExact
          ? `88888888${params.row.expectedItemPrice}`
          : params.row.expectedItemPrice;
    },
  };
  const invoicedQtyWithUOM = {
    field: 'invoicedQtyWithUOM',
    headerName: 'Invoice/UOM',
    type: 'customText',
    valueGetter: (params) => {
      const expectedAmount = params.row.expectedAmount || '';
      const uomType = params.row.uomType || '';
      return `${expectedAmount} ${uomType}`;
    },
  };
  const poQtyWithUOM = {
    field: 'poQtyWithUOM',
    headerName: 'PO/UOM',
    type: 'customText',
    valueGetter: (params) => {
      const poQuantity = params.row.quantity;
      const uomType = params.row.poUOMType || '';
      return `${poQuantity} ${uomType}`;
    },
    renderCell: (params) => {
      const moreSentThenOrdered = params.row.quantity > params.row.expectedAmount;
      return (
      params.row.manual ? renderEditableNumber('quantity', params.row, false) :
        <>
          <Tooltip
            title={moreSentThenOrdered ? `More sent then ordered (${params.row.expectedAmount})` : ''}
            placement="top"
            arrow
          >
            <Box color={moreSentThenOrdered ? 'error.main' : 'black'}>
              {moreSentThenOrdered && <Iconify icon={'material-symbols:error-outline'} width={13} height={13} />}
              {fNumber(params.row.quantity)}&nbsp;
            </Box>
          </Tooltip>{' '}
          {params.row.poUOMType}
        </>
      );
    },
  };
  const actionButtons = (params) => {
    const { row } = params;
    return (
      <>
        {editable && (
          <ScopeGuard
            scopes={invoiceItemData?.invoice?.sourceMethod === 'Manual' ? ['ManualInvoice-Edit'] : ['Invoice-Edit']}
            allowAdmin
          >
            <IconButton
              color="error"
              actionButton="Delete"
              onClick={async () => {
                const rowToDelete = { ...row, deleteFlag: true, _action: 'delete' };
                setUnsavedData((prev) => ({
                  ...prev,
                  [row?.id]: rowToDelete,
                }));
                apiRef?.current?.updateRows([rowToDelete]);
              }}
            >
              <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
            </IconButton>
          </ScopeGuard>
        )}
      </>
    );
  }

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    renderCell: (params) => actionButtons(params),
    id: 'actions',
    className: 'actions',
  };
  const getColumns = useCallback(() => {
    const commonColumns = [
      description,
      vendorItemId,
      invoicedQtyWithUOM,
      poQtyWithUOM,
      price,
      expectedItemPrice,
      taxAmount,
      invoiceItemTotal,
      categoryDropdown,
      subCategoryDropdown,
      actions,
      manual
    ];

    return commonColumns;
  }, [invoiceItemData?.invoice?.multiFacility, invoiceGLItems]);

  const sortedItems = [...(invoiceItemData?.invoiceItems ?? [])]?.sort((a, b) => {
    if (
      (a?.subcategoryId === 0 && b?.subcategoryId !== 0) ||
      (a.price !== a.expectedItemPrice && b.price === b.expectedItemPrice) ||
      (a.quantity > a.expectedAmount && b.quantity <= b.expectedAmount)
    ) {
      return -1;
    }
    if (
      (a?.subcategoryId !== 0 && b?.subcategoryId === 0) ||
      (b.price !== b.expectedItemPrice && a.price === a.expectedItemPrice) ||
      (b.quantity > b.expectedAmount && a.quantity <= a.expectedAmount)
    ) {
      return 1;
    }
    return 0;
  });
  useEffect(() => {
    setRows(sortedItems || []);
  }, [invoiceItemData?.invoiceItems]);

  useEffect(() => {
    apiRef.current.updateColumns(getColumns());
  }, [invoiceItemData?.invoice?.multiFacility, getColumns, apiRef, invoiceItemData?.invoiceItems]);
  useEffect(() => {
    apiRef.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
  }, [highlightMissingGls]);

  const handleSave = async () => {
    const response = await dispatch(patchInvoiceItems(invoice.id, Object.values(unsavedData)));
    setUnsavedData({});
    if (response.status !== 200) {
      enqueueSnackbar('Failed to update invoice', { variant: 'error' });
    }
  };

  const handleRemoveGls = async () => {
    const response = await dispatch(deleteGlItems(id));
    if (response === 'success') {
      enqueueSnackbar('Gl items deleted successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('failed to delete gl items', { variant: 'error' });
    }
  };

  const CustomLeftToolbar = useCallback(
    () => (
      <>
        <Grid container direction="row" justifyContent="flex-start" spacing={2}  alignItems="flex-end">
          {invoiceGLItems.length === 0 && editable &&
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<Icon icon="material-symbols:add" width="1rem" height="1rem" />}
                onClick={addInvoiceItem}
                disabled={!hasEditAccess}
              >
                Add Invoice Item
              </Button>
            </Grid>}
          {invoiceGLItems?.length > 0 && (
            <Grid item>
              <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={handleRemoveGls}
              // sx={{ ml: 1, display: !hasUnsavedRows ? 'none' : 'block' }}
              >
                Unlock invoice items
            </Button>
          </Grid>
          )}
          {Object.values(unsavedData).length > 0 && (
            <>
              <Grid item>
                <Tooltip title={Object.values(unsavedData).some(item => (!('quantity' in item) || item.quantity === 0) && item.deleteFlag !== true) ?
                  'Quantity must be greater than 0': ''}>
                    <Button
                    disabled={Object.values(unsavedData).some(item => (!('quantity' in item) || item.quantity === 0) && item.deleteFlag !== true)}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={handleSave}>
                    save
                    </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  onClick={() => { setUnsavedData({}); setRows(sortedItems || []); } }>
                  Cancel
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </>
    ),
    [unsavedData, invoiceGLItems]
  );

  const addInvoiceItem = () => {
    const nextRowIndex = apiRef?.current?.getRowsCount();
    const newRow = {
      id: nextRowIndex,
      manual: true
    };
    apiRef?.current?.updateRows([newRow]);
    apiRef?.current?.scrollToIndexes({ rowIndex: 0 });
  };
  const handleSortModelChange = (newSortModel) => {
    if (Object.values(unsavedData)?.length === 0) 
      setSortModel(newSortModel);
    else
      setSortModel([]);
  };
  return (
    <>
      <Box sx={{ maxHeight: expandBottom ? '630px' : '430px' }}>
        {rows && (
          <CustomDataGrid
            gridId="invoicing-order-information"
            boxSX={{ width: '100%', height: expandBottom ? '600px' : '400px' }}
            data={rows}
            isModal
            apiRef={apiRef}
            gridColumns={getColumns()}
            isLoading={rows?.length === 0 && isLoading}
            CustomLeftToolbar={CustomLeftToolbar}
            sortModel={sortModel||[]}
            onSortModelChange={(model) => {
              handleSortModelChange(model);
            }}
            columnVisibilityModel={{
              manual: false, 
            }}
            getRowClassName={({ id }) => {
              if (
                highlightMissingGls &&
                rows
                  ?.filter((item) => item.agoraSubcategory.id === 0)
                  ?.map((x) => x.id)
                  ?.includes(id)
              )
                return 'row--highlight';
              return '';
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                marginY: '4px',
                marginRight: '8px',
                height: 38,

                '&:focus-within': { outline: `none`, border: `none` },
              },
              '& .MuiDataGrid-cell.MuiDataGrid-cell--editing, & .MuiDataGrid-cell--editable': {
                border: `solid 1px #404042`,
                borderRadius: '6px',
                fontSize: '0.8rem',
                fontWeight: '600',
                cursor: 'pointer',
              },
              '& .MuiDataGrid-columnHeader': {
                marginRight: '8px',
              },
              '& .MuiBadge-badge': {
                  display: 'none',
                },
              '& .MuiDataGrid-row.row--highlight': {
                backgroundColor: (theme) => {
                  if (theme.palette.mode === 'light') {
                    return 'rgba(143, 223, 130, 0.3)';
                  }
                  return 'rgba(143, 223, 130, 1)';
                },
              },
            }}
          />
        )}
      </Box>
    </>
  );
}

import { Badge, Box, IconButton, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { TabPanel } from '../../../../reusable-components/tab-panel';
import CompletedOrderInformation from './CompletedOrderInformation';
import InvoicePdfAttachment from './InvoicePdfAttachment';
import OrderInformation from './OrderInformation';

ReviewInvoice.propTypes = {
  selectedFileId: PropTypes.number,
  highlightMissingGls: PropTypes.bool,
  setOpenSidebar: PropTypes.func,
  setUnsavedData: PropTypes.func,
  unsavedData: PropTypes.object,
  vendor: PropTypes.string,
};

export default function ReviewInvoice({
  selectedFileId,
  highlightMissingGls,
  setOpenSidebar,
  setUnsavedData,
  unsavedData,
  vendor
}) {
  const [value, setValue] = useState(0);
  const [expandBottom, setExpandBottom] = useState(false);
  const [expandCompletedOrderInformation, setExpandCompletedOrderInformation] = useState(false);
  const { data: { invoiceItemData } } = useSelector((state) => state.invoicing);
  const { invoiceItems, maximumItemDiscrepancy, invoice } = invoiceItemData || {};
  const handleTabChange = (event, newValue) => {
    if (newValue === 1 || newValue === 2) setOpenSidebar(false);
    setValue(newValue);
  };

  useEffect(() => {
    if (selectedFileId !== 0) {
      setValue(0);
    }
  }, [selectedFileId]);

  useEffect(() => {
    if (highlightMissingGls) {
      setValue(1);
      setOpenSidebar(false);
    }
  }, [highlightMissingGls]);

  const countErrors = () =>
    invoiceItems?.reduce((accumulator, currentValue) => {
      const moreSentThenOrdered = currentValue.quantity > currentValue.expectedAmount;
      const overMax = Math.abs(currentValue.price - currentValue.expectedItemPrice) > maximumItemDiscrepancy;

      if (moreSentThenOrdered) accumulator++;
      if (overMax) accumulator++;
      return accumulator;
    }, 0);

  return (
    <>
      <Box sx={boxStyle}>
        <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: 'info.main' }}>Review invoice &nbsp;</Typography>

        <Box sx={detailsStyle}>
          <Tabs value={value} onChange={handleTabChange}>
            <Tab label="Attachment" />
            {invoice?.sourceMethod !== 'Manual' && (
              <Tab
                label={
                  <Badge
                    badgeContent={countErrors()}
                    color="error"
                    sx={{ '& .MuiBadge-badge': { top: -5 }, }}>
                    Electronic invoice
                  </Badge>
                }
              />
            )}
            {invoice?.sourceMethod !== 'Manual' && <Tab label="Previous order" />}
          </Tabs>
          <TabPanel value={value} index={0}>
            <InvoicePdfAttachment selectedFileId={selectedFileId} />
          </TabPanel>
          {invoice?.sourceMethod !== 'Manual' && (
            <TabPanel value={value} index={1}>
              <OrderInformation
                highlightMissingGls={highlightMissingGls}
                expandBottom={expandBottom}
                unsavedData={unsavedData}
                setUnsavedData={setUnsavedData}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton onClick={() => setExpandBottom(!expandBottom)} sx={{ color: 'info.main' }}>
                  <Iconify icon={`ic:twotone-expand-${!expandBottom ? 'more' : 'less'}`} width={26} height={26} />
                </IconButton>
              </Box>
            </TabPanel>
          )}
          {invoice?.sourceMethod !== 'Manual' && (
            <TabPanel value={value} index={2}>
              <CompletedOrderInformation expandBottom={expandCompletedOrderInformation} vendor={vendor} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton onClick={() => setExpandCompletedOrderInformation(expand => !expand)} sx={{ color: 'info.main' }}>
                  <Iconify icon={`ic:twotone-expand-${!expandCompletedOrderInformation ? 'more' : 'less'}`} width={26} height={26} />
                </IconButton>
              </Box>
            </TabPanel>
          )}
        </Box>
      </Box>
    </>
  );
}

const boxStyle = {
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  border: 'solid 1px #c1c9d0',
  width: '100%',
  mb: '20px',
  padding: '20px',
};

const detailsStyle = {
  paddingTop: '5px',
};

import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import ReportsView from '../../../components/dashboard/reports/ReportsView';

export default function Reports()
{
  return <>
    <Helmet>
      <title>Invoicing | Platform Solutions</title>
    </Helmet>

     <ReportsView />
  </>;
}

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPreviousOrderInfo, removeCartCheckedFromRedux } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import AwaitButton from '../../../../reusable-components/await-button/AwaitButton';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { fDate, fISODateTime, timestamp } from '../../../../utils/formatTime';
import { postApproveOrder } from '../../../../api/orders';
import { useTabs } from '../../../../context/TabContext';
import Iconify from '../../../../reusable-components/iconify/Iconify';
import { getUnreadData } from '../../../../redux/slices/dashboard';
import { fCurrency } from '../../../../utils/formatNumber';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';

ConfirmOrderDialog.propTypes = {
  id: PropTypes.string,
  allReqDates: PropTypes.array,
  openConfirm: PropTypes.bool,
  setOpenConfirm: PropTypes.func,
  altAddress: PropTypes.object,
};

export default function ConfirmOrderDialog({ id, allReqDates, openConfirm, setOpenConfirm, altAddress }) {
  const { orderById } = useSelector((state) => state.orders || {});
  const {
    items = [],
    orderNumber,
    vendorMinimums,
    requestedShipDate,
    facilityAddress,
    orderDelay,
    vendorDateMessages,
    facility,
    vendors,
    globalNote,
    removeGlobalNoteFromVendors
  } = orderById[id] || {};
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const navigate = useNavigate();
  const { addNewTabAndNavigate } = useTabs();

  const [orderDelayUntil, setOrderDelayUntil] = useState(orderDelay?.sendOrderAt);
  const [processingOrder, setProcessingOrder] = useState(false);
  const [showOrderDelayUntil, setShowOrderDelayUntil] = useState(false);

  const processOrderFunction = async () => {
    setProcessingOrder(true);
    dispatch(removeCartCheckedFromRedux());

    const response = await postApproveOrder({
      rerunOrder: filter === 'rerun',
      orderId: id,
      items: items?.filter((item) => item.approved && item.deleted !== true),
      address: altAddress,
      vendorDates: allReqDates.map((date) => ({
        vendorId: date.vendorId,
        date: fISODateTime(date.date),
      })),
      delayOrderInSeconds: orderDelayUntil
        ? Math.floor(Math.abs(new Date().getTime() - orderDelayUntil.getTime()) / 1000)
        : null,
      globalNote,
      removeGlobalNoteFromVendors
    });

    if (response.status !== 200) {
      enqueueSnackbar('Error processing order', { variant: 'error' });
      return;
    }
    if (!response.data.success && response.data.message === 'Something has gone wrong') {
      enqueueSnackbar(response.data.message, { variant: 'error' });
      return;
    }
    if (response.data.success && response.data.message === 'Order Delayed') {
      enqueueSnackbar(`Order successfully set. Order will be sent on ${timestamp(orderDelayUntil)}`, {
        variant: 'success',
      });
      navigate(`/dashboard/orders?filter=pending`);
      return;
    }
    const fullSuccess = response.data.message === 'Success';
    dispatch(getPreviousOrderInfo(id));
    enqueueSnackbar(
      `${!fullSuccess ? `${response.data.message}\n` : ''}Order #${orderNumber || id} has been ` +
        `${fullSuccess ? 'successfully' : 'partially'} processed!`,
      {
        variant: fullSuccess ? 'success' : 'error',
        style: { whiteSpace: 'pre-wrap' },
        autoHideDuration: 3000,
        action: (key) => (
          <>
            <Button
              onClick={() => addNewTabAndNavigate(`/dashboard/orders/${id}?filter=previous`)}
              color={fullSuccess ? 'secondary' : 'primary'}
              endIcon={<Iconify icon="material-symbols:open-in-new" />}
            >
              #{orderNumber || id}
            </Button>
            <Button onClick={() => closeSnackbar(key)} sx={{ color: '#fff' }}>
              X
            </Button>
          </>
        ),
      }
    );
    if (!fullSuccess) navigate(`/dashboard/orders/${id}?filter=previous`);
    else navigate(`/dashboard/orders?filter=pending`);
    dispatch(getUnreadData());
  };

  useEffect(() => {
    setOrderDelayUntil(orderDelay ? new Date(orderDelay.sendOrderAt) : null);
  }, [orderDelay]);

  const vendorTotals = items
    .filter((item) => item.approved)
    .reduce((acc, item) => {
      const { vendorId, price, quantity, vendor } = item;
      const total = price * quantity;
      const vendorTotal = acc.find((total) => total.vendorId === vendorId);
      if (vendorTotal) {
        vendorTotal.total += total;
        return acc;
      }
      acc.push({
        vendorId,
        vendor,
        total,
        allowsRequestedShipDate: vendors?.find((vendor) => vendor.id === item.vendorId)?.allowsRequestedShipDate,
        minimum: vendorMinimums?.find((min) => min.vendorId === vendorId)?.minimum ?? 0,
        requestedDate: allReqDates.find((date) => date.vendorId === vendorId)?.date,
        messages:
          vendorDateMessages?.filter(
            (message) => message.vendorId === vendorId && !message.relativeDate && !message.mainMessage
          ) || [],
      });
      return acc;
    }, []);

  const address = altAddress || facilityAddress;

  return (
    <Dialog fullWidth maxWidth="sm" open={openConfirm} onClose={() => setOpenConfirm(false)}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Box color="info.main">
          {showOrderDelayUntil && (
            <IconButton
              onClick={() => {
                setOrderDelayUntil(orderDelay?.sendOrderAt ? new Date(orderDelay?.sendOrderAt) : null);
                setShowOrderDelayUntil(false);
              }}
              sx={{
                position: 'absolute',
                left: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                fontWeight: 'bold',
              }}
            >
              <Iconify icon="ep:arrow-left-bold" width={24} height={24} />
            </IconButton>
          )}
          Order Summary <CloseIconButton onClick={() => setOpenConfirm(false)} />
        </Box>
        <Box sx={{ fontSize: '15px', fontWeight: 'normal' }}>
          {facility}{' '}
          <Box color="info.main" component="span">
            |
          </Box>{' '}
          PO#: {orderNumber}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {!showOrderDelayUntil && (
          <>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="info.main">
                Facility Address:
              </Typography>
              {`${address?.street || ''} ${address?.city || ''} ${address?.state || ''} ${address?.zip || ''}`.trim()}
            </Box>
            <Divider sx ={{bgcolor: "rgb(145, 158, 171)"}}/>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="info.main">
                Vendors:
              </Typography>
              {requestedShipDate && (
                <Box color="secondary.main">User requested ship date: {fDate(requestedShipDate, 'MMMM dd')}</Box>
              )}
              {vendorTotals.map((vendorTotal, index) => (
                <Box key={index}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {vendorTotal.vendor}:
                  </Typography>
                  <Box sx={{ ml: 1 }}>
                    <Box display="flex" alignItems="center">
                      Total: {fCurrency(vendorTotal.total)}
                      <Iconify
                        icon={
                          vendorTotal.total >= vendorTotal.minimum
                            ? 'material-symbols:check-circle-outline'
                            : 'mdi:minus-circle-outline'
                        }
                        style={{ marginLeft: '4px', cursor: 'auto', width: '17px', height: '17px' }}
                        color={vendorTotal.total >= vendorTotal.minimum ? 'primary' : 'secondary'}
                      />
                    </Box>
                    {vendorTotal.allowsRequestedShipDate ? (
                      <Grid display="flex">
                        {(vendorTotal.messages?.length > 0 || vendorTotal.requestedDate) && 'Ship date: '}
                        {(vendorTotal.messages?.map((message) => fDate(message.date, 'MMMM dd')) || []).join(', ')}
                        {vendorTotal.messages?.length > 0 && vendorTotal.requestedDate && ' | '}
                        {vendorTotal.requestedDate && (
                          <Box
                            color="secondary.main"
                            component="span"
                            display="inline-flex"
                            alignItems="center"
                            sx={{ ml: '2px' }}
                          >
                            <Iconify
                              icon="mdi:alert-circle-outline"
                              style={{ marginRight: '2px', cursor: 'auto', width: '17px', height: '17px' }}
                            />
                            Requested ship date {fDate(vendorTotal.requestedDate, 'MMMM dd')}
                          </Box>
                        )}
                      </Grid>
                    ) : (
                      <Box>Ship date: Vendor doesn't accept requested dates.</Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}

        {(orderDelay || showOrderDelayUntil) && filter !== 'rerun' && (
          <Box sx={orderDelay && !showOrderDelayUntil ? { mt: 2 } : {}}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="info.main">
              Delay Order:
            </Typography>
            {orderDelay && (
              <Typography variant="body2" color="secondary" sx={{ fontSize: '13px' }}>
                Order delayed by: {orderDelay.addedBy}
              </Typography>
            )}
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '12px' }}>
              Send order on
            </Typography>
            <DateTimePicker
              minDate={new Date()}
              slotProps={{
                textField: { size: 'small', sx: { width: '100%' } },
                actionBar: { actions: ['clear', 'cancel', 'accept'] },
              }}
              value={orderDelayUntil || null}
              onAccept={(dateValue) => {
                setOrderDelayUntil(dateValue);
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!showOrderDelayUntil && filter !== 'rerun' && (
          <Button variant="outlined" color="primary" onClick={() => setShowOrderDelayUntil(true)}>
            Delay Order
          </Button>
        )}
        <AwaitButton
          color="primary"
          size="small"
          variant="contained"
          loading={processingOrder}
          sx={{ mr: 1 }}
          onClick={processOrderFunction}
        >
          Process order
        </AwaitButton>
      </DialogActions>
    </Dialog>
  );
}

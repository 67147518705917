import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, Typography, Button, Tooltip } from '@mui/material';
import { GridRowModes, useGridApiRef, GridCellModes } from '@mui/x-data-grid-pro';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { invoiceGLItemsForm, getInvoice, changeInvoiceField } from '../../../../redux/slices/invoicing';
import { useScopeCheck, ScopeGuard } from '../../../../reusable-components/scopes';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { INVOICE_GL_ITEM } from '../../../../reusable-components/datagrid/invoiceColumns';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { fCurrency, twoDecimal } from '../../../../utils/formatNumber';
import usePrompt from '../../../../reusable-components/prompt/usePrompt';

GlItemsDataGrid.propTypes = {
  highlightedRows: PropTypes.bool,
  rows: PropTypes.array,
  openGlCodeSummary: PropTypes.bool,
  setOpenGlCodeSummary: PropTypes.func,
};
export default function GlItemsDataGrid({ highlightedRows, rows, openGlCodeSummary, setOpenGlCodeSummary }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    data: { invoiceData, invoiceGLItems, invoiceItemData },
  } = useSelector((state) => state.invoicing);
  const { categories: cats, manualCategories, subcategories: subcats, manualSubcategories } = invoiceData;

  const categories =
    invoiceItemData.invoice.sourceMethod === null ? cats : /*sourceMethod === "Manual"*/ manualCategories;
  const subcategories =
    invoiceItemData.invoice.sourceMethod === null ? subcats : /*sourceMethod === "Manual"*/ manualSubcategories;
 const facilities =
   invoiceItemData?.invoice?.sourceMethod === 'Manual' ? invoiceData.manualFacilities : invoiceData.facilities;

  const isLoading = useSelector((state) => state.invoicing.isLoading);
  const error = useSelector((state) => state.invoicing.error);
  const apiRef = useGridApiRef();
  const hasEditAccess = useScopeCheck(
    invoiceItemData?.invoice?.sourceMethod === 'Manual' ? ['ManualInvoice-Edit'] : ['Invoice-Edit'],
    true
  );
  const { enqueueSnackbar } = useSnackbar();
  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const [saveGls, setSaveGls] = useState(false);
  const [missingRequiredFields, setMissingRequiredFields] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [cellModesModel, setCellModesModel] = useState({});

  const unsavedChangesRef = useRef({
    unsavedRows: {},
    rowsBeforeChange: {},
  });
  usePrompt(hasUnsavedRows);
  const [isSaving, setIsSaving] = useState(false);
  const selectedData = (row) => {
    const selectedFacility = facilities?.find((facility) => facility.value === row?.facilityId);
    const selectedCategory = categories?.find(
      (category) => category.facilityId === row?.facilityId && category.categoryId === row?.agoraCategoryId
    );
    const selectedSubcategory = subcategories?.find(
      (sub) =>
        sub.facilityId === row?.facilityId &&
        sub.categoryId === row?.agoraCategoryId &&
        sub.subcategoryId === row?.agoraSubcategoryId
    );
    return {
      selectedFacility,
      selectedCategory,
      selectedSubcategory,
    };
  };
  const updateMissingFields = () => {
    const changedRows = unsavedChangesRef.current.unsavedRows;
    const unselectedFields = Object.values(changedRows).some(
      (row) => row.facilityId === undefined || row.agoraCategoryId === undefined || row.agoraSubcategoryId === undefined
    );
    setMissingRequiredFields(unselectedFields);
  };
  const processGlRowUpdate = async (newRow, oldRow) => {
    const rowId = newRow.id;
    const { selectedFacility, selectedCategory, selectedSubcategory } = selectedData(newRow);
    const updateRow = {
      ...newRow,
      facilityId: selectedFacility?.value,
      agoraCategoryId: selectedCategory?.categoryId,
      agoraCategory: selectedCategory?.category,
      agoraSubcategoryId: selectedSubcategory?.subcategoryId,
      agoraSubcategory: selectedSubcategory?.subcategory,
      glCode: selectedSubcategory?.glCode,
    };

    unsavedChangesRef.current.unsavedRows[rowId] = updateRow;

    //add only if the row is not a new one
    if (unsavedChangesRef.current.rowsBeforeChange[rowId] === undefined && newRow.glItemId !== 0) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }

    updateMissingFields();
    setHasUnsavedRows(true);
    return updateRow;
  };
  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);
  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => ({
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit },
      },
    }));
  }, []);

  const handleAddGlRowClick = () => {
    const nextRowIndex = apiRef?.current?.getRowsCount();

    const newRow = {
      agoraCategory: undefined,
      agoraCategoryId: undefined,
      agoraSubcategory: undefined,
      agoraSubcategoryId: undefined,
      amount: '',
      deleteFlag: false,
      memo: null,
      facility: '',
      facilityId: undefined,
      glCode: '',
      glItemId: 0,
      id: nextRowIndex,
      invoiceId: id,
      referenceNumber: null,
    };
    apiRef?.current?.updateRows([newRow]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [nextRowIndex]: { mode: GridRowModes.Edit, fieldToFocus: 'facilityId' },
    }));
  };
  const updateInvoiceShipping = async () => {
    const newShipping = invoiceGLItems.reduce(
      (accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.shipping),
      0
    );
    await dispatch(changeInvoiceField(id, { key: 'Shipping', value: newShipping }));
    setSaveGls(false);
  };
  useEffect(() => {
    if (saveGls && invoiceGLItems?.length > 0) updateInvoiceShipping();
  }, [saveGls, invoiceGLItems]);
  const saveGlItems = async () => {
    const unsavedIds = Object.values(unsavedChangesRef.current.unsavedRows).map((x) => x.id);
    //generated from items table and weren't save yet in the db and are not in unsaved
    if (invoiceGLItems?.length === 0 && rows?.length > 0) {
      const unchangedRows = rows.filter((row) => !unsavedIds.some((x) => x === row.id)); //but should be saved
      unchangedRows.forEach((row) => {
        unsavedChangesRef.current.unsavedRows[row.id] = row;
      });
    }
    const response = await dispatch(
      invoiceGLItemsForm({ invoiceGlItems: Object.values(unsavedChangesRef.current.unsavedRows) })
    );
    setHasUnsavedRows(false);
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    };
    if (response === 'success') {
      setSaveGls(true);
      enqueueSnackbar('Gl Items updated successfully', { variant: 'success' });
    } else enqueueSnackbar('Error updating Gl Items', { variant: 'error' });
  };
  const cancelEditGlItems = async () => {
    setHasUnsavedRows(false);
    const rowsBeforeChange = Object.values(unsavedChangesRef.current.rowsBeforeChange);
    const newRowsToDelete = Object.values(unsavedChangesRef.current.unsavedRows)
      .filter((x) => x.glItemId === 0)
      ?.map((row) => ({
        ...row,
        _action: 'delete',
      }));
    apiRef?.current?.updateRows(rowsBeforeChange);
    apiRef?.current?.updateRows(newRowsToDelete);
    apiRef?.current?.updateRows(rows);
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    };
  };
  const customToolbar = useCallback(
    () => (
      <>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
          <>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<Icon icon="material-symbols:add" width="1rem" height="1rem" />}
              onClick={handleAddGlRowClick}
              disabled={!hasEditAccess}
            >
              Add Gl code
            </Button>
            <Tooltip title={missingRequiredFields ? 'Missing required fields' : ''} placement="top" arrow>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color={missingRequiredFields ? 'error' : 'secondary'}
                  disabled={!hasUnsavedRows || missingRequiredFields || !hasEditAccess}
                  loading={isSaving}
                  type="submit"
                  sx={{ ml: 1 }}
                  onClick={saveGlItems}
                >
                  Save
                </Button>
              </Box>
            </Tooltip>

            <Button
              variant="outlined"
              size="small"
              color="inherit"
              disabled={!hasUnsavedRows}
              loading={isSaving}
              type="submit"
              sx={{ ml: 1 }}
              onClick={cancelEditGlItems}
            >
              Cancel
            </Button>
          </>
        </Grid>
      </>
    ),
    [apiRef, hasUnsavedRows, isSaving, missingRequiredFields]
  );
  const actionButtons = (params) => {
    const { row } = params;
    const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
    return (
      <>
        <ScopeGuard
          scopes={invoiceItemData?.invoice?.sourceMethod === 'Manual' ? ['ManualInvoice-Edit'] : ['Invoice-Edit']}
          allowAdmin
        >
          <ConfirmDialog
            icon="delete-outline"
            color="error"
            content={`Are you sure you want to delete this Gl item?`}
            actionButton="Delete"
            action={async () => {
              const rowToDelete = { ...row, deleteFlag: true, _action: 'delete' };
              if (row.glItemId === 0) {
                delete unsavedChangesRef.current.unsavedRows[row.id];
                apiRef?.current?.updateRows([rowToDelete]);
              } else {
                const response = await dispatch(invoiceGLItemsForm({ invoiceGlItems: [rowToDelete] }));
                if (response === 'success') {
                  await dispatch(getInvoice(id));
                  enqueueSnackbar('Gl Item deleted successfully', { variant: 'success' });
                } else enqueueSnackbar('Error deleting Gl Items', { variant: 'error' });
              }
            }}
          />
        </ScopeGuard>
      </>
    );
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    renderCell: (params) => actionButtons(params),
    id: 'actions',
    className: 'actions',
  };
  const facility = {
    field: 'facilityId',
    headerName: 'Facility',
    editable: hasEditAccess,
    minWidth: 100,
    type: 'singleSelect',
    valueOptions: facilities,
  };
  const glCategory = {
    field: 'agoraCategoryId',
    headerName: 'Category',
    editable: hasEditAccess,
    minWidth: 100,
    flex: 1,
    type: 'singleSelect',
    valueOptions: ({ row }) =>
      categories
        ?.filter((category) => category?.facilityId === row?.facilityId)
        ?.map((category) => ({ label: category.category, value: category.categoryId })),
    renderCell: ({ row }) => (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          fontSize: '0.75rem',
        }}
      >
        {
          categories
            ?.filter((category) => category.facilityId === row?.facilityId)
            ?.find((x) => x.categoryId === row.agoraCategoryId)?.category
        }
      </Box>
    ),
  };
  const glSubcategory = {
    field: 'agoraSubcategoryId',
    headerName: 'Gl code',
    editable: hasEditAccess,
    minWidth: 150,
    flex: 1,
    type: 'singleSelect',
    valueOptions: ({ row }) => {
      const existingGls = [...apiRef?.current?.getRowModels().values()]?.map((x) => ({
        glCode: x.glCode,
        facilityId: x.facilityId,
      }));
      const filteredSubcategories = subcategories?.filter((sub) => {
        const isDifferentGlCode = row?.glCode !== sub.glCode;
        const isNotInExistingGlCodes = !existingGls.some(
          (existing) => existing.glCode === sub.glCode && existing.facilityId === sub.facilityId
        );
        return (
          sub.facilityId === row?.facilityId &&
          sub.categoryId === row?.agoraCategoryId &&
          sub.glCode !== null &&
          (isDifferentGlCode ? isNotInExistingGlCodes : true)
        );
      });

      return (
        filteredSubcategories?.map((sub) => ({
          label: `${sub.subcategory}   ${sub.glCode || ''}`,
          value: sub.subcategoryId,
        })) || []
      );
    },

    renderCell: ({ row }) => (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          fontSize: '0.75rem',
        }}
      >
        {subcategories
          ?.filter((sub) => sub.facilityId === row?.facilityId && sub.categoryId === row?.agoraCategoryId)
          ?.find((x) => x.subcategoryId === row?.agoraSubcategoryId)?.glCode || ''}
      </Box>
    ),
  };
  const amount = {
    field: 'amount',
    headerName: 'Amount',
    type: 'customMoney',
    minWidth: 100,
    flex: 1,
    editable: hasEditAccess,
    renderCell: (params) => fCurrency(params.value),
    valueGetter: (params) => params.row.amount,
  };
  const shipping = {
    field: 'shipping',
    headerName: 'Shipping',
    type: 'customMoney',
    minWidth: 100,
    flex: 1,
    editable: hasEditAccess,
    renderCell: (params) => fCurrency(params.value),
    valueGetter: (params) => params.row.shipping,
  };
  const totalAmount = {
    headerName: 'Total amount',
    type: 'customMoney',
    minWidth: 100,
    flex: 1,
    renderCell: (params) => fCurrency(parseFloat(params.row.amount) + parseFloat(params.row.shipping)),
    valueGetter: (params) => parseFloat(params.row.amount) + parseFloat(params.row.shipping),
  };
  const glMemo = {
    field: 'memo',
    headerName: 'Memo',
    type: 'customText',
    minWidth: 100,
    editable: hasEditAccess,
  };
  const referenceNumber = {
    field: 'referenceNumber',
    headerName: 'Reference #',
    type: 'customText',
    minWidth: 100,
    editable: hasEditAccess,
  };

  const columns = [
    facility,
    glCategory,
    glSubcategory,
    amount,
    shipping,
    totalAmount,
    glMemo,
    referenceNumber,
    ...INVOICE_GL_ITEM,
    actions,
  ];
  useEffect(() => {}, [openGlCodeSummary]);
  useEffect(() => {
    updateMissingFields();
  }, [unsavedChangesRef.current.unsavedRows]);

  return (
    <>
      <Box sx={accordionBoxStyle}>
        <Accordion
          expanded={openGlCodeSummary}
          onChange={(e, newValue) => setOpenGlCodeSummary(newValue)}
          sx={accordionStyle}
        >
          <AccordionSummary
            sx={{
              color: 'info.main',
            }}
            expandIcon={<Iconify icon="ic:outline-expand-more" color="info" height="24px" width="24px" />}
          >
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}> GL Code summary &nbsp;</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {rows && (
              <Box sx={{ maxHeight: '400px' }}>
                <CustomDataGrid
                  gridId="invoicing-invoice-overview-gl-items"
                  boxSX={{ width: '100%', height: '350px' }}
                  data={rows}
                  gridColumns={columns}
                  apiRef={apiRef}
                  disableRowSelectionOnClick
                  isModal
                  CustomLeftToolbar={customToolbar}
                  processRowUpdate={processGlRowUpdate}
                  onCellClick={handleCellClick}
                  cellModesModel={cellModesModel}
                  onCellModesModelChange={handleCellModesModelChange}
                  sx={{
                    '& .MuiDataGrid-cell.cell--required': {
                      backgroundColor: 'rgba(255, 170, 170, 1)',
                    },
                    '& .MuiDataGrid-cell': {
                      marginY: '4px',
                      marginRight: '8px',
                      height: 38,
                    },
                    '& .MuiDataGrid-cell.MuiDataGrid-cell--editing, & .MuiDataGrid-cell--editable': {
                      border: `solid 1px #404042`,
                      borderRadius: '6px',
                      fontSize: '0.8rem',
                      fontWeight: '600',
                      cursor: 'pointer',
                    },
                    '& .MuiDataGrid-columnHeader': {
                      marginRight: '8px',
                    },

                    '& .MuiDataGrid-row.row--edited': {
                      backgroundColor: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return 'rgba(255, 254, 176, 0.3)';
                        }
                        return 'rgba(255, 254, 176, 1)';
                      },
                    },
                    '& .MuiDataGrid-row.row--highlight': {
                      backgroundColor: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return 'rgba(143, 223, 130, 0.3)';
                        }
                        return 'rgba(143, 223, 130, 1)';
                      },
                    },
                  }}
                  getRowClassName={({ id }) => {
                    const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
                    if (highlightedRows?.includes(id)) return 'row--highlight';
                    if (unsavedRow) {
                      if (unsavedRow._action === 'delete') {
                        return 'row--removed';
                      }
                      return 'row--edited';
                    }

                    return '';
                  }}
                  getCellClassName={(params) => {
                    const { id, field } = params;
                    const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
                    if (unsavedRow) {
                      const { selectedFacility, selectedCategory, selectedSubcategory } = selectedData(unsavedRow);
                      if (
                        (selectedFacility === undefined && field === 'facilityId') ||
                        (selectedCategory === undefined && field === 'agoraCategoryId') ||
                        (selectedSubcategory === undefined && field === 'agoraSubcategoryId')
                      ) {
                        return 'cell--required';
                      }
                    }
                    return '';
                  }}
                  loading={isSaving}
                  isLoading={isLoading}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                        glItemId: false,
                        deleteFlag: false,
                      },
                    },
                  }}
                />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

const accordionBoxStyle = {
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  border: 'solid 1px #c1c9d0',
  width: '100%',
  mb: '20px',
};

const accordionStyle = {
  width: '100%',
  '&.Mui-expanded': {
    minHeight: '500px',
    maxHeight: '500px',
  },
};

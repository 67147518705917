import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { postAddTicketImage } from '../../../../api/tickets';
import { sendTicketMessage } from '../../../../redux/slices/tickets';
import { useDispatch, useSelector } from '../../../../redux/store';
import Editor from '../../../../reusable-components/editor';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import { useSettingsCheck } from '../../../../reusable-components/settings';
import { useTabs } from '../../../../context/TabContext';

// ----------------------------------------------------------------------

export default function TicketMessageInput() {
  const location = useLocation();
  const { id = '' } = useParams();
  const subticketId = new URLSearchParams(location.search).get('subticket');

  const { users } = useSelector((state) => state.tickets.data.ticketInfoById[id || subticketId]) || [];

  const signature = useSettingsCheck('AutoSignatureOnTickets');
  const defaultMessage = signature?.show ? signature?.value : '';

  const { ticketNumber } = useSelector((state) => state.tickets.data.ticketById[subticketId || id]) || {};
  const { user } = useSelector((state) => state.user);
  const { activeTab } = useTabs();

  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;
  const sessionStorageKey = `tab-${activeTab.key}-editor-${baseUrl}`;

  const loadMessageFromSession = () => {
    const savedMessage = sessionStorage.getItem(sessionStorageKey);
    return savedMessage ? JSON.parse(savedMessage) : defaultMessage;
  };

  const clearMessageFromSession = () => {
    sessionStorage.removeItem(sessionStorageKey);
  };

  const dispatch = useDispatch();

  const [ticketEmailsOn, setTicketEmailsOn] = useState(true);
  const [message, setMessage] = useState(loadMessageFromSession);
  useEffect(() => {
    setMessage(loadMessageFromSession);
  }, [location]);

  const [attachments, setAttachments] = useState([]);

  const handleChangeMessage = (value) => {
    value = value.replace(/<h1>/g, '<b>').replace(/<\/h1>/g, '</b>');
    value = value.replace(/<h2>/g, '<b>').replace(/<\/h2>/g, '</b>');
    value = value.replace(/<h3>/g, '<b>').replace(/<\/h3>/g, '</b>');
    value = value.replace(/<h4>/g, '<b>').replace(/<\/h4>/g, '</b>');
    value = value.replace(/<h5>/g, '<b>').replace(/<\/h5>/g, '</b>');
    value = value.replace(/<h6>/g, '<b>').replace(/<\/h6>/g, '</b>');

    if (value.includes('<img src=')) {
      const imgSrcRegex = /<img src="([^"]*)"/g;
      const imgSrcMatch = value.matchAll(imgSrcRegex);
      const imgSrcArray = Array.from(imgSrcMatch, (match) => ({ src: match[1] }));
      imgSrcArray.forEach(async (img) => {
        if (!img.src.includes('//res.cloudinary.com/agoraserv')) {
          const imgUrl = await postAddTicketImage(img.src);
          value = value.replace(img.src, imgUrl.data);
          setMessage(value);
        }
      });
    }
    setMessage(value);
  };

  const handleFileInputChange = (event) => {
    const maxAllowedFiles = 5;
    if (
      event.target.files.length > maxAllowedFiles ||
      event.target.files.length + attachments?.length > maxAllowedFiles
    ) {
      alert(`Only ${maxAllowedFiles} files can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    const { files } = event.target;
    const fileList = [];
    for (let i = 0; i < files.length; i++) {
      if (attachments?.some((x) => x.name === files[i].name)) {
        alert(`File ${files[i].name} already exists.`);
      } else fileList.push(files[i]);
    }
    setAttachments((prevAttachments) => {
      if (prevAttachments === undefined) {
        return fileList;
      }
      return [...prevAttachments, ...fileList];
    });
  };
  const removeFile = (file) => () => {
    setAttachments((prevAttachments) => prevAttachments.filter((x) => x !== file));
  };

  const htmlFormatter = async (html) => {
    const pattern = /<p><br><\/p>$/i;
    while (pattern.test(html)) {
      html = html.replace(pattern, '');
    }
    const imgSrcRegex = /<img src="([^"]*)"/g;
    const imgSrcMatch = html.matchAll(imgSrcRegex);
    const imgSrcArray = Array.from(imgSrcMatch, (match) => ({ src: match[1] }));
    const convertedImgPromises = imgSrcArray.map(async (img) => {
      if (!img.src.includes('//res.cloudinary.com/agoraserv')) {
        const imgUrl = await postAddTicketImage(img.src);
        html = html.replace(img.src, imgUrl.data);
      }
    });
    await Promise.all(convertedImgPromises);
    return html;
  };

  const listOfUsers = async (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const mentionElements = doc.querySelectorAll('span.mention');
    const users = Array.from(mentionElements).map((mention) => mention.getAttribute('data-id'));
    return users;
  };

  const handleSend = async () => {
    if (!message && attachments?.length === 0) return;
    // if ((!message && attachments?.length === 0) || message === defaultMessage) return;
    const formattedHtml = await htmlFormatter(message);
    const usersList = await listOfUsers(message);
    usersList.push(user.email);
    try {
      await dispatch(
        sendTicketMessage({
          message: formattedHtml,
          ticketNumber: subticketId || ticketNumber || id,
          files: attachments,
          users: usersList,
          notify: ticketEmailsOn,
          sentBy: user.email,
          fullName: `${user.firstName} ${user.lastName}`,
        })
      );

      setMessage(defaultMessage);
      setAttachments([]);
    } catch (error) {
      console.error(error);
    }
    clearMessageFromSession();
  };


  return (
    <Box>
      {users && users?.length > 0 ? (
        <>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Stack direction="column" alignItems="flex-end" sx={{ pt: 1, flexGrow: 1 }}>
              <Editor
                attachFile
                simple
                id="ticket-reply"
                value={message}
                onChange={handleChangeMessage}
                placeholder="Type a message"
                sx={{ flexGrow: 1, borderColor: 'transparent' }}
                users={users}
                handleFileInputChange={handleFileInputChange}
                emailSwitch
                sendButton
                handleSend={handleSend}
                ticketEmailsOn={ticketEmailsOn}
                setTicketEmailsOn={setTicketEmailsOn}
              />
            </Stack>
          </Box>

          <Box>
            <Stack direction="row" alignItems="flex-end">
              {attachments?.map((file) => (
                <FileThumbnail
                  key={file.name}
                  file={file}
                  tooltip
                  sx={{ width: 64, height: 64 }}
                  onRemove={removeFile(file)}
                />
              ))}
            </Stack>
          </Box>
        </>
      ) : (
        //no users input
        <Editor
          attachFile
          simple
          id="ticket-reply"
          value={message}
          onChange={handleChangeMessage}
          placeholder="Type a message"
          sx={{ flexGrow: 1, borderColor: 'transparent' }}
          handleFileInputChange={handleFileInputChange}
          emailSwitch
          sendButton
          handleSend={handleSend}
          ticketEmailsOn={ticketEmailsOn}
          setTicketEmailsOn={setTicketEmailsOn}
        />
      )}
    </Box>
  );
}

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import { Budgets } from '.';
import FacilityBudget from './FacilityBudget';
import { getBudget } from '../../../../redux/slices/budget';
import { useDispatch } from '../../../../redux/store';

BudgetsView.propTypes = {
  selectedRow: PropTypes.object,
};

export default function BudgetsView({ selectedRow }) {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);

  const fetchData = async () => {
    try {
      await dispatch(getBudget());
    } catch (error) {
      console.error('Error fetching budget data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const renderTabContent = () => {
    switch (tab) {
      case 0:
        return <FacilityBudget selectedRow={selectedRow} />;
      default:
        return <Budgets selectedRow={selectedRow} />;
    }
  };

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="transparent"
            sx={{
              borderRadius: '4px 4px 0px 0px',
            }}
          >
            <CustomTab
              label={
                <>
                  <Box>
                    <Typography variant="subtitle1" color="success.contrastText" component="span">
                      {selectedRow ? `${selectedRow.shortName} Budgets view` : 'Facility Budgets'}
                    </Typography>
                  </Box>
                </>
              }
            />
            <CustomTab
              label={
                <>
                  <Box>
                    <Typography variant="subtitle1" color="success.contrastText" component="span">
                      {selectedRow ? selectedRow.shortName : 'All'} Budgets
                    </Typography>
                  </Box>
                </>
              }
            />
          </Tabs>
        </Box>
      </Grid>

      {renderTabContent()}
    </Box>
  );
}

const CustomTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '250px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

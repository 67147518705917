import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Collapse, Typography, Grid, Link, Tooltip, Box, IconButton, Stack } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendIcon from '@mui/icons-material/Send';
import { useSelector, useDispatch } from '../../../../redux/store';
import Scrollbar from '../../../../reusable-components/scrollbar';
import Iconify from '../../../../reusable-components/iconify';
import { fDateTime } from '../../../../utils/formatTime';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import CommentEditor from '../../../../reusable-components/comment-editor';
import { addInvoiceLog, deleteInvoiceLog } from '../../../../redux/slices/invoicing';
import { getUserData } from '../../../../api/account';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { LogContainer } from './reusable';

ChatTemplate.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.element,
  logEntry: PropTypes.object,
  setHighlightCommentId: PropTypes.func,
  highlightCommentId: PropTypes.number,
};
function ChatTemplate({ icon, message, logEntry, highlightCommentId, setHighlightCommentId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const addedBy = logEntry?.user?.fullName;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const {
    data: { invoiceLogData, invoiceItemData },
  } = useSelector((state) => state.invoicing);
  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 55);
    }
  }, [message]);
  function nameWithEmail() {
    return (
      <>
        {' '}
        <Tooltip
          onClick={handleCopyToClipboard}
          title={
            <Stack direction={'row'}>
              <Icon icon="ph:copy-bold" width="1rem" height="1rem" /> &nbsp;{logEntry?.user?.email}
            </Stack>
          }
          arrow
        >
          <Typography
            color="text.secondary"
            sx={{
              fontSize: '12px',
              display: 'inline',
            }}
          >
            {addedBy}&nbsp;
          </Typography>
        </Tooltip>
      </>
    );
  }
  function responseInfo() {
    const parentComment = invoiceLogData?.find((x) => x.id === logEntry?.parentCommentId);
    const parentFirstName = parentComment?.user?.fullName.split(' ')[0];
    return (
      <>
        {nameWithEmail()}&nbsp;
        <Typography
          color="text.secondary"
          sx={{
            fontSize: '12px',
            display: 'inline',
          }}
        >
          replied to
        </Typography>
        &nbsp;
        <Link
          color="primary"
          onClick={() => {
            setHighlightCommentId(parentComment.id);
          }}
          sx={{
            fontSize: '12px',
            textDecoration: 'underline',
            display: 'inline',
          }}
        >
          @{parentFirstName}
        </Link>
      </>
    );
  }
  function addedByInfo() {
    return (
      <>
        {logEntry?.user?.id === '0' ? (
          <Typography
            color="text.secondary"
            sx={{
              fontSize: '12px',
              display: 'inline',
            }}
          >
            {addedBy}&nbsp;
          </Typography>
        ) : (
          nameWithEmail()
        )}
      </>
    );
  }
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(logEntry?.user?.email)
      .then(() => {
        enqueueSnackbar('Email copied to clipboard!', {
          variant: 'info',
        });
      })
      .catch((err) => {
        console.error('Failed to copy email: ', err);
      });
  };
  return (
    <Box id={`comment-${logEntry.id}`}>
      <LogContainer
        title={fDateTime(logEntry?.dateAdded)}
        pointer={isOverflowing}
        highlight={highlightCommentId === logEntry.id}
        icon={icon}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <Box sx={{ flex: 1, whiteSpace: 'normal', wordBreak: 'break-word' }}>
          <Collapse collapsedSize={55} in={expanded}>
            <Box ref={contentRef} sx={{ fontSize: '14px', display: 'flex', alignItems: 'center' }} minHeight={55}>
              {message}
            </Box>
          </Collapse>
        </Box>
        {isOverflowing && (
          <Box>
            <Iconify
              icon={!expanded ? 'ic:outline-expand-more' : 'ic:outline-expand-less'}
              color="info"
              height="18px"
              width="18px"
            />
          </Box>
        )}
        {user.id === logEntry.user.id && (
          <Box>
            <ScopeGuard
              scopes={invoiceItemData?.invoice?.sourceMethod === 'Manual' ? ['ManualInvoice-Edit'] : ['Invoice-Edit']}
              allowAdmin
            >
              <ConfirmDialog
                icon="delete-outline"
                title="Delete"
                color="error"
                content={`Are you sure you want to delete this comment?`}
                actionButton="Delete"
                action={async (e) => {
                  const response = await dispatch(deleteInvoiceLog(logEntry.id));
                  if (response === 'success') {
                    enqueueSnackbar('comment has been deleted.', { variant: 'success' });
                  } else enqueueSnackbar('Failed to delete comment. ', { variant: 'error' });
                }}
              />
            </ScopeGuard>
          </Box>
        )}
      </LogContainer>
      <Grid container justifyContent={'end'} p={0.5}>
        {logEntry?.parentCommentId === null ? addedByInfo() : responseInfo()}
      </Grid>
    </Box>
  );
}
InvoiceComment.propTypes = {
  logEntry: PropTypes.object,
  setComment: PropTypes.func,
  setNewLog: PropTypes.func,
  setParentCommentId: PropTypes.func,
  setHighlightCommentId: PropTypes.func,
  highlightCommentId: PropTypes.number,
};
function InvoiceComment({
  logEntry,
  setComment,
  setNewLog,
  setParentCommentId,
  highlightCommentId,
  setHighlightCommentId,
}) {
  const { user } = useSelector((state) => state.user);
  function newComment(e) {
    e.stopPropagation();
    setParentCommentId(logEntry.id);
    setComment(`
<p>
  <span
    class="mention"
    data-index="1"
    data-denotation-char=""
    data-value="${logEntry?.user?.fullName}"
    data-id="${logEntry?.user?.email}"
  >
    <span contenteditable="false">
      <span class="ql-mention-denotation-char"></span>
      ${logEntry?.user?.fullName}
    </span>
  </span>
</p>
`);
    setNewLog(true);
  }
  const htmlMessage = (
    <>
      <Box>
        <div dangerouslySetInnerHTML={{ __html: logEntry.extraInfo }} />
        <Link color="secondary" onClick={(e) => newComment(e)}>
          <Typography variant="subtitle2" color="secondary" component="span">
            Click to answer
          </Typography>
        </Link>
      </Box>
    </>
  );
  return (
    <ChatTemplate
      icon={'bi:chat'}
      message={htmlMessage}
      logEntry={logEntry}
      highlightCommentId={highlightCommentId}
      setHighlightCommentId={setHighlightCommentId}
    />
  );
}

NewInvoiceComment.propTypes = {
  setNewLog: PropTypes.func,
  parentCommentId: PropTypes.string,
  comment: PropTypes.string,
  setComment: PropTypes.func,
};
function NewInvoiceComment({ setNewLog, comment, setComment, parentCommentId = '' }) {
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const {
    data: { invoiceViewers },
  } = useSelector((state) => state.invoicing);

  async function extractMentions(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Select all spans with the "mention" class
    const mentionElements = doc.querySelectorAll('span.mention');

    // Extract the relevant data (data-value or data-id)
    const mentions = Array.from(mentionElements).map((mention) => ({
      name: mention.getAttribute('data-value'), // Get the mention name
      email: mention.getAttribute('data-id'), // Get the email or ID
    }));

    return mentions;
  }

  const saveNewComment = async () => {
    const mentions = await extractMentions(comment);

    const invoiceUrl = window.location.href;
    const response = await dispatch(
      addInvoiceLog({
        invoiceId: id,
        messageType: 'Comment',
        extraInfo: comment,
        mentions,
        parentCommentId,
        invoiceUrl,
      })
    );
    if (response === 'success') {
      enqueueSnackbar('Your comment has been added.', { variant: 'success' });
    } else enqueueSnackbar('Failed to add comment. ', { variant: 'error' });
  };
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const handleChange = (value) => {
    setComment(value);
  };

  return (
    <>
      <Box sx={{ borderRadius: '6px', border: 'solid 1px #4ca5e7', mb: '20px' }}>
        <Box>
          <CommentEditor value={comment} onChange={handleChange} users={invoiceViewers} />
        </Box>

        <Box>
          <Grid item sx={{ borderTop: 'solid 1px #c1c9d0' }}>
            <Grid container justifyContent={'end'}>
              <Grid>
                <IconButton color="error" onClick={() => setNewLog(false)} id="action-button" className="action-button">
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
              <Grid>
                <AwaitButton
                  color="info"
                  sx={{ padding: 1, borderRadius: '50%', minWidth: 0 }}
                  onClick={saveNewComment}
                  id="action-button"
                  className="action-button"
                >
                  <SendIcon />
                </AwaitButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export const InvoiceChat = () => {
  const {
    data: { invoiceLogData, invoiceItemData },
  } = useSelector((state) => state.invoicing);
  const [newLog, setNewLog] = useState(false);
  const hasEditAccess = useScopeCheck(
    invoiceItemData?.invoice?.sourceMethod === 'Manual' ? ['ManualInvoice-Edit'] : ['Invoice-Edit'],
    true
  );
  const scrollbarRef = useRef(null);

  const [comment, setComment] = useState('');
  const [parentCommentId, setParentCommentId] = useState('');
  const [highlightCommentId, setHighlightCommentId] = useState(0);
  useEffect(() => {
    setNewLog(false);
  }, [invoiceLogData]);
  useEffect(() => {
    if (highlightCommentId !== 0 && scrollbarRef.current) {
      const highlightedComment = document.getElementById(`comment-${highlightCommentId}`);
      if (highlightedComment) {
        const container = scrollbarRef.current;
        const commentTop = highlightedComment.offsetTop;
        // highlightedComment.scrollIntoView({ behavior: 'smooth', block: 'center' });
        container.scrollTo({
          top: commentTop,
          behavior: 'smooth',
        });
      }
      const timeout = setTimeout(() => {
        setHighlightCommentId(0);
      }, 1000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [highlightCommentId]);
  return (
    <>
      <Box
        sx={{
          borderRadius: '4px',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          backgroundColor: '#fff',
          border: 'solid 1px #c1c9d0',
        }}
      >
        <Box
          sx={{
            padding: '15px 25px',
          }}
        >
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: 'info.main' }}>Chat</Typography>
            </Grid>
            <Grid item>
              <Tooltip title={'Comment'}>
                <IconButton
                  color="secondary"
                  onClick={() => {
                    setNewLog(true);
                    setComment('');
                  }}
                  disabled={!hasEditAccess}
                >
                  <Iconify icon={'bi:chat'} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Scrollbar sx={{ maxHeight: '400px', mx: 3 }} scrollableNodeProps={{ ref: scrollbarRef }}>
          <Box>
            {newLog && (
              <NewInvoiceComment
                setNewLog={setNewLog}
                comment={comment}
                setComment={setComment}
                parentCommentId={parentCommentId}
              />
            )}
            {invoiceLogData
              ?.filter((x) => x.messageType === 'Comment')
              ?.map((logEntry) => {
                switch (logEntry.messageType) {
                  case 'Comment':
                    return (
                      <InvoiceComment
                        setNewLog={setNewLog}
                        logEntry={logEntry}
                        setComment={setComment}
                        setParentCommentId={setParentCommentId}
                        highlightCommentId={highlightCommentId}
                        setHighlightCommentId={setHighlightCommentId}
                      />
                    );
                  default:
                    return <div />;
                }
              })}
          </Box>
        </Scrollbar>
      </Box>
    </>
  );
};

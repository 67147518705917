import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { postAddCategory, postAddSubcategory } from '../../../api/rentals';
import { getCategoryData } from '../../../redux/slices/rentals';
import { useDispatch, useSelector } from '../../../redux/store';
import { AwaitButton } from '../../../reusable-components/await-button';
import Iconify from '../../../reusable-components/iconify';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { listOfEmojis } from '../../../utils/cssStyles';

AddRentalCategory.propTypes = {
  description: PropTypes.string,
};

export default function AddRentalCategory({ description }) {
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState('fluent-emoji-flat:plus');

  const { enqueueSnackbar } = useSnackbar();
  const {
    data: { categoryData },
  } = useSelector((state) => state.rentals);
  const dispatch = useDispatch();
  const seenCategories = new Set();

  const categoryOptions = useMemo(
    () =>
      categoryData.reduce((acc, item) => {
        if (!seenCategories.has(item.categoryName)) {
          seenCategories.add(item.categoryName);
          acc.push({
            label: item.categoryName,
            id: item.categoryId,
          });
        }
        return acc;
      }, []),
    [categoryData]
  );

  const [category, setCategory] = useState(null);
  const [name, setName] = useState('');
  const [openIcons, setOpenIcons] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setCategory(null);
    setName('');
  };
  const handleSave = async () => {
    if (!name) {
      enqueueSnackbar('Name is required', 'error');
      return;
    }
    if (description === 'Category') {
      const response = await postAddCategory(name, icon);
      if (!response.data.success) {
        enqueueSnackbar('Category already exists', { variant: 'error' });
        return;
      }
    } else {
      const response = await postAddSubcategory(name, category.id);
      if (!response.data.success) {
        enqueueSnackbar('Subcategory already exists', { variant: 'error' });
        return;
      }
    }
    enqueueSnackbar(`${description} added`, { variant: 'success' });
    dispatch(getCategoryData());
    handleClose();
  };

  const handleClickOpenIcons = () => {
    setOpenIcons(true);
  };
  const handleCloseIcons = () => {
    setOpenIcons(false);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ mr: 1 }}
        size="small"
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
        startIcon={<Iconify icon="material-symbols:add" />}
      >
        Add New {description}
      </Button>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Add New {description}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          {description === 'Subcategory' && (
            <>
              <DialogContentText sx={{ fontWeight: 'bold' }}>Select Category</DialogContentText>
              <Autocomplete
                sx={{
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': 'none',
                }}
                size="small"
                fullWidth
                type="new-password"
                options={categoryOptions}
                autoHighlight
                getOptionLabel={(option) => option?.label}
                value={category}
                onChange={(event, newValue) => {
                  setCategory(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />{' '}
            </>
          )}

          <>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Name</DialogContentText>{' '}
            <TextField
              value={name || ''}
              onChange={(e) => setName(e.target.value)}
              label=""
              size="small"
              variant="outlined"
              sx={{ width: '100%' }}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
          </>
        </DialogContent>
        {description === 'Category' && (
          <>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2, ml: 3 }}>Choose an Icon</DialogContentText>{' '}
            <Tooltip title="Click to Choose an Icon">
              <Iconify onClick={handleClickOpenIcons} icon={icon} width={40} height={40} sx={{ ml: 3, mt: 2 }} />
            </Tooltip>
            <Popover
              open={Boolean(openIcons)}
              anchorEl={openIcons}
              onClose={handleCloseIcons}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid container spacing={1} sx={{ p: 2 }}>
                {listOfEmojis?.map((emoji, index) => (
                  <Grid
                    item
                    xs={1}
                    key={index}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{ cursor: 'pointer', height: '100%' }}
                  >
                    <Grid item>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setIcon(emoji);
                          handleCloseIcons();
                        }}
                      >
                        <Iconify icon={emoji} width={40} height={40} />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Popover>
          </>
        )}
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150, mr: 2 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

import PropTypes from 'prop-types';
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import { useDispatch } from '../../../../../redux/store';
import { updateUsersRoleValuesInRedux } from '../../../../../redux/slices/users';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import CloseIconButton from '../../../../../reusable-components/basic-buttons/CloseIconButton';
import RolesValueTable from './RoleValueTable';

RolesModal.propTypes = {
  roleType: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default function RolesModal({ roleType, open, close })
{
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [tempRoles, setTempRoles] = useState([]);

   const handleClose = () => {
    setTempRoles([]);
    close();
  };

  const handleSave = () => {
    dispatch(updateUsersRoleValuesInRedux(tempRoles));
    enqueueSnackbar('Roles updated you must still press save to save user roles changes ', { variant: 'success' });
    close();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {roleType.label}
          <CloseIconButton onClick={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <RolesValueTable roleType={roleType} tempRoles={tempRoles} setTempRoles={setTempRoles} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={handleSave}>
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
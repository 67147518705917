import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getVendorIntegrations, deleteVendorIntegration } from '../../../../api/warehouse-vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import AddButton from '../../../../reusable-components/basic-buttons/AddButton';
import EditIconButton from '../../../../reusable-components/basic-buttons/EditIconButton';
import WarehouseVendorIntegrationModal from './WarehouseVendorIntegrationModal';

WarehouseVendorIntegrations.propTypes = {
  vendorList: PropTypes.array,
};

export default function WarehouseVendorIntegrations({ vendorList }) {
  const { enqueueSnackbar } = useSnackbar();
  const [integrations, setIntegrations] = useState([]);
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [integrationFieldTypes, setIntegrationFieldTypes] = useState([]);
  const [types, setTypes] = useState([]);

  const fetchVendorIntegrations = async () => {
    setIsLoading(true);
    try {
      const response = await getVendorIntegrations();
      if (response.status === 200) {
        const types = response.data.integrationTypes.map(type => ({ value: type.id, label: type.name, type: type.type }));
        response.data.siteLinks = response.data.siteLinks.map((siteLink) => ({
          ...siteLink,
          site: response.data.sites.find((site) => site.value === siteLink.siteId)?.label,
        }));

        const result = response.data.integrationConfigListItems.map((item, index) => ({
          ...item,
          indexId: index,
          type: types.find((type) => type.value === item.integrationTypeId),
          vendor: vendorList.find((vendor) => vendor.id === item.mainVendorId)?.name,
          sites: item.configId > 0 ? response.data.siteLinks.filter((siteLink) =>
            (item.integrationTypeId === 1 ? siteLink.cxmlConfigId :
              item.integrationTypeId === 2 ? siteLink.punchoutConfigId :
                item.integrationTypeId === 3 ? siteLink.cartCheckConfigId :
                  item.integrationTypeId === 5 ? siteLink.jsonOrderConfigId :
                    item.integrationTypeId === 6 ? siteLink.excelOrderImportConfigId :
                    -1) === item.configId) : [],
        }));
        setTypes(types);
        setSites(response.data.sites);
        setIntegrationFieldTypes(response.data.integrationFieldTypes);    
        setIntegrations(result);
      } else enqueueSnackbar('Error fetching vendor integrations', { variant: 'error' });
    } catch {
      enqueueSnackbar('Error fetching vendor integrations', { variant: 'error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (vendorList?.length > 0) fetchVendorIntegrations();
  }, [vendorList]);

  const handleDelete = async (id) => {
    try {
      const response = await deleteVendorIntegration(id);
      if (response.status === 200) {
        enqueueSnackbar('Vendor integration deleted successfully', { variant: 'success' });
        fetchVendorIntegrations();
      } else {
        enqueueSnackbar('Problem deleting vendor integration', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Problem deleting vendor integration', { variant: 'error' });
      console.error('Error deleting vendor integration:', error);
    }
  };

  const mainVendorCol = {
    field: 'vendor',
    headerName: 'Vendor',
    minWidth: 100,
    flex: 0.5,
  };

  const nameCol = {
    field: 'name',
    headerName: 'Name',
    minWidth: 100,
    flex: 1,
  };

  const sitesCol = {
    field: 'sites',
    headerName: 'Sites Using This Integration',
    valueGetter: (params) => params.row.sites.map((siteLink) => siteLink.site).join(', '),
    minWidth: 100,
    flex: 1,
  };

  const typeCol = {
    field: 'type',
    valueGetter: (params) => params.row.type?.label,
    headerName: 'Type',
    minWidth: 100,
    flex: 0.5,
  };

  const actionButtonCol = {
    field: 'actions',
    headerName: 'Actions',
    width: 140,
    id: 'actions',
    renderCell: (params) => (
      <>
        <EditIconButton onClick={() => { setRowData(params.row); setOpenForm(true); }} />

        <ConfirmDialog
          content={`Are you sure you want to delete the ${params.row.name} integration?`}
          action={() => handleDelete(params.row.id)}
        />
      </>
    ),
  };

  return (
    <>
      <CustomDataGrid
        gridId="admin-warehouse-vendor-integrations"
        data={integrations}
        gridColumns={[mainVendorCol, typeCol, nameCol, sitesCol, actionButtonCol]}
        pinnedColumns={{ right: ['actions'] }}
        id={'indexId'}
        CustomLeftToolbar={() => <AddButton onClick={() => setOpenForm(true)}>Add Integration</AddButton>}
        isLoading={isLoading}
      />
      <WarehouseVendorIntegrationModal
        open={openForm}
        row={rowData}
        sites={sites}
        vendors={vendorList.map((vendor) => ({ value: vendor.id, label: vendor.name }))}
        fetchData={() => fetchVendorIntegrations()}
        onClose={() => {
          setOpenForm(false);
          setRowData(null);
        }}
        integrations={integrations}
        types={types}
        integrationFieldTypes={integrationFieldTypes}
      />
    </>
  );
}

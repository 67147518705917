import { Box, DialogTitle, DialogContentText, Autocomplete, Tooltip, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../reusable-components/iconify';
import SimpleAutocompleteProps from '../../../reusable-components/autocomplete/SimpleAutocompleteProps';

ReportSelectTypeStep.propTypes = {
  reportsList: PropTypes.array,
  reportTypesList: PropTypes.array,
  formValues: PropTypes.object,
  setFormValues: PropTypes.func,
  setSelectedReportId: PropTypes.func
};

export default function ReportSelectTypeStep({ reportsList, reportTypesList, formValues, setFormValues, setSelectedReportId })
{
  return <>
    <DialogTitle sx={{ textAlign: 'center', color: 'rgba(112, 112, 112, 0.5)' }}>
      Choose Report
    </DialogTitle>
    <DialogContentText sx={{ fontWeight: 'bold' }}>Choose Report Type:</DialogContentText>
    <Autocomplete
      {...SimpleAutocompleteProps()}
      sx={{ mt: 1 }}
      options={reportTypesList || []}
      autoHighlight
      value={formValues.selectedReportType || null}
      onChange={(event, newValue) => setFormValues({ ...formValues, selectedReportType: newValue, selectedReport: null })}    
    />
    <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Choose Report:</DialogContentText>
    <Autocomplete
      {...SimpleAutocompleteProps()}
      sx={{ mt: 1 }}
      options={formValues.selectedReportType ? reportsList?.filter((x) => x.typeId === formValues.selectedReportType.id && x.id !== 12) : []} //removing budget report.
      autoHighlight
      value={formValues.selectedReport || null}
      onChange={(event, newValue) => { setFormValues({ ...formValues, selectedReport: newValue }); setSelectedReportId(newValue?.id ?? 0); }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}>
          {option.label}
          <Tooltip title={option.description}>
            <IconButton>
              <Iconify icon={'eva:eye-outline'} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    />
  </>;
}

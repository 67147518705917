import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Grid, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';
import CXMLOrderIntegration from './IntegrationForms/_CXMLOrderIntegration';
import PunchoutIntegration from './IntegrationForms/_PunchoutIntegration';
import CartCheckIntegration from './IntegrationForms/_CartCheckIntegration';
import JSONOrderIntegration from './IntegrationForms/_JSONOrderIntegration';
import ExcelOrderImportIntegration from './IntegrationForms/_ExcelOrderImportIntegration';
import IntegrationFieldsForm from './IntegrationForms/IntegrationFieldsForm';
import TextFormField from './IntegrationForms/IntegrationTextFormField';
import { postVendorIntegrationForm, getVendorIntegrationData } from '../../../../api/warehouse-vendors';

WarehouseVendorIntegrationModal.propTypes = {
  onClose: PropTypes.func,
  row: PropTypes.object,
  vendors: PropTypes.array,
  types: PropTypes.array,
  integrations: PropTypes.array,
  integrationFieldTypes: PropTypes.array,
  sites: PropTypes.array,
  fetchData: PropTypes.func,
  open: PropTypes.bool,
};

export default function WarehouseVendorIntegrationModal({ onClose, row, vendors, integrations, integrationFieldTypes, types, sites, fetchData, open })
{
  const { enqueueSnackbar } = useSnackbar();
  const [typeId, setTypeId] = useState(0);
  const [value, setValue] = useState({});
  const [copyIntegrationOptions, setCopyIntegrationOptions] = useState([]);
  const [copyIntegration, setCopyIntegration] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCopy, setLoadingCopy] = useState(false);

  useEffect(() => {
    setValue({});
    const newTypeId = row?.type?.value ?? 0;
    const rowId = row?.id ?? 0;
    if (rowId) {
      setLoading(true);
      getVendorIntegrationData(rowId)
        .then(res => setValue(res.data ? {
          ...res.data.integrationData,
          type: row.type,
          identities: res.data.identities,
          fields: res.data.integrationFields
        } : {}))
        .catch(() => enqueueSnackbar(`Failed to fetch vendor integration data`, { variant: 'error' }))
        .finally(() => setLoading(false));
    }
    setCopyIntegration(null);
    setTypeId(newTypeId);
    setCopyIntegrationOptions(row ? integrations
      .filter((integration) => integration.type?.value === newTypeId)
      .map((integration) => ({ value: integration.id, label: integration.name }))
      : []);
  }, [row, open]);

  const handleCopyIntegration = (copyIntegration) => {
    setCopyIntegration(copyIntegration);
    if (!copyIntegration) return;
    const copyValues = integrations.find(integration => integration.id === copyIntegration.value && integration.type?.value === typeId);
    if (!copyValues) return;
    setLoadingCopy(true);
    getVendorIntegrationData(copyValues.id)
      .then(res => setValue(initVal => res.data ? {
        ...res.data.integrationData,
        type: initVal.type,
        name: initVal.name,
        identities: res.data.identities,
        fields: res.data.integrationFields
      } : initVal))
      .catch(() => enqueueSnackbar(`Failed to fetch vendor integration data to copy`, { variant: 'error' }))
      .finally(() => setLoadingCopy(false));;
  };

  const handleSave = async () => {
    const response = await postVendorIntegrationForm({ ...value, id: row?.configId }, typeId);
    if (response.data) {
      enqueueSnackbar(`Vendor integration ${row ? 'updated' : 'added'} successfully`, { variant: 'success' });
      onClose();
    } else {
      enqueueSnackbar(`Failed to ${row ? 'update' : 'add'} vendor integration`, { variant: 'error' });
    }
    fetchData();
  };

  const autoCompleteBaseOps = {
    fullWidth: true,
    size: 'small',
    isOptionEqualToValue: (option, value) => option?.value === value?.value,
    renderInput: (params) => <TextField {...params} label="Search or select" />,
    sx: {
      mt: 0.5,
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 },
      '& .MuiFormLabel-root ': { display: 'none' },
    },
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        {row ? 'Edit' : 'Add'} Vendor Integration
        <CloseIconButton onClick={onClose} />
      </DialogTitle>
      {!loading ? <DialogContent>
        <Grid container direction="row" spacing={3} display="flex">
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Integration Type *
            </Typography>
            <Autocomplete
              {...autoCompleteBaseOps}
              value={types.find((opt) => opt.value === typeId) ?? null}
              onChange={(event, newValue) => {
                setValue({});
                setTypeId(newValue?.value ?? 0);
                setCopyIntegrationOptions(newValue ? integrations
                  .filter((integration) => integration.type?.value === newValue.value)
                  .map((integration) => ({ value: integration.id, label: integration.name }))
                  : []);
              }}
              options={types}
              disabled={row?.id > 0}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Vendor *
            </Typography>
            <Autocomplete
              {...autoCompleteBaseOps}
              value={vendors.find((opt) => opt.value === value.mainVendorId) ?? null}
              onChange={(event, newValue) => {
                setValue((value) => ({ ...value, mainVendorId: newValue?.value ?? 0 }));
              }}
              options={vendors}
              disabled={row?.id > 0}
            />
          </Grid>
        </Grid>
        {copyIntegrationOptions.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Copy Integration
            </Typography>
            <Autocomplete
              {...autoCompleteBaseOps}
              value={copyIntegration}
              onChange={(event, newValue) => handleCopyIntegration(newValue)}
              options={copyIntegrationOptions}
              popupIcon={loadingCopy ? <CircularProgress size={20} /> : null}
            />
          </>
        )}
        <TextFormField header={'Name *'} value={value.name} onChange={(e) => setValue({ ...value, name: e.target.value })} />

        {typeId === 1 && <CXMLOrderIntegration value={value} setValue={setValue} sites={sites} />}
        {typeId === 2 && <PunchoutIntegration value={value} setValue={setValue} />}
        {typeId === 3 && <CartCheckIntegration value={value} setValue={setValue} dataImports={integrations.filter(integration => integration.type?.type === "Item Data Import")
          .map(integration => ({ value: integration.id, label: integration.name }))} />}
        {typeId === 5 && <JSONOrderIntegration value={value} setValue={setValue} sites={sites} />}
        {typeId === 6 && <ExcelOrderImportIntegration value={value} setValue={setValue} />}
        {typeId > 0 && <IntegrationFieldsForm value={value} setValue={setValue} sites={sites} fieldTypes={integrationFieldTypes
          .filter(fieldType => fieldType.integrationTypeId === typeId).map(fieldType => ({ value: fieldType.id, label: `${fieldType.name} - ${fieldType.description}` }))} />}
      </DialogContent> : <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%' }} />}
      <DialogActions>
        <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={handleSave} disabled={loading || !value.mainVendorId || !value.name}>
          {row ? 'Save' : 'Add Integration'}
        </AwaitButton>
      </DialogActions>
    </Dialog>
  );
}

import { Autocomplete, DialogContentText, DialogTitle, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { getCategoryData } from '../../../../redux/slices/rentals';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';

CategorySelect.propTypes = {
  setSelectedCategory: PropTypes.func,
  selectedCategory: PropTypes.string,
};
export default function CategorySelect({ selectedCategory, setSelectedCategory }) {
  const dispatch = useDispatch();

  const {
    data: { categoryData },
  } = useSelector((state) => state.rentals);

  const seenCategories = new Set();
  const categoryOptions = categoryData.reduce((acc, item) => {
    if (!seenCategories.has(item.categoryName)) {
      seenCategories.add(item.categoryName);
      acc.push({
        label: item.categoryName,
        icon: item.categoryIcon,
      });
    }
    return acc;
  }, []);

  useEffect(() => {
    dispatch(getCategoryData());
  }, [dispatch]);

  return (
    <>
      <DialogTitle
        sx={{
          textAlign: 'center',
          color: 'rgba(112, 112, 112, 0.5)',
        }}
      >
        Rental Type
      </DialogTitle>
      <DialogContentText sx={{ fontWeight: 'bold' }}>Category Name</DialogContentText>{' '}
      <Autocomplete
        sx={{
          width: '100%',
          mt: 1,
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiFormLabel-root ': 'none',
        }}
        type="new-password"
        options={categoryOptions}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <li {...props}>
            {<Iconify icon={option.icon} />}
            {option.label}
          </li>
        )}
        value={selectedCategory?.label}
        onChange={(event, option) => {
          setSelectedCategory(option?.label || '');
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        )}
      />
    </>
  );
}

import { LoadingButton } from '@mui/lab';
import ExcelJS from 'exceljs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import FileDropWrapper from '../../../../reusable-components/DragAndDrop/FileDropWrapper';

ExcelUploadInput.propTypes = {
  setSheets: PropTypes.func,
  setSelectedSheet: PropTypes.func,
  setExcelData: PropTypes.func,
  setHeaderRow: PropTypes.func,
  setFile: PropTypes.func,
  selectedSheet: PropTypes.string,
}

export default function ExcelUploadInput({ setFile, setSheets, setSelectedSheet, setExcelData, setHeaderRow, selectedSheet })
{
  const { enqueueSnackbar } = useSnackbar();

  const [workbook, setWorkbook] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (file) => {
    try {
      if (file.size > 4000000)
      {
        enqueueSnackbar('Failed to load excel data: File is to big. max allowed size is 4 MB', { variant: 'error' });
        return;
      }
      setIsLoading(true);
      setFile(file);
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(await file.arrayBuffer(), {
        ignoreNodes: ['hyperlinks'],
      });
      setWorkbook(workbook);
    } catch {
      setIsLoading(false);
      enqueueSnackbar('Failed to load excel data', { variant: 'error' });
    }
  };

  const loadSheetData = async (workbook, sheetName) => {
    setIsLoading(true);
    const worksheet = workbook.getWorksheet(sheetName);
    const rawData = [];
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      const rowData = { row: rowNumber };
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        const cellHeader = String.fromCharCode(64 + colNumber);
        rowData[cellHeader] = typeof cell.value === 'object' && cell.value ? cell.text : cell.value;
      });    
      rawData.push(rowData);
    })
    const hasEmptyRow = rawData.some(row => Object.keys(row).filter(key => key !== 'row')
      .every(key => row[key] === undefined || row[key] === null || row[key] === ''));
    if (hasEmptyRow) {
      enqueueSnackbar('One or more rows are empty. Please check the data.', { variant: 'error' });
    } 
    setExcelData(rawData);
    const headerRow = rawData?.find(x => Object.keys(x).length > 1);
    setHeaderRow(headerRow ? Object.entries(headerRow).map(entry => ({ value: entry[0], label: `${entry[0]} - ${entry[1]}` })).filter(cell => cell.value !== "row") : []);
    setIsLoading(false);
  };

  const changeSheet = async (sheet) => {
    setSelectedSheet(sheet);
    if (sheet) {    
      loadSheetData(workbook, sheet);
    } else {
      setExcelData([]);
      setHeaderRow([]);
    }
  }

  useEffect(() => {
    changeSheet(selectedSheet);
  }, [selectedSheet]);

  useEffect(() => {
    const sheetNames = workbook?.worksheets.map((sheet) => sheet.name);
    setSheets(sheetNames);
    setSelectedSheet(sheetNames?.length ? sheetNames[0] : null);
  }, [workbook]);

  return <FileDropWrapper onFileDropped={handleFileUpload} accept='.xlsx, .xls'  >
    <LoadingButton
      loading={isLoading}
      variant="contained"
      color="secondary"
      size="small"
      startIcon={<Iconify icon="material-symbols:upload" />} 
    >
      Upload Excel
    </LoadingButton>
  </FileDropWrapper>;
}

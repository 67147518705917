import { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';

ReportsSideBarGroup.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  icon: PropTypes.string,
  getValue: PropTypes.func,
};

export default function ReportsSideBarGroup({ values, title, icon, getValue })
{
  const [show, setShow] = useState(false);
  if (!values || values.length === 0) return null;

  return <Typography variant="subtitle1" sx={{ py: 0.5, clear: 'both', display: 'block' }}>
    <Iconify sx={{ mr: 0.5, display: "inline" }} icon={icon} width={16} height={16} color="secondary" />
    {title}
    <Typography variant="body2" sx={{ fontWeight: 'bold', cursor: 'pointer', display: "inline" }} color="info.main">
      <Iconify onClick={() => { setShow(!show) }} icon={show ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
    </Typography>
    {show && Array.isArray(values) ?
      values.map((value, index) => <Typography key={index} variant="body2" sx={{ py: 0.5, ml: 5, mr: 5, display: 'block', clear: 'both' }}>
        {getValue(value)}
      </Typography>) : show ? <Typography variant="body2" sx={{ py: 0.5, ml: 5, mr: 5, clear: 'both' }}> {getValue(values)} </Typography> : ""}
  </Typography>;
}
import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Tooltip, Grid, TextField, Button, Stack, MenuItem, Menu } from '@mui/material';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { patchInvoiceItems, getManualInvoicing } from '../../../../../redux/slices/invoicing';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import Iconify from '../../../../../reusable-components/iconify';
import { bookInvoice2 } from '../../../../../api/invoicing';
import { useScopeCheck } from '../../../../../reusable-components/scopes';
import { NotificationDialog } from '../../../../../reusable-components/dialog';
import ApproveInvoice from '../ApproveInvoice';
import SubmitForReview from './SubmitForReview';
import SubmitApprovers from './SubmitApprovers';
import SubmitSwitcher from './SubmitSwitcher';

SubmitInvoice.propTypes = {
  glRows: PropTypes.array,
  setEnableMarkAsSent: PropTypes.func,
  unsavedData: PropTypes.object,
  setUnsavedData: PropTypes.func,
  selectedApprovers: PropTypes.any,
  editingApprovers: PropTypes.bool,
};
export default function SubmitInvoice({
  glRows,
  setEnableMarkAsSent,
  unsavedData,
  setUnsavedData,
  selectedApprovers,
  editingApprovers,
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const section = location.pathname.split('/')[3];
  const {
    data: { manualInvoicing, invoiceApprovers, invoiceItemData },
  } = useSelector((state) => state.invoicing);

  const { bookingType } = invoiceItemData || '';
  const { invoice } = invoiceItemData || {};

  const hasSubmitAccess = useScopeCheck(['Submit-Invoice'], false, true);
  const hasManualSubmitAccess = useScopeCheck(['ManualInvoice-Submit'], false, true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitComplete, setSubmitComplete] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const requiredFieldsFilled =
    invoice?.facilityId > 0 &&
    invoice?.vendorId > 0 &&
    invoice?.vendorInvoiceId !== null &&
    invoice?.glPostingDate !== null &&
    glRows?.length > 0;

  useEffect(() => {
    if (submitComplete) {
      if (localStorage.getItem('selectedInvoices')) {
        if (JSON.parse(localStorage.getItem('selectedInvoices')).length === 0) {
          localStorage.removeItem('selectedInvoices');
          navigate(`/dashboard/invoicing/new`);
        } else {
          const nextId = JSON.parse(localStorage.getItem('selectedInvoices'))[0];
          navigate(`/dashboard/invoicing/new/${nextId}`);
          localStorage.setItem(
            'selectedInvoices',
            JSON.stringify(JSON.parse(localStorage.getItem('selectedInvoices')).slice(1))
          );
        }
      } else if (invoice?.sourceMethod === 'Manual' && manualInvoicing?.length > 0) {
        const nextId = manualInvoicing?.[0]?.id;
        navigate(`/dashboard/invoicing/manual/${nextId}`);
      } else {
        const pathSegments = location.pathname.split('/');
        const url = pathSegments.slice(0, -1).join('/');
        navigate(url);
      }
    }
  }, [manualInvoicing, submitComplete]);

  const submit = async () => {
    if (
      (invoice?.sourceMethod !== 'Manual' && !hasSubmitAccess) ||
      (invoice?.sourceMethod === 'Manual' && !hasManualSubmitAccess)
    ) {
      setShowNotification(true);
      setIsSubmitting(false);
      return;
    }

    setSubmitComplete(false);
    const response = await bookInvoice2(invoice, glRows);

    if (response.status === 200) {
      if (response.data.keepFormOpen) {
        enqueueSnackbar(response.data.message, {
          variant: response.data.success ? 'success' : 'error',
          persist: true,
        });
        if (response.data.message?.startsWith(`Invoice '${invoice?.vendorInvoiceId}' already in Intacct.`)) {
          setEnableMarkAsSent(true);
        }
      } else {
        enqueueSnackbar(response.data.message, { variant: response.data.success ? 'success' : 'error' });
      }
    } else {
      enqueueSnackbar('Unable to submit invoice', { variant: 'error' });
    }
    if (response.data.success) {
      if (invoice?.sourceMethod === 'Manual') await dispatch(getManualInvoicing());
      setSubmitComplete(true);
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    if (Object.values(unsavedData)?.length === 0 && isSubmitting) {
      submit();
    }
  }, [unsavedData, isSubmitting]);
  const save = async () => {
    if (Object.values(unsavedData)?.length > 0) {
      await dispatch(patchInvoiceItems(invoice.id, Object.values(unsavedData)));
      setUnsavedData({});
    }
    setIsSubmitting(true);
  };
  return (
    <>
      <Tooltip
        title={
          Object.values(unsavedData)?.length > 0
            ? 'Unsaved Changes'
            : invoice.approvalStatus !== 0
            ? 'Waiting for approvals'
            : !requiredFieldsFilled
            ? 'Complete invoice info - Required fields + Items'
            : invoiceItemData?.invoiceItems?.filter((item) => item?.agoraSubcategory?.id === 0)?.length > 0
            ? 'Missing GLs'
            : bookingType !== 'Intacct' && invoice?.multiFacility
            ? 'Invoice booking type must be  Intacct'
            : ''
        }
        placement="top"
        arrow
      >
        <Box>
          <AwaitButton
            variant="contained"
            color="secondary"
            onClick={save}
            loading={isSubmitting}
            loadingPosition="start"
            disabled={
              Object.values(unsavedData)?.length > 0 ||
              invoice?.glPostingDate === null ||
              glRows?.length === 0 ||
              invoiceItemData?.invoiceItems?.filter((item) => item?.agoraSubcategory?.id === 0)?.length > 0 ||
              (bookingType !== 'Intacct' && invoice?.multiFacility) ||
              invoice.approvalStatus !== 0
            }
            startIcon={<Iconify icon="fa:paper-plane-o" />}
          >
            Submit Invoice
          </AwaitButton>
        </Box>
      </Tooltip>
      {showNotification && (
        <NotificationDialog
          handleClose={() => {
            setShowNotification(false);
          }}
          title="Permission missing"
          content={"You don't have permission to submit the invoice."}
        />
      )}
    </>
  );
}
